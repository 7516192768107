import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { ToastContainer } from "react-toastify"
import AuthUser from "components/AuthUser/AuthUser"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import UserProfileCard from "../../pages/UserProfile/UserProfileCard"
import TravelAgencyLogoCard from "../../pages/UserProfile/TravelAgencyLogoCard"
import { Col, Row, Container, Card, CardBody, FormGroup } from "reactstrap"
import Select from "react-select"
import { useDispatch } from "react-redux"
import { connect } from "react-redux"
import errorCatching from "../../helpers/commonhelpers/errorCatching"
import { isEmpty } from "lodash"
import {
  getPayReqByUserId,
  getSummary,
  getMerchantDetail,
  getMerchantList,
} from "store/actions"
import Summary from "./Summary"
import AcceptanceGraph from "./AcceptanceGraph"
import PaymentRequests from "../PaymentRequestList/index"
import PromotionsAnnouncements from "./PromotionsAnnouncements"
import TopMerchantGraph from "./TopMerchantGraph"
import TopUserChart from "./TopUserChart"
import Survey from "pages/Dashboard/Survey"
import makeAnimated from "react-select/animated"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import LatestTransactions from "./LatestTransactions"
const animatedComponents = makeAnimated()

const multi = {
  filtered: [],
  select2: undefined,
}
const Dashboard = props => {
  const {
    onGetSummary,
    onGetUser,
    onGetMerchantDetail,
    prSummary,
    merchantDetail,
    onGetMerchantList,
  } = props

  const dispatch = useDispatch()
  const [user, setUser] = useState(AuthUser())
  const [prList, setPRList] = useState()
  const [prLoading, setPRLoading] = useState(props.prLoading)
  const [prCount, setPRCount] = useState(0)
  const [revenue, setRevenue] = useState(0)
  const [merchant, setMerchant] = useState(0)
  const [selectedMerchant, setSelectedMerchant] = useState(null)
  const [selectedDateRange, setSelectedDateRange] = useState(null)
  const [merchantList, setMerchantList] = useState([])
  const [selectedMerchantName, setSelectedMerchantName] = useState()
  const [dateRanges, setDateRanges] = useState()
  const [selectYear, setSelectYear] = useState(null)
  const [errorResponse, setErrorResponse] = useState(undefined)
  const [barYear, setBarYear] = useState()

  let yearOptions = [2025, 2024, 2023, 2022]
  //////////////////////////////////////////////////////////
  useEffect(() => {
    let dates = {
      StartDate: null,
      EndDate: null,
      MerchantId: null,
    }
    if (user.userId !== undefined) {
      onGetUser(user.userId)
      onGetSummary(dates)
      onGetMerchantDetail(user.merchantId)
    }
    if (user.role === "SuperAdmin") {
      onGetMerchantList()
    }
  }, [])

  useEffect(() => {}, [prSummary])
  useEffect(() => {
    // console.log(selectedDateRange)
  }, [selectedDateRange])

  useEffect(() => {
    setMerchant(merchantDetail)
  }, [merchantDetail])

  useEffect(() => {}, [selectedMerchant])

  useEffect(() => {}, [selectedMerchantName])

  useEffect(() => {
    if (merchantList.length === 0 && Array.isArray(props.merchantList)) {
      setMerchantList(props.merchantList)
    }
  }, [props.merchantList])

  useEffect(() => {}, [merchantList])

  useEffect(() => {
    if (props.prList !== undefined) {
      setPRList(props.prList)
    }
  }, [props.prList])

  useEffect(() => {}, [prList])

  useEffect(() => {
    setPRLoading(true)
    if (Array.isArray(prList)) {
      setPRCount(handleSuccessSent())
      setRevenue(handleRevenue())
    }
  }, [prList, props.prLoading])
  useEffect(() => {}, [revenue])
  useEffect(() => {}, [prCount])

  useEffect(() => {}, [prLoading])

  useEffect(() => {}, [dateRanges])
  useEffect(() => {
    if (props.error !== undefined && !isEmpty(props.error))
      setErrorResponse(props.error)
  }, [props.error])
  useEffect(() => {
    if (selectYear && selectYear != "Select Year") {
      let startDate = selectYear.toString() + "-01-01"
      let endDate = selectYear.toString() + "-12-31"
      //console.log([new Date(startDate), new Date(endDate)])
      setSelectedDateRange([new Date(startDate), new Date(endDate)])
    }
  }, [selectYear])
  ////////////////////////////////////////////////////////////

  const handleRevenue = () => {
    let amount = 0
    //console.log(prList)
    if (Array.isArray(prList)) {
      prList.map((pr, i) => {
        if (
          pr.paymentStatus === "Success" ||
          pr.paymentStatus === "Refund Pending" ||
          pr.paymentStatus === "Refund Rejected"
        ) {
          amount = amount + pr.amountDetails.totalAmount
        }
      })
    }
    // console.log(prSummary.totalRevenue.toFixed(2))
    return amount.toLocaleString(undefined, {
      maximumFractionDigits: 2,
    })
  }

  const handleSuccessSent = () => {
    let count = 0
    if (Array.isArray(prList)) {
      prList.map((pr, i) => {
        if (pr.paymentStatus === "Success") {
          count++
        }
      })
    }
    return count
  }

  const handleDateRangeAndMerchant = (dateRange, merchant) => {
    {
      merchantList.map((merchants, index) => {
        if (merchants.merchantId === merchant) {
          setSelectedMerchantName(merchants.merchantName)
        }
      })
    }
    if (Array.isArray(dateRange)) {
      setDateRanges(dateRange)
      if (dateRange.length === 2) {
        let sDate =
          dateRange[0].getMonth() +
          1 +
          "/" +
          dateRange[0].getDate() +
          "/" +
          dateRange[0].getFullYear()
        let eDate =
          dateRange[1].getMonth() +
          1 +
          "/" +
          dateRange[1].getDate() +
          "/" +
          dateRange[1].getFullYear()
        let dates = {
          StartDate: sDate,
          EndDate: eDate,
          MerchantId: merchant,
        }
        onGetSummary(dates)
      } else {
        let sDate =
          dateRange[0].getMonth() +
          1 +
          "/" +
          dateRange[0].getDate() +
          "/" +
          dateRange[0].getFullYear()
        let dates = {
          StartDate: sDate,
          EndDate: null,
          MerchantId: merchant,
        }
        onGetSummary(dates)
      }
    } else {
      if (merchant !== "null") {
        let dates = {
          StartDate: null,
          EndDate: null,
          MerchantId: merchant,
        }

        onGetSummary(dates)
      }
    }
  }

  const handleReset = () => {
    setSelectYear("Select Year")
    setSelectedDateRange(null)
    setDateRanges(undefined)
    setSelectedMerchantName(undefined)
    setSelectedMerchant("Select Merchant")
    let dates = {
      StartDate: null,
      EndDate: null,
    }
    onGetSummary(dates)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs breadcrumbItem=" DASHBOARD" />
          {errorCatching(errorResponse, setErrorResponse)}
          <Row>
            <Col lg="4">
              {/* <TravelAgencyLogoCard /> */}
              <UserProfileCard
                user={user}
                prCount={prCount}
                revenue={revenue}
                merchantDetail={merchant}
              />

              <PromotionsAnnouncements />

              <Survey userId={user.userId} />
            </Col>

            <Col lg="8">
              <Card className="py-2 px-3 mb-1">
                <Row>
                  <Col lg={2}>
                    <small>Year:</small>
                    <FormGroup className="mb-0 templating-select select2-container my-1 ">
                      <select
                        value={selectYear}
                        className="form-select form-control"
                        onChange={e => setSelectYear(e.target.value)}
                        name="selectYear"
                      >
                        <option value={null}>Select Year</option>
                        {yearOptions &&
                          yearOptions?.map((val, i) => {
                            return (
                              <option key={i} value={val}>
                                {val}
                              </option>
                            )
                          })}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col lg={4}>
                    <small>Date Range:</small>
                    <Flatpickr
                      name="selectedDates"
                      className="form-control w-100 my-1  "
                      placeholder="Select Date Range"
                      value={selectedDateRange}
                      options={{
                        mode: "range",
                        altFormat: "Y-m-d",
                        dateFormat: "F j, Y",
                      }}
                      onChange={selectedDates =>
                        setSelectedDateRange(selectedDates)
                      }
                    />
                  </Col>
                  {user.role === "SuperAdmin" ? (
                    <Col lg={4}>
                      <small className="">Merchant:</small>
                      <FormGroup className="mb-0 templating-select select2-container my-1 ">
                        <select
                          value={selectedMerchant}
                          className="form-select form-control"
                          onChange={e => setSelectedMerchant(e.target.value)}
                          name="selectedMerchant"
                        >
                          <option value={null}>Select Merchant</option>
                          {merchantList
                            ? merchantList.map((merchant, i) => {
                                return (
                                  <option key={i} value={merchant.merchantId}>
                                    {merchant.merchantName}
                                  </option>
                                )
                              })
                            : "No Merchant Ids"}
                        </select>
                      </FormGroup>
                    </Col>
                  ) : null}
                  {user.role !== "SuperAdmin" ? (
                    <React.Fragment>
                      <Col lg={2}>
                        <div className="dashborad-button"></div>
                        <button
                          type="button"
                          className="btn btn-primary waves-effect btn-label waves-light  mt-1  w-100"
                          onClick={() => {
                            setBarYear(selectYear)
                            handleDateRangeAndMerchant(
                              selectedDateRange,
                              selectedMerchant == "Select Merchant"
                                ? null
                                : selectedMerchant
                            )
                          }}
                        >
                          <i className="bx bx-search-alt label-icon"></i> Search
                        </button>
                      </Col>
                      <Col lg={2}>
                        <div className="dashborad-button"></div>
                        <button
                          type="button"
                          className="btn btn-danger waves-effect btn-label waves-light  mt-1 mx-auto w-100"
                          onClick={() => handleReset()}
                        >
                          <i className="bx bx-reset label-icon "></i> Reset
                        </button>
                      </Col>
                    </React.Fragment>
                  ) : null}
                </Row>
                {user.role === "SuperAdmin" ? (
                  <Row className="mb-2">
                    <Col lg={2}>
                      <div className="dashborad-button"></div>
                      <button
                        type="button"
                        className="btn btn-primary waves-effect btn-label waves-light    w-100"
                        onClick={() => {
                          setBarYear(selectYear)
                          handleDateRangeAndMerchant(
                            selectedDateRange,
                            selectedMerchant == "Select Merchant"
                              ? null
                              : selectedMerchant
                          )
                        }}
                      >
                        <i className="bx bx-search-alt label-icon"></i> Search
                      </button>
                    </Col>
                    <Col lg={2}>
                      <div className="dashborad-button"></div>
                      <button
                        type="button"
                        className="btn btn-danger waves-effect btn-label waves-light   mx-auto w-100"
                        onClick={() => handleReset()}
                      >
                        <i className="bx bx-reset label-icon "></i> Reset
                      </button>
                    </Col>
                  </Row>
                ) : null}
              </Card>
              <Row className="mx-1">
                <Summary
                  selectedDateRange={dateRanges}
                  selectedMerchantName={selectedMerchantName}
                  prSummary={prSummary}
                  user={user}
                  merchantDetail={merchant}
                />
              </Row>
              <Row className="">
                <AcceptanceGraph prSummary={prSummary} selectYear={barYear} />
              </Row>
              {/* {user.role === "SuperAdmin" ? (
                <Row className="">
                  <TopMerchantGraph />
                </Row>
              ) : null}
              {user.role !== "User" ? (
                <Row className="">
                  <TopUserChart />
                </Row>
              ) : null}
              <Row>
                <LatestTransactions />
              </Row>{" "} */}
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  onGetSummary: PropTypes.func,
  onGetUser: PropTypes.func,
  loading: PropTypes.bool,
  prList: PropTypes.any,
  prLoading: PropTypes.bool,
  prSummary: PropTypes.object,
  merchantDetail: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    loading: state.Users.loading,
    prList: state.paymentRequests.prList,
    prLoading: state.paymentRequests.loading,
    prSummary: state.Dashboard.summary,
    merchantDetail: state.Merchants.merchantDetail,
    merchantList: state.Merchants.merchantList,
    error: state.Dashboard.error,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetSummary: dates => dispatch(getSummary(dates)),
  onGetUser: userId => dispatch(getPayReqByUserId(userId)),
  onGetMerchantDetail: merchantId => dispatch(getMerchantDetail(merchantId)),
  onGetMerchantList: () => dispatch(getMerchantList()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
