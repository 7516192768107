export default function handleHoursToReadableText(value) {
  switch (value) {
    case 24:
      return "1 Day"
    case 168:
      return "1 Week"
    case 730:
      return "1 Month"
    case 8760:
      return "1 Year"
  }
}
