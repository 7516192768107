import React, { useState, useEffect } from "react"
import {
  payfortLogo,
  dibsyLogo,
  applepayLogo,
  googlepayLogo,
} from "components/SharedImages"
import LoadingSpinnerThreeDots from "helpers/commonhelpers/loadingSpinner"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  FormGroup,
  Label,
  Collapse,
  Button,
} from "reactstrap"

function PaymentTypesCards(props) {
  const { paymentList, handleChangePrSettingsDetails, merchantLoading } = props
  const [open, setOpen] = useState([])
  const [paymentTypes, setPaymentTypes] = useState()
  const [ptIdsToUse, setPTIdsToUse] = useState()
  // const payfortLogo =
  //   "https://res.cloudinary.com/developerqa/image/upload/v1736430872/merchant/Amazon-Payment-Services_Logo_copy_ixkqm0.jpg"
  // const dibsyLogo =
  //   "https://res.cloudinary.com/developerqa/image/upload/v1736430504/merchant/dibsy_xchibk.svg"

  // const applepayLogo =
  //   "https://res.cloudinary.com/developerqa/image/upload/v1736422361/merchant/applepay_oope1a.png"
  // const googlepayLogo =
  //   "https://res.cloudinary.com/developerqa/image/upload/v1736422390/merchant/googlepay_vciqln.png"
  //---------------------UseEffects----------------------------------
  useEffect(() => {
    // console.log(paymentTypes)
    if (paymentTypes?.length > 0) {
      let checkedCards = []

      paymentTypes.map(pt => {
        if (pt?.checked) {
          pt.cards.map(c => {
            if (c?.checked) {
              checkedCards.push(parseInt(c.id))
            }
          })
        }
      })
      setPTIdsToUse(checkedCards)
    }
  }, [paymentTypes])
  useEffect(() => {
    //console.log(ptIdsToUse)
    if (ptIdsToUse) {
      handleChangePrSettingsDetails({
        AvailablePaymentOptions: ptIdsToUse,
      })
    }
  }, [ptIdsToUse])
  useEffect(() => {
    //console.log(open)
  }, [open])
  useEffect(() => {
    //console.log(paymentList)
    if (paymentList) {
      let openChecks = []
      paymentList.map(pt => {
        openChecks.push({ id: pt.id, checked: pt.checked })
      })
      setOpen(openChecks)
      setPaymentTypes(paymentList)
    }
  }, [paymentList])
  //------------------Function--------------------------
  function handlePTChanges(changes, cid) {
    const newItems = [...paymentTypes]
    const item = newItems.findIndex(i => i.id === cid)
    newItems[item] = changes

    setPaymentTypes(newItems)
  }
  function handleChangePT(changes, index, cid) {
    handlePTChanges({ ...paymentTypes[index], ...changes }, cid)
  }
  function handleChangePaymentTypes(id, value, index, cid) {
    const newItems = [...paymentTypes[index].cards]
    const item = newItems.findIndex(i => i.id === id)
    newItems[item] = value
    handleChangePT({ cards: newItems }, index, cid)
  }
  function handleChangeCards(id, value, index) {
    const newItems = [...paymentTypes]
    const item = newItems.findIndex(i => i.id === id)
    newItems[item] = value
    //console.log(newItems)
    setPaymentTypes(newItems)
  }
  function handleChange(id, changes, c, index, cid) {
    handleChangePaymentTypes(id, { ...c, ...changes }, index, cid)
  }
  function handleChangeCard(id, changes, card, index) {
    //console.log(changes, id)
    handleChangeCards(id, { ...card, ...changes }, index)
  }
  function handleOpenChange(id, changes) {
    const newItems = [...open]
    const item = newItems.findIndex(i => i.id === id)

    newItems[item] = changes

    setOpen(newItems)
  }
  function handleOpenClickChange(id, index, changes) {
    handleOpenChange(id, { ...open[index], ...changes })
  }

  function handleCountCheckedCards(pg) {
    let i = 0
    if (pg?.checked) {
      pg.cards.map(c => {
        if (c?.checked) {
          i++
        }
      })
    }
    return i
  }
  function handlePGLogo(value) {
    switch (value) {
      case "2":
        return payfortLogo
      case "4":
        return dibsyLogo
    }
  }
  function handlePGLogoSize(value) {
    switch (value) {
      case "2":
        return "60%"
      case "4":
        return "18%"
    }
  }
  function handlePGLogoSizeClassName(value) {
    switch (value) {
      case "2":
        return "pointer"
      case "4":
        return "piointer mb-2"
    }
  }
  function handlePaymentOption(value) {
    switch (value) {
      case "credit":
        return ""
      case "debit":
        return ""
      case "applepay":
        return applepayLogo
      case "googlepay":
        return googlepayLogo
    }
  }
  return (
    <React.Fragment>
      {merchantLoading ? (
        <LoadingSpinnerThreeDots />
      ) : (
        <div
          className="container-scroll-express my-auto"
          style={{
            border: "1px",
            borderStyle: "solid",
            borderColor: "primary",
          }}
        >
          <div className="center-col my-2">
            {/* <Button
            color="primary"
          >
            Expand
          </Button> */}
            {/* <br /> */}
            {paymentTypes?.map((card, index) => {
              return (
                <div key={index} className="mx-4">
                  <input
                    type="radio"
                    checked={card.checked}
                    className="mr-2 my-2 pointer"
                    onClick={() => {
                      handleOpenClickChange(card.id, index, {
                        checked: !open[index]?.checked,
                      })
                    }}
                    // onClick={e => {
                    //   handleChangeCard(
                    //     card.id,
                    //     {
                    //       checked: !card.checked,
                    //     },
                    //     card,
                    //     index
                    //   )
                    // }}
                  />
                  {/* <Label
                    check
                    className="my-auto color-primary pointer mx-2"
                    style={{ fontSize: "15px" }}
                    onClick={e => {
                      handleChangeCard(
                        card.id,
                        {
                          checked: !card.checked,
                        },
                        card,
                        index
                      )
                    }}
                  >
                    {card?.pgName} {"(" + handleCountCheckedCards(card) + ")"}
                  </Label> */}
                  <img
                    src={handlePGLogo(card.id)}
                    width={handlePGLogoSize(card.id)}
                    alt="pg logo"
                    className={handlePGLogoSizeClassName(card.id)}
                    onClick={() => {
                      handleOpenClickChange(card.id, index, {
                        checked: !open[index]?.checked,
                      })
                    }}
                    // onClick={() => {
                    //   handleOpenClickChange(card.id, index, {
                    //     checked: !open[index]?.checked,
                    //   })
                    // }}
                  />
                  {/* {open[index]?.checked ? (
                    <small
                      className="color-primary pointer"
                      onClick={() => {
                        handleOpenClickChange(card.id, index, {
                          checked: !open[index]?.checked,
                        })
                      }}
                    >
                      <i className="fa fa-expand color-primary mx-1" />
                      expand
                    </small>
                  ) : (
                    <small
                      className="color-danger pointer"
                      onClick={() => {
                        handleOpenClickChange(card.id, index, {
                          checked: !open[index]?.checked,
                        })
                      }}
                    >
                      <i className="fa fa-compress color-danger mx-1" />
                      hide
                    </small>
                  )} */}

                  <Collapse isOpen={!open[index]?.checked}>
                    {card.cards?.map((c, i) => {
                      if (c?.basis) {
                        return (
                          <FormGroup
                            className=" "
                            check
                            key={i}
                            style={{ width: "90%" }}
                          >
                            <Row className="my-2 ">
                              <Col className="mx-4 ">
                                <input
                                  type="radio"
                                  checked={c?.checked}
                                  className="pointer"
                                  // onChange={() => {}}
                                  // onClick={e => {
                                  //   handleChange(
                                  //     c.id,
                                  //     {
                                  //       checked: !c.checked,
                                  //     },
                                  //     c,
                                  //     index,
                                  //     card.id
                                  //   )
                                  // }}
                                />{" "}
                                {/* <Label
                                  check
                                  className="pointer"
                                  onClick={e => {
                                    handleChange(
                                      c.id,
                                      {
                                        checked: !c.checked,
                                      },
                                      c,
                                      index,
                                      card.id
                                    )
                                  }}
                                >
                                  {c?.basis.toUpperCase()}
                                </Label> */}
                                {handlePaymentOption(c?.basis) !== "" ? (
                                  <img
                                    src={handlePaymentOption(c?.basis)}
                                    width={"50px"}
                                    height={"20px"}
                                    alt="pg logo"
                                    className=" pointer mt-1"
                                    // onClick={() => {
                                    //   handleOpenClickChange(card.id, index, {
                                    //     checked: !open[index]?.checked,
                                    //   })
                                    // }}
                                  />
                                ) : (
                                  <Label
                                    check
                                    className="pointer mt-1"
                                    // onClick={e => {
                                    //   handleChange(
                                    //     c.id,
                                    //     {
                                    //       checked: !c.checked,
                                    //     },
                                    //     c,
                                    //     index,
                                    //     card.id
                                    //   )
                                    // }}
                                  >
                                    {c?.basis.toUpperCase()}
                                  </Label>
                                )}
                              </Col>
                            </Row>
                          </FormGroup>
                        )
                      }
                    })}
                  </Collapse>
                  <br />
                </div>
              )
            })}
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default PaymentTypesCards
