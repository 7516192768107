export default function handleCardTypes(value, pgDetails) {
  switch (handleMultiNumber(value)) {
    case "4":
      return "visa"
    case "5":
      return "master"
    case "2":
      return "master"
    case "3":
      return "amex"
    default:
      return ""
  }
}

function handleMultiNumber(value) {
  if (value?.length > 0) {
    return Array.from(value)[0]
  } else {
    return value
  }
}

function handleSelectedCardOptionDetails(value, pgDetails) {
  // pgDetails?.map((pg)=>{
  //   if(pg.isActive){
  //   }
  // })
}
