import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { Container } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  getPaymentRequestList,
  getPaymentRequestListReport,
  patchPaymentRequestArchive,
  downloadPaymentHistory,
} from "store/actions"
import PaymentRequestList from "./PaymentRequestList"
import SearchForm from "./SearchForm"
import errorCatching from "../../helpers/commonhelpers/errorCatching"
import { isEmpty } from "lodash"
import { sendPaymentRequestEmail, getMerchantList } from "store/actions"
export const PaymentRequestContext = React.createContext()
import SweetAlert from "react-bootstrap-sweetalert"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import AuthUser from "components/AuthUser/AuthUser"
import { CSVLink } from "react-csv"

toastr.options = {
  positionClass: "toast-top-right",
  closeButton: false,
  debug: false,
  progressBar: true,
  preventDuplicates: false,
  newestOnTop: true,
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
  showDuration: 300,
  hideDuration: 300,
}

const headers = [
  { label: "Payment Request Id", key: "paymentRequestId" },
  { label: "Date Requested", key: "paymentRequestDate" },
  { label: "Payment Gateway Id", key: "paymentGatewayId" },
  { label: "Transaction Reference", key: "transactionReference" },
  { label: "Amount", key: "amountDetails.totalAmount" },
  { label: "Currency", key: "amountDetails.currency" },
  { label: "Invoice Link", key: "generatedLinkShort" },
  { label: "Invoice Status", key: "generatedLinkStatus" },
  { label: "Generated From", key: "itemSource" },
  { label: "Request Created By", key: "createdBy" },
  { label: "Latest Payment Reference", key: "lastPayment" },
  { label: "Payment Status", key: "paymentStatus" },
  { label: "Paid Track Id", key: "paidDetailsTrackId" },
  { label: "Paid Trans Id", key: "paidDetailsTransId" },
  { label: "Paid Payment Type", key: "paidDetailsPaymentTypeUsed" },
  { label: "Paid Date", key: "paidDetailsDate" },
  { label: "Customer Name", key: "customerName" },
  { label: "Customer Mobile", key: "customerMobile" },
  { label: "Customer Email", key: "customerEmail" },
  { label: "Config Payment Type", key: "settingsPaymentType" },
  { label: "Config Language", key: "settingsLanguage" },
  { label: "Config Link Validity", key: "settingsValidity" },
  { label: "Config Link Epiry", key: "settingsLinkDateExpired" },
  { label: "Config Terminal Id", key: "settingsTerminalId" },
  { label: "Merchant Name", key: "merchantDetailsName" },
  { label: "Merchant Id", key: "merchantDetailsId" },
  { label: "Request Created By Email", key: "userDetailsEmail" },
]

const PaymentRequests = props => {
  const {
    loading,
    paymentRequests,
    paymentRequestsReport,
    onGetPaymentRequests,
    onGetPaymentRequestsReport,
    error,
    loadingEmail,
    onPatchPaymentRequestArchive,
    paymentRequestArchiveResult,
    onGetMerchantList,
    merchantList,
    onDownloadPaymentHistory,
    downloadsList,
    loadingDownloadHistotry,
  } = props

  const [paymentRequestList, setPaymentRequestList] = useState([])
  const [isLoaded, setIsLoaded] = useState(loading)
  const [isLoadedEmail, setIsLoadedEmail] = useState(loadingEmail)
  const [prListReportFiltered, setPrListReportFiltered] = useState([])
  const [prListReport, setPrListReport] = useState([])
  const [reportdownloadClick, setReportdownloadClick] = useState(false)
  const [user, setUser] = useState(AuthUser())
  const [selectedMultiSelect, setselectedMultiSelect] = useState(null)
  const [selectedDateRange, setSelectedDateRange] = useState(null)
  const [archivePR, setArchivePR] = useState("")
  const csvLink = useRef()
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [error_dlg, seterror_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [errorResponse, setErrorResponse] = useState(undefined)

  const dispatch = useDispatch()

  //useEffects
  //get payment request list by merchant/role
  useEffect(() => {
    if (user && user.role === "SuperAdmin") {
      onGetPaymentRequests("SuperAdmin")
      onGetMerchantList()
    } else {
      if (user.merchantId) {
        onGetPaymentRequests(user.merchantId)
      }
    }
  }, [])

  //setReportListItems
  useEffect(() => {
    if (!isEmpty(paymentRequestsReport)) {
      setPrListReport(paymentRequestsReport)
    }
  }, [paymentRequestsReport])

  useEffect(() => {}, [merchantList])
  useEffect(() => {}, [selectedMultiSelect, selectedDateRange])
  useEffect(() => {
    // console.log(archivePR)
    if (archivePR && archivePR !== undefined) {
      let model = {
        Field: "PaymentRequest",
        Id: archivePR,
      }
      onPatchPaymentRequestArchive(model)
    }
  }, [archivePR])

  useEffect(() => {
    if (
      prListReport !== null &&
      !isEmpty(prListReport) &&
      reportdownloadClick === true
    ) {
      handleFilterActionsReport(selectedMultiSelect, selectedDateRange)
    }
  }, [prListReport, reportdownloadClick])

  //filtered items are new after clicking search
  useEffect(() => {
    if (!isEmpty(prListReportFiltered) && reportdownloadClick === true) {
      csvLink.current.link.click()
      setReportdownloadClick(false)
    }
  }, [prListReportFiltered, reportdownloadClick])

  useEffect(() => {
    //console.log(paymentRequests)
    if (!isEmpty(paymentRequests)) {
      setPaymentRequestList(paymentRequests)
    }
  }, [paymentRequests])

  useEffect(() => {
    setIsLoaded(loading)
  }, [loading])

  useEffect(() => {
    if (loadingEmail && loadingEmail === true) {
      toastr.options = {
        timeOut: 5000,
      }
      toastr.success("", "Email Success")
    }

    if (loadingEmail === undefined) {
      toastr.options = {
        timeOut: 1000,
      }
      toastr.info("", "Email Sending")
    }

    if (loadingEmail === false) {
      toastr.options = {
        timeOut: 5000,
      }
      toastr.error("", "Opps Email Not Sent")
    }
  }, [loadingEmail])

  useEffect(() => {
    //console.log(paymentRequestList.length)
  }, [paymentRequestList])
  useEffect(() => {
    if (paymentRequestArchiveResult === true) {
      setsuccess_dlg(true)
      setdynamic_title("Success")
      setdynamic_description("Payment Request has been archived")
    }
    if (paymentRequestArchiveResult === false) {
      seterror_dlg(true)
      setdynamic_title("Fail")
      setdynamic_description("Payment Request has not been archived")
    }
  }, [paymentRequestArchiveResult])

  //ERROR CATCHING
  useEffect(() => {
    if (error !== undefined && !isEmpty(error)) setErrorResponse(error)
  }, [error])

  useEffect(() => {}, [errorResponse])

  useEffect(() => {
    //console.log(downloadsList)
  }, [downloadsList])

  //----------------------Functions-----------------------------------------
  const handleDownloadReport = (selectedMulti, selectedDateRange) => {
    // handleFilterActionsReport(selectedMulti, selectedDateRange)

    if (user && user.role === "SuperAdmin") {
      onGetPaymentRequestsReport("SuperAdmin")
    } else {
      if (user.merchantId) {
        onGetPaymentRequestsReport(user.merchantId)
      }
    }

    setReportdownloadClick(true)
    setselectedMultiSelect(selectedMulti)
    setSelectedDateRange(selectedDateRange)
  }
  const handleDownloadReportHistory = (selectedMulti, selectedDateRange) => {
    // handleFilterActionsReport(selectedMulti, selectedDateRange)

    if (user && user.role === "SuperAdmin") {
      const data = {
        StartDate: selectedDateRange[0],
        EndDate: selectedDateRange[1],
        MerchantId: selectedMulti,
      }
      //console.log(data)
      onDownloadPaymentHistory(data)
    }

    setReportdownloadClick(true)
    setselectedMultiSelect(selectedMulti)
    setSelectedDateRange(selectedDateRange)
  }

  //Filter: Multi Selection & Date for Table
  function handleFilterActions(selectedMulti, selectedDateRange) {
    setselectedMultiSelect(selectedMulti)
    setSelectedDateRange(selectedDateRange)

    let prs = paymentRequests

    if (isEmpty(selectedDateRange) && !isEmpty(selectedMulti.filtered)) {
      prs = paymentRequests
    }

    if (!isEmpty(selectedDateRange) && selectedDateRange != null) {
      const dateFrom = Date.parse(selectedDateRange[0])
      const dateNext = dateFrom + 86400000

      //if two dates
      if (selectedDateRange[1]) {
        const dateTo = Date.parse(selectedDateRange[1])
        const dateToAdded = dateTo + 86400000

        prs = prs.filter(pr => {
          return (
            Date.parse(pr.paymentRequestDate) >= dateFrom &&
            Date.parse(pr.paymentRequestDate) <= dateToAdded
          )
        })
      } else {
        prs = prs.filter(pr => {
          return (
            Date.parse(pr.paymentRequestDate) >= dateFrom &&
            Date.parse(pr.paymentRequestDate) <= dateNext
          )
        })
      }
    }

    if (!isEmpty(selectedMulti.filtered)) {
      const arrayFilter = new RegExp(selectedMulti.filtered[0].value.join("|"))

      prs = prs.filter(pr => {
        if (
          arrayFilter.test(pr.paymentStatus) ||
          arrayFilter.test(pr.itemSource) ||
          arrayFilter.test(pr.generatedLinkStatus)
        ) {
          return pr
        }
      })
    }

    setPaymentRequestList(prs)
  }

  //Filter: Multi Selection & Date for Report
  function handleFilterActionsReport(selectedMulti, selectedDateRange) {
    let prs = prListReport

    if (isEmpty(selectedDateRange) && !isEmpty(selectedMulti?.filtered)) {
      prs = prListReport
    }

    if (!isEmpty(selectedDateRange) && selectedDateRange != null) {
      const dateFrom = Date.parse(selectedDateRange[0])
      const dateNext = dateFrom + 86400000

      //if two dates
      if (selectedDateRange[1]) {
        const dateTo = Date.parse(selectedDateRange[1])
        const dateToAdded = dateTo + 86400000

        prs = prs.filter(pr => {
          return (
            Date.parse(pr.paymentRequestDate) >= dateFrom &&
            Date.parse(pr.paymentRequestDate) <= dateToAdded
          )
        })
      } else {
        prs = prs.filter(pr => {
          return (
            Date.parse(pr.paymentRequestDate) >= dateFrom &&
            Date.parse(pr.paymentRequestDate) <= dateNext
          )
        })
      }
    }

    if (!isEmpty(selectedMulti?.filtered)) {
      const arrayFilter = new RegExp(selectedMulti?.filtered[0].value.join("|"))

      prs = prs.filter(pr => {
        if (
          arrayFilter.test(pr.paymentStatus) ||
          arrayFilter.test(pr.itemSource) ||
          arrayFilter.test(pr.generatedLinkStatus)
        ) {
          return pr
        }
      })
    }

    setPrListReportFiltered(prs)
  }

  //Resending Email
  const handleSendPaymentLinkEmail = (id, recipient) => {
    var data = {
      id: id,
      recipient: recipient,
      actiontype: "Resending Payment Request",
    }

    if (data.id) {
      dispatch(sendPaymentRequestEmail(data))
    }
  }

  function clearToast() {
    toastr.clear()
  }

  ///context
  const PaymentRequestContextValue = {
    handleFilterActions,
    handleSendPaymentLinkEmail,
    handleDownloadReport,
    handleDownloadReportHistory,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Payment Requests"
            breadcrumbItem="Payment Requests"
          />
          {success_dlg ? (
            <SweetAlert
              timeout={1000}
              success
              showConfirm={false}
              title={dynamic_title}
              onConfirm={() => {
                setsuccess_dlg(false)
                window.location.reload()
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}

          {error_dlg ? (
            <SweetAlert
              timeout={1000}
              error
              showConfirm={false}
              title={dynamic_title}
              onConfirm={() => {
                seterror_dlg(false)
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}

          {errorCatching(errorResponse, setErrorResponse)}

          <PaymentRequestContext.Provider value={PaymentRequestContextValue}>
            <SearchForm
              userRole={user.role}
              merchantList={merchantList}
              downloadsList={downloadsList}
              loadingDownloadHistotry={loadingDownloadHistotry}
            />
            <PaymentRequestList
              paymentRequestList={paymentRequestList}
              isLoaded={isLoaded}
              onChange={value => setArchivePR(value)}
            />
          </PaymentRequestContext.Provider>
        </Container>
        <CSVLink
          data={prListReportFiltered}
          filename="PaymentRequestList.csv"
          className="hidden"
          headers={headers}
          ref={csvLink}
          target="_blank"
          key="_id"
        />
      </div>
    </React.Fragment>
  )
}

PaymentRequests.propTypes = {
  paymentRequests: PropTypes.any,
  onGetPaymentRequests: PropTypes.func,
  emailStatus: PropTypes.bool,
  loadingEmail: PropTypes.bool,
}

const mapStateToProps = pr => ({
  paymentRequests: pr.paymentRequests.paymentRequestList,
  paymentRequestsReport: pr.paymentRequests.paymentRequestListReport,
  error: pr.paymentRequests.error,
  loading: pr.paymentRequests.loading,
  loadingEmail: pr.paymentRequests.loadingEmail,
  merchantList: pr.Merchants.merchantList,
  paymentRequestArchiveResult: pr.PRArchived.paymentRequestArchiveResult,
  downloadsList: pr.PaymentHistory.downloadsList,
  loadingDownloadHistotry: pr.PaymentHistory.loading,
})

const mapDispatchToProps = dispatch => ({
  onGetPaymentRequests: merchantId =>
    dispatch(getPaymentRequestList(merchantId)),
  onGetPaymentRequestsReport: merchantId =>
    dispatch(getPaymentRequestListReport(merchantId)),
  onPatchPaymentRequestArchive: data =>
    dispatch(patchPaymentRequestArchive(data)),
  onGetMerchantList: () => dispatch(getMerchantList()),
  onDownloadPaymentHistory: data => dispatch(downloadPaymentHistory(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentRequests)
