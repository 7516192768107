import React from "react"
import { Col, FormGroup, Input, Row, Button, Form } from "reactstrap"
const Items = props => {
  const {
    item,
    index,
    handleChangeSingleItem,
    handleDeleteItem,
    handleSubmit,
    onSubmit,
    register,
    errors,
  } = props

  const inputItem = "Item" + index
  const inputQty = "Qty" + index
  const inputAmount = "Amount" + index
  const inputRefund = "Refundable" + index

  function handleChange(changes) {
    handleChangeSingleItem(item.id, { ...item, ...changes })
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg="5">
            <FormGroup>
              <small>Enter Product / Service</small>
              <input
                value={item.ItemName}
                onChange={e => handleChange({ ItemName: e.target.value })}
                name={inputItem}
                type="text"
                className="form-control"
                placeholder=""
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                })}
              />
              <small className="color-danger">
                {errors[inputItem] && errors[inputItem].message}
              </small>
            </FormGroup>
          </Col>

          <Col lg="1">
            <FormGroup>
              <small>Quantity</small>
              <input
                value={item.Qty}
                onChange={e => handleChange({ Qty: parseInt(e.target.value) })}
                type="number"
                name={inputQty}
                className="form-control"
                placeholder="Enter Quantity"
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                  min: {
                    value: 1,
                    message: "Not a valid qty",
                  },
                })}
              />
              <small className="color-danger">
                {errors[inputQty] && errors[inputQty].message}
              </small>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <small>Amount</small>
              <input
                onChange={e =>
                  handleChange({ Amount: parseFloat(e.target.value) })
                }
                value={item.Amount}
                type="number"
                name={inputAmount}
                className="form-control"
                placeholder="Enter Amount"
                step="1"
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                  min: {
                    value: 1,
                    message: "Not a valid amount",
                  },
                })}
              />
              <small className="color-danger">
                {errors[inputAmount] && errors[inputAmount].message}
              </small>
            </FormGroup>
          </Col>
          <Col lg="1" className="">
            <FormGroup>
              <small>Refundable</small>
              <br />
              <input
                onChange={e => handleChange({ Refundable: !item.Refundable })}
                value={item.Refundable}
                type="checkbox"
                name={inputRefund}
                className="mx-3 my-2"
              />
            </FormGroup>
          </Col>

          <Col lg="2">
            <FormGroup>
              {/* <label style={{ color: "white" }} htmlFor="subject">Delete </label> */}
              <br />
              <Button
                onClick={() => handleDeleteItem(item.id)}
                color="danger"
                className="btn-block inner"
                style={{ width: "100%" }}
              >
                {" "}
                Delete{" "}
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default Items
