import React, {
  useEffect,
  useState,
  useContext,
  forwardRef,
  useImperativeHandle,
} from "react"
import { Col, Form, FormGroup, Label, Row, Button } from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import Contacts from "../Contacts"
import { v4 as uuidv4 } from "uuid"
import { useForm } from "react-hook-form"
import { isEmpty } from "lodash"
import { MerchantCreateContext } from "../index"
import ConvenienceFeeSettings from "../ConvenienceFeeSettings"

export const FirstMerchantInfo = forwardRef((props, ref) => {
  const {
    handleMerchantChange,
    handleMobilePlusChange,
    handleTelephonePlusChange,
    merchantDetails,
    toggleTabProgress,
    activeTabProgress,
    countryCodes,
  } = props

  const { register, handleSubmit, errors, reset, watch } = useForm()
  const [pick_date, setPickDate] = useState("")
  const { settingFirstStepError } = useContext(MerchantCreateContext)
  const [validContactDetailsArray, setValidContactDetailsArray] = useState([])
  const [MobilePlus, setMobilePlus] = useState("+974")
  const [TelephonePlus, setTelephonePlus] = useState("+974")
  const [telephone, setTelephone] = useState("")
  const [mobile, setMobile] = useState("")
  const [isConvenienceValid, setConvenienceValid] = useState(true)
  const [isCFBasis, setIsCFBasis] = useState(true)
  useEffect(() => {
    if (validContactDetailsArray)
      setValidContactDetailsArray(validContactDetailsArray)
  }, [validContactDetailsArray])

  useEffect(() => {
    let basis =
      merchantDetails?.PaymentGatewayEnrollments[0]?.ConvenienceFee.filter(
        i => i.Basis === ""
      )
    let value =
      merchantDetails?.PaymentGatewayEnrollments[0]?.ConvenienceFee.filter(
        i => i.Fixed === 0 && i.Percentage === 0
      )
    if (basis?.length === 0) {
      setConvenienceValid(false)
      if (value?.length === 0) {
        setConvenienceValid(true)
      }
    }
    handleCFBasis(merchantDetails?.PaymentGatewayEnrollments[0]?.ConvenienceFee)
  }, [merchantDetails?.PaymentGatewayEnrollments[0]?.ConvenienceFee])
  useEffect(() => {}, [isConvenienceValid])
  useEffect(() => {
    //console.log(isCFBasis)
  }, [isCFBasis])

  function handleCFBasis(cfs) {
    let basis = []
    if (cfs?.length > 0) {
      cfs.map((cf, i) => {
        if (basis.includes(cf.Basis)) {
          setIsCFBasis(false)
        } else {
          setIsCFBasis(true)
          basis.push(cf.Basis)
        }
      })
    }
  }

  function handleChange(changes) {
    // console.log(changes)
    handleMerchantChange({ ...merchantDetails, ...changes })
  }
  function handlePaymentGatewayChange(id, paymentGateway) {
    // console.log(paymentGateway)
    const newPaymentGateways = [...merchantDetails.PaymentGatewayEnrollments]
    const index = newPaymentGateways.findIndex(i => i.id === id)
    newPaymentGateways[index] = paymentGateway
    handleChange({ PaymentGatewayEnrollments: newPaymentGateways })
  }
  function handleChangePG(changes) {
    // console.log("Changes", changes)
    handlePaymentGatewayChange(
      merchantDetails.PaymentGatewayEnrollments[0].id,
      {
        ...merchantDetails.PaymentGatewayEnrollments[0],
        ...changes,
      }
    )
  }

  function handleMobilePChange(changes) {
    handleMobilePlusChange(changes)
  }

  function handleTelephonePChange(changes) {
    handleTelephonePlusChange(changes)
  }
  function handleContactChange(id, contact) {
    const newContacts = [...merchantDetails.Contacts]
    const index = newContacts.findIndex(i => i.id === id)
    newContacts[index] = contact
    handleChange({ Contacts: newContacts })
  }
  function handleConvenienceChange(id, convenience) {
    // console.log(id, transaction)
    const newConvenience = [
      ...merchantDetails?.PaymentGatewayEnrollments[0]?.ConvenienceFee,
    ]
    const index = newConvenience.findIndex(i => i.id === id)
    // console.log(newConvenience[index])
    newConvenience[index] = convenience
    // console.log(newConvenience[index])
    handleChangePG({ ConvenienceFee: newConvenience })
  }
  function handleContactAdd() {
    const newContact = {
      id: uuidv4(),
      Name: "",
      Mobile: "",
      Email: "",
      Position: "Administrator",
    }
    handleChange({ Contacts: [...merchantDetails.Contacts, newContact] })
  }
  function handleConvenienceAdd() {
    const newConvenience = {
      id: uuidv4(),
      Basis: "",
      Fixed: 0,
      Percentage: 0,
    }
    handleChangePG({
      ConvenienceFee: [
        ...merchantDetails.PaymentGatewayEnrollments[0].ConvenienceFee,
        newConvenience,
      ],
    })
  }
  function handleConvenienceDelete(id) {
    //console.log(id)
    handleChangePG({
      ConvenienceFee:
        merchantDetails?.PaymentGatewayEnrollments[0]?.ConvenienceFee.filter(
          i => i.id !== id
        ),
    })
  }
  function handleContactDelete(id, index) {
    handleChange({
      Contacts: merchantDetails.Contacts.filter(i => i.id !== id),
    })

    let tempArray = validContactDetailsArray.filter(
      item => !item.includes(index.toString())
    )
    setValidContactDetailsArray(tempArray)
  }

  //used to call this method from parent (not working and temporarily disabled)
  useImperativeHandle(ref, () => ({
    trigger() {
      settingFirstStepError(errors)
      document.getElementById("my-form_submit_1").click()
    },
  }))

  const onSubmit = data => handleCheckNoErrors()

  function handleCheckNoErrors() {
    //console.log(TelephonePlus)
    if (isEmpty(errors)) {
      toggleTabProgress(activeTabProgress + 1)
    }
  }

  function parseBool(value) {
    var bool = value === "true" ? true : false
    return bool
  }

  const [isMerchantNameValid, setIsMerchantNameValid] = useState()

  function handleMerchantName(value) {
    var pattern = /^[A-Za-z0-9\s\'\,\-\(\)]+$/

    if (pattern.test(value)) {
      setIsMerchantNameValid(true)
    } else {
      setIsMerchantNameValid(false)
    }

    handleChange({ MerchantName: value })
  }

  const [isWebsiteValid, setIsWebsiteValid] = useState()

  function handleWebsite(value) {
    var pattern =
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
    // /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/

    if (pattern.test(value)) {
      setIsWebsiteValid(true)
    } else {
      setIsWebsiteValid(false)
    }

    handleChange({ Website: value })
  }

  const [isTelephoneValid, setIsTelephoneValid] = useState()

  function handleTelephone(value) {
    var pattern = /^(\+|)[0-9]{8,}$/

    if (pattern.test(value)) {
      setIsTelephoneValid(true)
    } else {
      setIsTelephoneValid(false)
    }

    handleChange({ PhoneNumber: value })
  }

  const [isCityValid, setIsCityValid] = useState()

  function handleCity(value) {
    var pattern = /^[A-Za-z\s]+$/

    if (pattern.test(value)) {
      setIsCityValid(true)
    } else {
      setIsCityValid(false)
    }

    handleChange({ City: value })
  }

  const [isAddressValid, setIsAddressValid] = useState()

  function handleAddress(value) {
    var pattern = /^[A-Za-z0-9\s\.\#\,\-]+$/

    if (pattern.test(value)) {
      setIsAddressValid(true)
    } else {
      setIsAddressValid(false)
    }

    handleChange({ Address: value })
  }

  const [isContactNameValid, setIsContactNameValid] = useState()
  function handleContactName(value) {
    var pattern = /^[A-Za-z\s]+$/

    if (pattern.test(value)) {
      setIsContactNameValid(true)
    } else {
      setIsContactNameValid(false)
    }

    handleContactChange(merchantDetails.Contacts[0].id, {
      ...merchantDetails.Contacts[0],
      Name: value,
    })
  }

  const [isMobileValid, setIsMobileValid] = useState()
  function handleMobile(value) {
    var pattern = /^(\+|)[0-9]{8,}$/

    if (pattern.test(value)) {
      setIsMobileValid(true)
    } else {
      setIsMobileValid(false)
    }

    handleContactChange(merchantDetails.Contacts[0].id, {
      ...merchantDetails.Contacts[0],
      Mobile: value,
    })
  }

  const [isEmailValid, setIsEmailValid] = useState()
  function handleEmail(value) {
    var pattern =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

    if (pattern.test(value)) {
      setIsEmailValid(true)
    } else {
      setIsEmailValid(false)
    }

    handleContactChange(merchantDetails.Contacts[0].id, {
      ...merchantDetails.Contacts[0],
      Email: value,
    })
  }

  function handlePosition(value) {
    handleContactChange(merchantDetails.Contacts[0].id, {
      ...merchantDetails.Contacts[0],
      Position: value,
    })
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} id="my-form">
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label for="basicpill-merchantname-input14">
                Merchant Name <span style={{ color: "red" }}>*</span>
              </Label>
              <input
                autoComplete="off"
                type="text"
                value={merchantDetails.MerchantName}
                name="MerchantName"
                onChange={e => handleMerchantName(e.target.value)}
                onBlur={e => handleMerchantName(e.target.value)}
                className="form-control"
                id="MerchantName"
              />

              {isMerchantNameValid === false && (
                <small className="color-danger" style={{ float: "left" }}>
                  Invalid Merchant Name.
                </small>
              )}
            </FormGroup>
          </Col>

          <Col lg="6">
            <FormGroup>
              <Label>
                Merchant Type <span style={{ color: "red" }}>*</span>
              </Label>
              <select
                className="custom-select"
                name="Type"
                value={merchantDetails.Type}
                onChange={e => handleChange({ Type: e.target.value })}
              >
                <option value="" defaultValue hidden="hidden">
                  Choose here
                </option>
                <option value="Travel Agency">Travel Agency</option>
                <option value="Online Retail">Online Retail</option>
                <option value="Others">Other</option>
              </select>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label for="basicpill-website-input15">
                Website / Facebook <small>(https://www.example.com)</small>{" "}
                <span style={{ color: "red" }}>*</span>
              </Label>
              <input
                autoComplete="off"
                value={merchantDetails.Website}
                type="text"
                name="Website"
                onChange={e => handleWebsite(e.target.value)}
                onBlur={e => handleWebsite(e.target.value)}
                className="form-control"
                id="Website"
              />
              {isWebsiteValid === false && (
                <small className="color-danger" style={{ float: "left" }}>
                  Invalid Website.
                </small>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label for="basicpill-phoneno-input16">
                Telephone <span style={{ color: "red" }}>*</span>
              </Label>
              <Row>
                <Col md={4}>
                  <select
                    className="form-control  custom-select input-text-size"
                    id="TelephonePlus"
                    name="TelephonePlus"
                    value={TelephonePlus}
                    ref={register({ required: true })}
                    onChange={event => {
                      setTelephonePlus(event.target.value)
                      handleTelephonePChange(event.target.value)
                    }}
                  >
                    <option value="+974" hidden="hidden" defaultValue>
                      +974
                    </option>
                    {countryCodes?.map((cc, i) => {
                      return (
                        <option value={"+" + cc.countryCode} key={i}>
                          +{cc.countryCode}
                        </option>
                      )
                    })}
                  </select>
                </Col>
                <Col md={8}>
                  {" "}
                  <input
                    autoComplete="off"
                    value={merchantDetails.PhoneNumber}
                    type="text"
                    name="PhoneNumber"
                    onBlur={e => handleTelephone(e.target.value)}
                    onChange={e => {
                      // setTelephone(e.target.value)
                      handleTelephone(e.target.value)
                    }}
                    className="form-control"
                    id="PhoneNumber"
                  />
                </Col>
              </Row>

              {isTelephoneValid === false && (
                <small className="color-danger" style={{ float: "left" }}>
                  Invalid Telephone Number.
                </small>
              )}
            </FormGroup>
            <FormGroup hidden={true}>
              <Label for="basicpill-tc-input15">Terms & Condition</Label>
              <input
                autoComplete="off"
                value={merchantDetails.Website}
                type="text"
                name="TermsConditionUrl"
                onChange={e =>
                  handleChange({ TermsConditionUrl: e.target.value })
                }
                className="form-control"
                id="basicpill-tc-input15"
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                  pattern: {
                    value: /^https?:\/\/.+$/,
                    message: "invalid url",
                  },
                })}
              />
              <small className="color-danger">
                {errors.TermsConditionUrl && errors.TermsConditionUrl.message}
              </small>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg="6">
            <FormGroup>
              <Label for="basicpill-bus-input17">
                Business Registration No
              </Label>
              <input
                autoComplete="off"
                value={merchantDetails.BusinessRegNumber}
                type="text"
                name="BusinessRegNumber"
                onChange={e =>
                  handleChange({ BusinessRegNumber: e.target.value })
                }
                className="form-control"
                id="basicpill-bus-input17"
              />
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label for="basicpill-tax-input16">Tax Registration No</Label>
              <input
                autoComplete="off"
                value={merchantDetails.TaxRegNumber}
                type="text"
                name="TaxRegNumber"
                onChange={e => handleChange({ TaxRegNumber: e.target.value })}
                className="form-control"
                id="basicpill-tax-input16"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6"></Col>
        </Row>
        <Row>
          <Col lg="4">
            <FormGroup>
              <Label for="basicpill-city-input16">
                City <span style={{ color: "red" }}>*</span>
              </Label>
              <input
                autoComplete="off"
                value={merchantDetails.City}
                type="text"
                name="City"
                onBlur={e => handleCity(e.target.value)}
                onChange={e => handleCity(e.target.value)}
                className="form-control"
                id="City"
              />

              {isCityValid === false && (
                <small className="color-danger" style={{ float: "left" }}>
                  Invalid City.
                </small>
              )}
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <Label>Country</Label>
              <select
                ref={register({ required: true })}
                className="custom-select"
                name="Country"
                value={merchantDetails.Country}
                onChange={e => handleChange({ Country: e.target.value })}
              >
                {/* <option value="" selected="selected" hidden="hidden">
                  Choose here
                </option> */}
                <option value="Qatar">Qatar</option>
              </select>
              {errors.Country && errors.Country.type === "required" && (
                <small className="color-danger">This is required</small>
              )}
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <Label for="basicpill-PoBox-input16">Po Box</Label>
              <input
                autoComplete="off"
                value={merchantDetails.PoBox}
                type="text"
                name="PoBox"
                onChange={e => handleChange({ PoBox: e.target.value })}
                className="form-control"
                id="basicpill-PoBox-input16"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup>
              <Label for="basicpill-address-input2">
                Address <span style={{ color: "red" }}>*</span>
              </Label>
              <textarea
                autoComplete="off"
                value={merchantDetails.Address}
                name="Address"
                onBlur={e => handleAddress(e.target.value)}
                onChange={e => handleAddress(e.target.value)}
                id="Address"
                className="form-control"
                rows="2"
              />

              {isAddressValid === false && (
                <small className="color-danger" style={{ float: "left" }}>
                  Invalid Address.
                </small>
              )}
            </FormGroup>
          </Col>
        </Row>

        <hr></hr>
        <b>Add Company Contact Person</b>
        <p>For internal purposes only.</p>

        <Row>
          <Col lg="3">
            <FormGroup>
              <input
                autoComplete="off"
                value={merchantDetails.Contacts[0].Name}
                name="ContactName"
                onBlur={e => handleContactName(e.target.value)}
                onChange={e => handleContactName(e.target.value)}
                type="text"
                className="form-control"
                placeholder="Enter Name *"
              />

              {isContactNameValid === false && (
                <small className="color-danger" style={{ float: "left" }}>
                  Invalid Contact Name.
                </small>
              )}
            </FormGroup>
          </Col>

          <Col lg="4">
            <FormGroup>
              {/* <label htmlFor="subject">Mobile </label> */}
              <Row>
                <Col md={4}>
                  <select
                    className="form-control  custom-select input-text-size"
                    id="MobilePlus"
                    name="MobilePlus"
                    value={MobilePlus}
                    ref={register({ required: true })}
                    onChange={event => {
                      setMobilePlus(event.target.value)
                      handleMobilePChange(event.target.value)
                    }}
                  >
                    <option value="+974" hidden="hidden" defaultValue>
                      +974
                    </option>
                    {countryCodes?.map((cc, i) => {
                      return (
                        <option value={"+" + cc.countryCode} key={i}>
                          +{cc.countryCode}
                        </option>
                      )
                    })}
                  </select>
                </Col>
                <Col md={8}>
                  <input
                    autoComplete="off"
                    value={merchantDetails.Contacts[0].Mobile}
                    type="text"
                    name="ContactMobile"
                    onBlur={e => handleMobile(e.target.value)}
                    onChange={e => {
                      // setMobile(e.target.value)
                      handleMobile(e.target.value)
                    }}
                    className="form-control"
                    placeholder="Enter Mobile *"
                  />
                </Col>
              </Row>

              {isMobileValid === false && (
                <small className="color-danger" style={{ float: "left" }}>
                  Invalid Mobile.
                </small>
              )}
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              {/* <label htmlFor="subject">Email </label> */}
              <input
                autoComplete="off"
                value={merchantDetails.Contacts[0].Email}
                type="email"
                name="ContactEmail"
                onBlur={e => handleEmail(e.target.value)}
                onChange={e => handleEmail(e.target.value)}
                className="form-control input-place-holder"
                placeholder="Enter Email"
              />
              {isEmailValid === false && (
                <small className="color-danger" style={{ float: "left" }}>
                  Invalid Email.
                </small>
              )}
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              {/* <label htmlFor="subject">Position </label> */}
              <select
                className="custom-select"
                value={merchantDetails.Contacts[0].Position}
                name="ContactPosition"
                onChange={e => handlePosition(e.target.value)}
              >
                <option value="Administrator">Administrator</option>
                <option value="General Manager">General Manager</option>
                <option value="Finance Manager">Finance Manager</option>
                <option value="Operations Manager">Operations Manager</option>
                <option value="Supervisor">Supervisor</option>
                <option value="Others">Other</option>
              </select>
            </FormGroup>
          </Col>
          <Col lg="1">
            <FormGroup>
              {/* <label style={{ color: "white" }} htmlFor="subject">Delete </label> */}

              {/* <Button
                onClick={() => handleContactDelete(contact.id,index)}
                color="danger"
                className="btn-block inner"
                style={{ width: "100%" }}
              >
                {" "}
                <i className="mdi mdi-close-thick"/>
              </Button> */}
            </FormGroup>
          </Col>
        </Row>
        <hr></hr>
        <b>Add Convenience Fees</b>
        <p>Additional charge to customer by payment type.</p>
        {!isCFBasis ? (
          <p className="color-danger">
            Duplicate payment types are not allowed.
          </p>
        ) : null}
        {merchantDetails.PaymentGatewayEnrollments[0].ConvenienceFee &&
          merchantDetails.PaymentGatewayEnrollments[0].ConvenienceFee.map(
            (convenience, index) => (
              <ConvenienceFeeSettings
                index={index}
                indexPG={props.index}
                key={index}
                convenience={convenience}
                handleConvenienceChange={handleConvenienceChange}
                handleConvenienceDelete={handleConvenienceDelete}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                register={register}
                errors={errors}
              />
            )
          )}
        <div style={{ padding: "10px" }}>
          {" "}
          <Button
            onClick={() => handleConvenienceAdd()}
            color="warning"
            className="mt-1"
          >
            + Add Convenience Fee
          </Button>
        </div>
        {/* <Button
          onClick={() => handleContactAdd()}
          color="success"
          className="mt-1"
        >
          <i className="fa fas fa-plus" /> New Contact
        </Button> */}

        <ul className="pager wizard twitter-bs-wizard-pager-link">
          {/* <li
            className={
              activeTabProgress === 1 ? "previous disabled" : "previous"
            }
          >
            <Button
              color="primary"
              onClick={() => {
                toggleTabProgress(activeTabProgress - 1)
              }}
            >
              {" "}
              Previous{" "}
            </Button>
          </li> */}
          <li className={activeTabProgress === 3 ? "next" : "next"}>
            {isMerchantNameValid &&
            isWebsiteValid &&
            isTelephoneValid &&
            isCityValid &&
            isAddressValid &&
            isContactNameValid &&
            isMobileValid &&
            isConvenienceValid &&
            isEmailValid &&
            isCFBasis ? (
              <Button
                onClick={e => toggleTabProgress(activeTabProgress + 1)}
                color="success"
                id="my-form_submit_1"
              >
                {" "}
                Next{" "}
              </Button>
            ) : (
              <Button color="primary" id="NextDisabled" disabled>
                {" "}
                Next{" "}
              </Button>
            )}
          </li>
        </ul>
      </Form>
    </>
  )
})

export default FirstMerchantInfo
