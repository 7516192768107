import React, { useState, useEffect } from "react"
import { Col, Row, Label } from "reactstrap"

function EnabledPaymentTypes(props) {
  const {
    enabledPTOptions,
    handlePaymentOptionChanges,
    newPTOptions,
    setClick,
    click,
  } = props

  useEffect(() => {
    //console.log(enabledPTOptions)
  }, [enabledPTOptions])
  useEffect(() => {
    //
  }, [newPTOptions])
  useEffect(() => {}, [click])
  function handleSortedOptions(value) {
    let checked = newPTOptions?.find(i => {
      return i === value
    })
    if (checked) return true
    else return false
  }
  return (
    <React.Fragment>
      {enabledPTOptions?.map(ptOption => {
        return (
          <Row className="my-2">
            {/* <Col></Col> */}
            <Col className="mx-4">
              <input
                className="pointer"
                type="radio"
                checked={
                  click
                    ? handleSortedOptions(parseInt(ptOption.id))
                    : ptOption.enabled
                }
                onClick={() => {
                  setClick(true)
                  handlePaymentOptionChanges(parseInt(ptOption.id))
                }}
              />{" "}
              <Label
                className="pointer"
                onClick={() => {
                  setClick(true)
                  handlePaymentOptionChanges(parseInt(ptOption.id))
                }}
              >
                {ptOption?.basis.toUpperCase()}
              </Label>
            </Col>
          </Row>
        )
      })}
    </React.Fragment>
  )
}

export default EnabledPaymentTypes
