import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Col, Container, Row } from "reactstrap"
import { isEmpty } from "lodash"
import { getMerchantDetail } from "store/actions"
import { getUserListByMerchantId } from "store/actions"
import { Button } from "reactstrap"
import PropTypes from "prop-types"
import LoadingSpinnerThreeDots from "../../helpers/commonhelpers/loadingSpinner"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Contacts from "./Contacts"
import MerchantInfo from "./MerchantInfo"
import NotificationEmails from "./NotificationEmails"
import OfficeIds from "./OfficeIds"
import PaymentGatewayEnrollments from "./PaymentGatewayEnrollments"
import Configuration from "./Configuration"
import ConvenienceFee from "./ConvenienceFee"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import Users from "./Users"
import AuthUser from "components/AuthUser/AuthUser"
import { Link } from "react-router-dom"
import SweetAlert from "react-bootstrap-sweetalert"
import MultiPGCards from "./MultiPGPayment"
import errorCatching from "../../helpers/commonhelpers/errorCatching"
import CardFee from "./CardFee"

export const MerchantOverview = props => {
  //TODO: edit and delete

  const {
    onGetMerchantDetail,
    onGetUserList,
    merchantDetails,
    userList,
    loading,
    loadingUserList,
    merchantUpdated,
    error,
    match: { params },
  } = props

  const [isLoaded, setIsLoaded] = useState()
  const [user, setUser] = useState(AuthUser())
  const [merchantDetail, setMerchantDetail] = useState()
  const [convenienceFees, setCFs] = useState([])
  const [cardFees, setCardFees] = useState([])
  const [errorResponse, setErrorResponse] = useState(undefined)

  useEffect(() => {
    if (user) {
      // console.log(params.id)
      if (user.role === "SuperAdmin") {
        if (params && params.id) {
          onGetMerchantDetail(params.id)
          onGetUserList(params.id)
        }
      } else {
        if (user.merchantId) {
          onGetMerchantDetail(user.merchantId)
        }
      }
    }
  }, [params, onGetMerchantDetail])
  // useEffect(() => {
  //   // console.log(merchantUpdated)
  //   if (merchantUpdated && merchantUpdated === true) {
  //     //showToastSuccess("Merchant successfully updated!")

  //   }
  //   if (merchantUpdated && merchantUpdated === false) {
  //     //showToastError("Merchant not updated!")
  //   }
  //   // resetState()
  // }, [merchantUpdated])
  useEffect(() => {
    setIsLoaded(loading)
  }, [loading])

  useEffect(() => {
    //console.log(merchantDetail)
    if (
      user?.role === "SuperAdmin" &&
      merchantDetails &&
      params.id === merchantDetails.merchantId
    ) {
      setMerchantDetail(merchantDetails)
    }
    if (user?.role === "Admin" && params.id === "0") {
      setMerchantDetail(merchantDetails)
    }
  }, [merchantDetails])

  useEffect(() => {
    let cfs = []

    merchantDetail?.paymentGatewayEnrollments?.map(pg => {
      pg?.convenienceFee?.map(cf => {
        cfs.push({
          pgId: pg.paymentGatewayId,
          pgName: pg.paymentGateway,
          basis: cf.basis,
          fixed: cf.fixed,
          percentage: cf.percentage,
        })
      })
    })
    setCFs(cfs)

    // let cardFs = []
    // merchantDetail?.paymentGatewayEnrollments?.map(pg => {
    //   pg?.cardFee?.map(cf => {
    //     cardFs.push({
    //       pgId: pg.paymentGatewayId,
    //       pgName: pg.paymentGateway,
    //       basis: cf.basis,
    //       fixed: cf.fixed,
    //       percentage: cf.percentage,
    //     })
    //   })
    // })
    // setCardFees(cardFs)
  }, [merchantDetail])

  useEffect(() => {
    // console.log(convenienceFees)
  }, [convenienceFees])
  useEffect(() => {
    //console.log(cardFees)
  }, [cardFees])
  useEffect(() => {
    if (error !== undefined && !isEmpty(error)) setErrorResponse(error)
  }, [error])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Overview " breadcrumbItem=" MY BUSINESS" />
          {errorCatching(errorResponse, setErrorResponse)}
          {isLoaded ? (
            <LoadingSpinnerThreeDots />
          ) : (
            !isEmpty(merchantDetail) && (
              <>
                <Row>
                  {user[0]?.role !== "User" ? (
                    <Col lg="12" className=" my-2">
                      {" "}
                      <Link
                        to={"/merchant/edit/" + merchantDetail.merchantId}
                        className="mr-2 mb-1 float-right"
                      >
                        <i
                          className="mdi mdi-pencil  font-size-18 mr-1 "
                          id="edittooltip"
                        />
                        Update Merchant
                      </Link>
                    </Col>
                  ) : null}
                  <Col lg="12">
                    <MerchantInfo merchantDetail={merchantDetail} />
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    {!isEmpty(merchantDetail.contacts) && (
                      <Contacts contacts={merchantDetail?.contacts} />
                    )}
                  </Col>

                  <Col lg="6">
                    <PaymentGatewayEnrollments
                      paymentGatewayEnrollments={
                        merchantDetail?.paymentGatewayEnrollments
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  {convenienceFees ? (
                    <Col lg="12">
                      <ConvenienceFee
                        convenienceFees={convenienceFees}
                        user={user}
                      />
                    </Col>
                  ) : null}
                  {/* {cardFees ? (
                    <Col lg="6">
                      <CardFee cardFees={cardFees} user={user} />
                    </Col>
                  ) : null} */}
                </Row>
                <Row>
                  <Col lg="6">
                    <MultiPGCards
                      paymentTypes={
                        merchantDetail?.paymentGatewaySettings
                          ?.enabledPaymentOptions
                      }
                      commissionSettings={
                        merchantDetail?.paymentGatewayEnrollments
                      }
                    />
                  </Col>
                  <Col lg="6">
                    <NotificationEmails
                      notificationEmails={merchantDetail?.notificationEmails}
                    />
                  </Col>
                </Row>
                <Row>
                  {merchantDetail?.type === "Online Retail" ? (
                    <Col lg="12">
                      <Configuration
                        linkValidity={
                          merchantDetail?.paymentGatewaySettings?.linkValidity
                        }
                        marketingBanner={merchantDetail?.banner}
                        logo={merchantDetail?.logo}
                      />
                    </Col>
                  ) : (
                    <>
                      <Col lg="6">
                        <OfficeIds officeIds={merchantDetail?.officeIds} />
                      </Col>
                      <Col lg="6">
                        <Configuration
                          linkValidity={
                            merchantDetail?.paymentGatewaySettings?.linkValidity
                          }
                          marketingBanner={merchantDetail?.banner}
                          logo={merchantDetail?.logo}
                          hiddenFee={
                            merchantDetail?.paymentGatewaySettings?.feeHidden
                          }
                        />
                      </Col>
                    </>
                  )}
                </Row>
                {/* {user?.role === "SuperAdmin" && (
                  <Row>
                    <Col lg="12">
                    {!isEmpty(userList) && (
                      <Users usersByMerchant={userList} />
                    )}
                    </Col>
                  
                  </Row>
                )} */}
              </>
            )
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

MerchantOverview.propTypes = {
  userList: PropTypes.any,
  merchantDetails: PropTypes.object,
}

const mapStateToProps = state => ({
  merchantDetails: state.Merchants.merchantDetail,
  loading: state.Merchants.loading,
  userList: state.Users.usersList,
  loadingUserList: state.Users.loading,
  merchantUpdated: state.Merchants.merchantUpdated,
  error: state.Merchants.error,
})

const mapDispatchToProps = dispatch => ({
  onGetMerchantDetail: id => dispatch(getMerchantDetail(id)),
  onGetUserList: id => dispatch(getUserListByMerchantId(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MerchantOverview)
