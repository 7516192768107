import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { isEmpty } from "lodash"
import { Card, Col, CardBody, Row } from "reactstrap"
import MyFatoorah from "./PaymentGateways/MyFatoorah/MyFatoorah"
import AmazonPaymentServices from "./PaymentGateways/AmazonPaymentServices/AmazonPaymentServices"
import errorCatching from "../../helpers/commonhelpers/errorCatching"
import Dibsy from "./PaymentGateways/Dibsy/Dibsy"

const PaymentMethod = props => {
  const {
    paymentRequestDetails,
    tokenizationDetails,
    retrievedTokenDetailsCustom,
    retrievedTokenDetailsRedirect,
    myfatoorahInitiateSession,
    selectCard,
    apsCreditCustomTokenization,
    apsCreditCustom,
    apsDebitRedirect,
    apsApplePayRedirect,
    mfCreditEmbedded,
    mfDebitRedirect,
    mfApplePayEmbedded,
    dbsyCreditEmbedded,
    dbsyDebitRedirect,
    dbsyApplePayEmbedded,
    dbsyGooglePayEmbedded,
    dbsyApplePayEmbeddedMerchantSession,
    setCardType,
  } = props
  //console.log(selectCard)
  return (
    <>
      <div className="d-flex justify-content-center">
        <Col lg="8" style={{ marginTop: "0.5rem" }}>
          {isEmpty(paymentRequestDetails?.generatedLinkStatus) && (
            <>
              {errorCatching(
                { message: "No generatedLinkStatus. Please contact support." },
                null
              )}
            </>
          )}
          {paymentRequestDetails != null &&
            paymentRequestDetails?.generatedLinkStatus === "Active" && (
              <>
                <AmazonPaymentServices
                  paymentRequestDetails={paymentRequestDetails}
                  apsCreditCustomTokenization={apsCreditCustomTokenization}
                  apsCreditCustom={apsCreditCustom}
                  apsDebitRedirect={apsDebitRedirect}
                  apsApplePayRedirect={apsApplePayRedirect}
                  selectCard={selectCard}
                  setCardType={setCardType}
                />

                <MyFatoorah
                  mfCreditEmbedded={mfCreditEmbedded}
                  mfDebitRedirect={mfDebitRedirect}
                  paymentRequestDetails={paymentRequestDetails}
                  mfApplePayEmbedded={mfApplePayEmbedded}
                />

                <Dibsy
                  selectCard={selectCard}
                  setCardType={setCardType}
                  paymentRequestDetails={paymentRequestDetails}
                  dbsyCreditEmbedded={dbsyCreditEmbedded}
                  dbsyDebitRedirect={dbsyDebitRedirect}
                  dbsyApplePayEmbedded={dbsyApplePayEmbedded}
                  dbsyGooglePayEmbedded={dbsyGooglePayEmbedded}
                  dbsyApplePayEmbeddedMerchantSession={
                    dbsyApplePayEmbeddedMerchantSession
                  }
                />
              </>
            )}

          {paymentRequestDetails != null &&
            paymentRequestDetails?.generatedLinkStatus === "Inactive" && (
              <Card>
                <CardBody>
                  <p>
                    <b>Payment Status : </b>
                  </p>
                  {props.paymentRequestDetails?.paymentStatus}
                </CardBody>
              </Card>
            )}
        </Col>
      </div>
    </>
  )
}

PaymentMethod.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(PaymentMethod))

// IMPORTANT NOTE:

// INACTIVE LINK
