import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { CardTitle, Col, Row, Table } from "reactstrap"
import { refundLogo, noRefundLogo } from "components/SharedImages"
import { map } from "lodash"
import { statusPillsBool } from "../../../helpers/commonhelpers/statusPills"
const Portal = props => {
  const { purchasedItems } = props
  const [parsedItem, setParsedItem] = useState({})
  // let refundLogo =
  //   "https://res.cloudinary.com/developerqa/image/upload/v1736774509/merchant/45225461_tmyasv.jpg"
  // let noRefundLogo =
  //   "https://res.cloudinary.com/developerqa/image/upload/v1737881239/48694532_xmp67w.svg"
  useEffect(() => {
    var parsedItem = JSON.parse(
      props.purchasedItems ? props.purchasedItems : "null"
    )
    setParsedItem(parsedItem)
  }, [])

  return (
    <>
      {/* <Row >
                <Col sm="12">
                    <CardTitle style={{ background: "#EAEFF5", padding: "1px", }}><small>{props.t("ITEM DETAILS")}</small></CardTitle>
                </Col>
            </Row> */}

      <Row>
        <Col sm="12">
          <Table size="sm" style={{ textAlign: "left" }}>
            <tbody>
              <tr>
                <td>
                  <small>
                    {" "}
                    <b>{props.t("ITEM NAME")}</b>
                  </small>
                </td>
                <td>
                  <small>
                    {" "}
                    <b>{props.t("QUANTITY")}</b>
                  </small>
                </td>
                <td>
                  <small>
                    {" "}
                    <b>{props.t("AMOUNT")}</b>
                  </small>
                </td>
                <td>
                  <small>
                    {" "}
                    <b>{props.t("REFUNDABLE")}</b>
                  </small>
                </td>
              </tr>
              {parsedItem?.Items ? (
                map(parsedItem.Items, (item, index) => (
                  <tr key={index}>
                    <td>
                      {" "}
                      <small>{item.ItemName}</small>
                    </td>
                    <td>
                      {" "}
                      <small>{item.Qty}</small>
                    </td>
                    <td>
                      {" "}
                      <small>{item.Amount}</small>
                    </td>
                    <td>
                      {" "}
                      <small>
                        {/* {statusPillsBool(
                          item.Refundable,
                          item.Refundable.toString(),
                          true
                        )} */}
                        {item.Refundable ? //   height={"20px"} //   width={"60px"} //   src={refundLogo} // <img
                        //   alt="refund logo"
                        //   className="mx-2"
                        // />
                        null : (
                          <img
                            src={noRefundLogo}
                            width={"60px"}
                            height={"30px"}
                            alt="refund logo"
                            className="mx-2"
                          />
                        )}
                      </small>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>
                    <small>{props.t("Items bought not specified")}</small>
                  </td>
                  <td>
                    <small>{props.t("N/A")}</small>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row>
        <Col sm="12"> </Col>
      </Row>

      {/* <Row>
                <Col style={{ "marginTop": "1rem" }}></Col>
            </Row> */}
    </>
  )
}

Portal.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Portal))
