import React, {
  useEffect,
  useState,
  useContext,
  forwardRef,
  useRef,
} from "react"
import { connect, useDispatch } from "react-redux"
import {
  Col,
  Form,
  FormGroup,
  Label,
  Row,
  Button,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalBody,
} from "reactstrap"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import "flatpickr/dist/themes/material_blue.css"
import { useForm } from "react-hook-form"
import { isEmpty } from "lodash"
import Items from "../Items"
import { v4 as uuidv4 } from "uuid"
import CustomerPopup from "../../../components/Customer/CustomerList"
import { withRouter } from "react-router-dom"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { autofill } from "redux-form"
import SelectPaymentTypes from "../../../components/PaymentTypesSelect/index"
import PaymentTypesCards from "../PaymentTypesCards"
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "red" : "blue",
    backgroundColor: "white",
    padding: 10,
  }),
  control: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    height: state.selectProps.height,
    padding: 10,
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    minLength: 10,
    textOverflow: "none",
    width: state.selectProps.width,
  }),
}

export const FirstPaymentRequestDetails = forwardRef((props, ref) => {
  const {
    prDetails,
    itemDetails,
    handlePaymentRequestChange,
    handlePurchasedItemChange,
    toggleTabProgress,
    activeTabProgress,
    loadSubmitNowButton,
    commissionSettingsPaymentTypes,
    paymentList,
    merchantLoading,
  } = props
  const multi = {
    filtered: [],
    select2: undefined,
  }
  const animatedComponents = makeAnimated()
  const [showPurchase, setShowPurchase] = useState(false)
  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const { register, handleSubmit, errors, reset, watch } = useForm()
  const [mobilePlus, setMobilePlus] = useState("+974")
  const [mobile, setMobile] = useState("")
  const [paymentTypes, setPaymentTypes] = useState([])
  const [selectedMultiSelect, setselectedMultiSelect] = useState(multi)
  const [paymentTypesErrror, setPaymentTypesError] = useState(false)
  const [optionsPaymentTypes, setOpTypes] = useState([])
  const [valueStorage, setValueStorage] = useState([])

  //select all options
  // const selectAllOptions = {
  //   value: "<SELECT_ALL>",
  //   label: "all",
  // }
  //number of values
  // const valueRef = useRef(selectedMultiSelect.filtered)
  // valueRef.current = selectedMultiSelect.filtered
  //console.log(valueRef.current)
  // let optionsPaymentTypes = paymentTypes?.map((pt, i) => {
  //   return { value: pt, label: pt }
  // })

  useEffect(() => {
    //console.log(modal_xlarge)
  }, [modal_xlarge])
  useEffect(() => {
    //console.log(commissionSettingsPaymentTypes)
    if (
      commissionSettingsPaymentTypes !== undefined &&
      Array.isArray(commissionSettingsPaymentTypes)
    ) {
      setPaymentTypes(commissionSettingsPaymentTypes)
    }
  }, [commissionSettingsPaymentTypes])

  useEffect(() => {
    //console.log(paymentTypes)
    if (Array.isArray(paymentTypes)) {
      //console.log(optionsPaymentTypes)
      setOpTypes(
        paymentTypes?.map((pt, i) => {
          return { value: pt, label: pt }
        })
      )
      //setOpTypes(array => [selectAllOptions, ...array])
      //onFilteredChangeCustom(paymentTypes, "entry")
    }
  }, [paymentTypes])
  useEffect(() => {
    //console.log(optionsPaymentTypes)
    if (Array.isArray(optionsPaymentTypes)) {
      setValueStorage(optionsPaymentTypes)
    }
  }, [optionsPaymentTypes])

  useEffect(() => {
    //console.log(props.selectedCustomer)
    if (props.selectedCustomer) {
      // showToastSuccess(
      //   "Customer " + props.selectedCustomer.Name + " has been created."
      // )
      //setmodal_xlarge(false)
    }
  }, [props.selectedCustomer])
  useEffect(() => {
    // console.log(prDetails)
    if (
      prDetails.CustomerDetails.Mobile !== undefined &&
      prDetails.CustomerDetails.Mobile !== ""
    ) {
      let spilt = prDetails.CustomerDetails.Mobile.split("-")
      setMobilePlus(spilt[0])
      setMobile(spilt[1])
    }
    if (
      prDetails.CustomerDetails.Name !== undefined &&
      prDetails.CustomerDetails.Name !== ""
    ) {
      setmodal_xlarge(false)
    }
  }, [prDetails])

  useEffect(() => {
    // console.log(selectedMultiSelect)
    if (Array.isArray(selectedMultiSelect.filtered[0]?.value)) {
      handleChangePrSettingsDetails({
        PaymentType: selectedMultiSelect.filtered[0]?.value.toString(),
      })
    }

    if (selectedMultiSelect.filtered[0]?.value === "") {
      handleChangePrSettingsDetails({
        PaymentType: "",
      })
    }
  }, [selectedMultiSelect])

  useEffect(() => {
    //console.log(paymentTypesErrror)
  }, [paymentTypesErrror])
  useEffect(() => {
    //  console.log(valueStorage)
    if (Array.isArray(valueStorage)) {
      const array = new Array()
      valueStorage.map(value => {
        array.push(value.value)
      })
      onFilteredChangeCustom(array, "entry")
    }
  }, [valueStorage])
  ///////////////////////////////////////////////////////////////////////

  function onFilteredChangeCustom(value, accessor) {
    let filtered = selectedMultiSelect.filtered
    let insertNewFilter = 1

    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter["id"] === accessor) {
          if (value === "" || !value.length) filtered.splice(i, 1)
          else filter["value"] = value

          insertNewFilter = 0
        }
      })
    }

    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value })
    }

    setselectedMultiSelect({ filtered: filtered })
  }

  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function handleChange(changes) {
    // console.log(changes)
    handlePaymentRequestChange({ ...prDetails, ...changes })
  }
  function handleChangePRAmount(changes) {
    handleChange({ AmountDetails: { ...prDetails.AmountDetails, ...changes } })
  }

  function handleChangeItem(changes) {
    handlePurchasedItemChange({ ...itemDetails, ...changes })
  }

  function handleChangeCustomerDetails(changes) {
    handleChange({
      CustomerDetails: { ...prDetails.CustomerDetails, ...changes },
    })
  }

  function handleChangePrSettingsDetails(changes) {
    // console.log(changes)
    handleChange({
      PaymentRequestSettings: {
        ...prDetails.PaymentRequestSettings,
        ...changes,
      },
    })
  }

  function handleChangeSingleItem(id, item) {
    const newItems = [...itemDetails.Items]
    const index = newItems.findIndex(i => i.id === id)
    newItems[index] = item
    handleChangeItem({ Items: newItems })
  }

  function handleAddItem() {
    const newItem = {
      id: uuidv4(),
      Qty: 0,
      ItemName: "",
      Amount: 0,
      Refundable: false,
    }
    handleChangeItem({ Items: [...itemDetails.Items, newItem] })
  }

  function handleDeleteItem(id) {
    handleChangeItem({ Items: itemDetails.Items.filter(i => i.id !== id) })
  }

  const onSubmit = () => {
    if (
      prDetails?.PaymentRequestSettings?.AvailablePaymentOptions?.length > 0
    ) {
      setPaymentTypesError(false)
      handleCheckNoErrors()
    } else {
      setPaymentTypesError(true)
    }
  }

  function handleCheckNoErrors() {
    if (isEmpty(errors)) {
      toggleTabProgress(activeTabProgress + 1)
    }
  }

  const showPurchaseRow = () => {
    setShowPurchase(!showPurchase)
  }

  ///////////////////////////////////////////////////////////
  return (
    <>
      <Modal
        size="xl"
        isOpen={modal_xlarge}
        centered={true}
        toggle={() => {
          tog_xlarge()
        }}
        onHide={true}
        backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel"></h5>
          <button
            onClick={() => {
              setmodal_xlarge(false)
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="fas fa-window-close fa-2x" style={{ color: "red" }} />
          </button>
        </div>
        <ModalBody>
          <CustomerPopup onChange={value => setmodal_xlarge(value)} />
        </ModalBody>
      </Modal>
      <Form onSubmit={handleSubmit(onSubmit)} id="my-form">
        <p style={{ color: "#5c8FEC" }}>
          <small>
            <b>BILLING INFORMATION</b>
          </small>
        </p>
        <Row>
          <Col lg="2">
            <div className="mb-3">
              <Button
                onClick={() => setmodal_xlarge(true)}
                color="success"
                className="btn-block inner"
                style={{ width: "100%", height: "56px" }}
              >
                <i className="fas fa-plus fas-2x" /> Customer
              </Button>
            </div>
          </Col>
          <Col lg="4">
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control bg-color-disable color-text"
                value={prDetails.CustomerDetails.Name}
                onChange={e =>
                  handleChangeCustomerDetails({ Name: e.target.value })
                }
                id="name"
                name="Name"
                // placeholder="Enter Name"
                ref={register({ required: true })}
                readOnly
              />
              <label htmlFor="floatingnameInput" className="">
                {" "}
                Name
              </label>
              {errors.Name && errors.Name.type === "required" && (
                <>
                  <small className="color-danger">This is required</small>
                </>
              )}
            </div>
          </Col>

          <Col lg="3">
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control bg-color-disable color-text"
                id="email"
                name="Email"
                // placeholder="Enter Email"
                value={prDetails.CustomerDetails.Email}
                readOnly
                onChange={e =>
                  handleChangeCustomerDetails({ Email: e.target.value })
                }
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address",
                  },
                })}
              />
              <label htmlFor="floatingnameInput" className="">
                {" "}
                Email
              </label>
              {errors.Email && errors.Email.type === "required" && (
                <small className="color-danger">{errors.Email.message}</small>
              )}
              {errors.Email && errors.Email.type === "pattern" && (
                <small className="color-danger">{errors.Email.message}</small>
              )}
            </div>
          </Col>

          <Col lg="1">
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control bg-color-disable color-text"
                id="mobilePlus"
                name="mobilePlus"
                readOnly
                placeholder="+974"
                value={mobilePlus}
                onChange={e =>
                  handleChangeCustomerDetails({ Mobile: e.target.value })
                }
                ref={register({ required: true })}
              />
              {/* <select className="form-select form-control">
                                <option value="00974" defaultValue>+974</option>
                                <option value="00963" defaultValue>+963</option>
                            </select> */}
              <label htmlFor="floatingnameInput" className="">
                Area
              </label>
            </div>
          </Col>

          <Col lg="2">
            <div className="form-floating mb-3">
              <input
                type="number"
                className="form-control bg-color-disable color-text"
                id="mobile"
                name="mobile"
                readOnly
                // placeholder="Enter Mobile"
                value={mobile}
                onChange={e =>
                  handleChangeCustomerDetails({ Mobile: e.target.value })
                }
                ref={register({ required: true })}
              />
              <label htmlFor="floatingnameInput" className="">
                Mobile
              </label>
              {errors.mobile && errors.mobile.type === "required" && (
                <small className="color-danger">This is required</small>
              )}
            </div>
          </Col>
        </Row>

        <p style={{ color: "#5c8FEC" }}>
          <small>
            <b>PAYMENT DETAILS</b>
          </small>
        </p>
        <Row className="mb-4">
          <Col md={4}>
            {/* <div className="form-floating mb-3 ">
              {paymentTypes.length <= 1 ? (
                <Select
                  styles={customStyles}
                  name="multiSelect"
                  id="multiSelect"
                  value={valueStorage}
                  isMulti={true}
                  touchUi={false}
                  onChange={setValueStorage}
                  options={optionsPaymentTypes}
                  classNamePrefix="multiSelect"
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                />
              ) : (
                <SelectPaymentTypes
                  options={optionsPaymentTypes}
                  value={valueStorage}
                  onChange={setValueStorage}
                />
              )}
              {paymentTypesErrror === true ? (
                <small className="color-danger">This is required</small>
              ) : null}
              {paymentTypes.length === 0 ? (
                <small className="color-danger">
                  No active payment gateway.
                </small>
              ) : null}
            </div> */}
            <Label>
              Selected Payment Gateway Options:{" "}
              <small>
                {"("}read-only{")"}
              </small>
            </Label>
            <PaymentTypesCards
              paymentList={paymentList}
              value={valueStorage}
              onChange={setValueStorage}
              handleChangePrSettingsDetails={handleChangePrSettingsDetails}
              merchantLoading={merchantLoading}
            />
            {paymentTypesErrror === true ? (
              <small className="color-danger">This is required</small>
            ) : null}
          </Col>
          <Col md={8}>
            <Row>
              <Col md="3">
                {/* <InputGroupAddon className="mr-3"> */}
                <div className="form-floating mb-3" style={{ width: "100%" }}>
                  <select
                    className="form-select form-control bg-color-amount border-none  input-text-size-currency bold pl-2"
                    value={prDetails.AmountDetails.Currency}
                    name="Currency"
                    onChange={e =>
                      handleChangePRAmount({ Currency: e.target.value })
                    }
                    ref={register({ required: true })}
                  >
                    <option value="QAR">QAR</option>
                  </select>
                  <label htmlFor="floatingnameInput" className="">
                    Currency
                  </label>
                  {errors.Currency && errors.Currency.type === "required" && (
                    <small className="color-danger">This is required</small>
                  )}
                </div>

                {/* </InputGroupAddon> */}
              </Col>
              <Col md="6">
                <div className="form-floating mb-3 ">
                  <input
                    type="number"
                    className="form-control bg-color-amount input-text-size-amount bold border-none"
                    id="amount"
                    min="0"
                    step=".01"
                    // placeholder="Enter Amount"
                    value={prDetails?.AmountDetails?.InvoiceAmount}
                    onChange={e =>
                      handleChangePRAmount({
                        InvoiceAmount: parseFloat(e.target.value) || "",
                      })
                    }
                    name="Amount"
                    ref={register({
                      required: {
                        value: true,
                        message: "This is required",
                      },
                      min: {
                        value: 1,
                        message: "Not a valid amount",
                      },
                    })}
                  />
                  <label htmlFor="floatingnameInput" className="">
                    Amount
                  </label>
                  {errors.Amount && errors.Amount.type === "required" && (
                    <small className="color-danger">This is required</small>
                  )}
                  {errors.Amount && errors.Amount.type === "min" && (
                    <small className="color-danger">
                      {errors.Amount.message}
                    </small>
                  )}
                </div>
              </Col>

              {/* ------------------------Change the service fee here-------------------------------- */}
              <Col md="3">
                <div className="form-floating mb-3 ">
                  <input
                    type="number"
                    className="form-control  input-text-size-amount bold "
                    id="serviceFee"
                    min="0"
                    step=".01"
                    // placeholder="Enter Amount"
                    value={prDetails?.AmountDetails?.ServiceFee}
                    onChange={e =>
                      handleChangePRAmount({
                        ServiceFee: parseFloat(e.target.value) || "",
                      })
                    }
                    name="serviceFee"
                  />
                  <label htmlFor="floatingnameInput" className="">
                    Service Fee
                  </label>
                </div>
              </Col>
              {/* ------------------------Change the service fee here-------------------------------- */}
            </Row>
            <Row>
              <Col md="3">
                <div className="form-floating mb-3">
                  <select
                    value={prDetails.PaymentRequestSettings.Language}
                    className="form-select form-control"
                    onChange={e =>
                      handleChangePrSettingsDetails({
                        Language: e.target.value,
                      })
                    }
                    ref={register({ required: true })}
                    name="Language"
                  >
                    <option value="" hidden="hidden">
                      Choose here
                    </option>
                    <option value="En">English</option>
                    <option value="Ar">Arabic</option>
                  </select>
                  <label htmlFor="floatingSelectGrid" className="">
                    Language
                  </label>
                  {errors.Language && errors.Language.type === "required" && (
                    <small className="color-danger">This is required</small>
                  )}
                </div>
              </Col>
              <Col>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="transactionreference"
                    // placeholder="Enter Transaction reference"
                    value={prDetails?.TransactionReference}
                    onChange={e =>
                      handleChange({ TransactionReference: e.target.value })
                    }
                  />
                  <label htmlFor="floatingnameInput" className="pl-0 ml-0">
                    Transaction Reference
                  </label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    // placeholder="."
                    value={prDetails?.Remarks}
                    onChange={e => handleChange({ Remarks: e.target.value })}
                  />
                  <label htmlFor="floatingnameInput" className="">
                    Remarks
                  </label>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <div className="mb-3">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="floatingCheck"
              onChange={showPurchaseRow}
            />
            <label className="form-check-label" htmlFor="floatingCheck">
              Add Item Breakdown
            </label>
          </div>
        </div>

        {showPurchase === true && (
          <>
            <p style={{ color: "#5c8FEC" }}>
              <small>
                <b>PURCHASE DETAILS</b>
              </small>
            </p>

            <Row
              style={{
                color: "white",
                background: "#5c8FEC",
                padding: "3px",
                marginLeft: "3px",
                marginRight: "3px",
              }}
            >
              <Col lg="5">
                <small>Product or Service</small>
              </Col>
              <Col lg="2">
                <small>Quantity</small>
              </Col>
              <Col lg="3">
                <small>Amount</small>
              </Col>
              <Col lg="2"></Col>
            </Row>
            <br></br>

            {itemDetails.Items.map((item, index) => (
              <Items
                key={index}
                index={index}
                item={item}
                handleChangeSingleItem={handleChangeSingleItem}
                handleDeleteItem={handleDeleteItem}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                register={register}
                errors={errors}
              />
            ))}

            <Button
              onClick={() => handleAddItem()}
              color="success"
              className="mt-1"
            >
              Add Product or Service
            </Button>
          </>
        )}

        <ul className="pager wizard twitter-bs-wizard-pager-link">
          <li
            className={
              activeTabProgress === 1 ? "previous disabled" : "previous"
            }
          ></li>
          <li className={activeTabProgress === 3 ? "next" : "next"}>
            {activeTabProgress === 3 ? (
              <>{loadSubmitNowButton()}</>
            ) : (
              <Button type="submit" color="primary" id="my-form_submit_1">
                {" "}
                Next{" "}
              </Button>
            )}
          </li>
        </ul>
      </Form>
    </>
  )
})
const mapStateToProps = state => {
  return {
    selectedCustomer: state.Customer.customer,
  }
}

export default connect(mapStateToProps)(withRouter(FirstPaymentRequestDetails))
