import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Col, FormGroup, Input, Row, Button, Form } from "reactstrap"

import AuthUser from "components/AuthUser/AuthUser"
import { CommissionSettings } from "./CommissionSettings"
import { v4 as uuidv4 } from "uuid"
import { CustomConfig } from "./CustomConfig"
import ConvenienceFeeSettings from "./ConvenienceFeeSettings"
import { PaymentTypesMerchantEdit } from "../../components/PaymentTypesSelect/PaymentList"
import EnabledPaymentTypes from "./EnabledPaymentTypes"
export const PaymentGateways = props => {
  const {
    paymentGateway,
    handlePaymentGatewayChange,
    handlePaymentGatewayDelete,
    paymentGatewayList,
    handleSubmit,
    onSubmit,
    register,
    errors,
    index,
    enabledPaymentOptions,
  } = props

  const [selectedPg, setSelectedPg] = useState({})
  const [commissionBasis, setCommissionBasis] = useState([])
  const [convenienceBasis, setConvenienceBasis] = useState([])
  const user = useState(AuthUser())
  const [enabledPTOptions, setEnabledPTOptions] = useState()
  const [newPTOptions, setNewPTOptions] = useState()
  const [optionsSort, setOptionsSort] = useState()
  const inputPaymentGateway = "paymentGateway" + index
  // const inputTerminalId = "TerminalId" + index
  // const inputPassword = "Password" + index
  // const inputUserName = "UserName" + index
  // const inputTerminalPassword = "TerminalPassword" + index
  const inputMerchantId = "merchantId" + index
  const inputIsActive = "isActive" + index
  const inputCommissionBasedOn = "commissionBasedOn" + index
  const [click, setClick] = useState(false)
  const [pgList, setPgList] = useState([])
  useEffect(() => {
    // console.log(selectedPg)
  }, [selectedPg])
  useEffect(() => {
    // console.log(commissionBasis)
  }, [commissionBasis])
  useEffect(() => {
    // console.log(ConvenienceBasis)
  }, [convenienceBasis])

  useEffect(() => {
    if (paymentGatewayList) {
      setPgList(paymentGatewayList)
    }
  }, [paymentGatewayList])

  useEffect(() => {
    //console.log(paymentGateway)
  }, [paymentGateway])

  useEffect(() => {
    //console.log(enabledPTOptions)
  }, [enabledPTOptions])
  useEffect(() => {
    if (selectedPg && paymentGateway.commissionBasedOn === "payment type") {
      setCommissionBasis(selectedPg?.paymentType)
    }

    if (selectedPg && paymentGateway.commissionBasedOn === "card type") {
      setCommissionBasis(selectedPg?.cardType)
    }
  }, [paymentGateway.commissionBasedOn, selectedPg])
  useEffect(() => {
    if (selectedPg && paymentGateway.commissionBasedOn === "payment type") {
      setConvenienceBasis(selectedPg?.paymentType)
    }

    if (selectedPg && paymentGateway.commissionBasedOn === "card type") {
      setConvenienceBasis(selectedPg?.cardType)
    }
  }, [paymentGateway.commissionBasedOn, selectedPg])

  useEffect(() => {
    if (
      paymentGateway.paymentGateway !== undefined &&
      Array.isArray(paymentGatewayList)
    ) {
      const selectedpaymentgateWay = paymentGatewayList.filter(
        i => i.paymentGatewayId == paymentGateway.paymentGatewayId
      )[0]

      setSelectedPg(selectedpaymentgateWay)
    }
  }, [paymentGateway.paymentGateway, paymentGatewayList])

  useEffect(() => {
    if (Array.isArray(paymentGateway.customConfig)) {
      paymentGateway.customConfig.map((config, index) => {
        if (config.id === undefined) {
          config.id = uuidv4()
        }
      })
    }
  }, [paymentGateway.customConfig])
  useEffect(() => {
    if (Array.isArray(paymentGateway.commissionSettings)) {
      paymentGateway.commissionSettings.map((comm, index) => {
        if (comm.id === undefined) {
          comm.id = uuidv4()
        }
      })
    }
  }, [paymentGateway.commissionSettings])
  useEffect(() => {
    if (Array.isArray(paymentGateway?.convenienceFee)) {
      paymentGateway?.convenienceFee.map((comm, index) => {
        if (comm.id === undefined) {
          comm.id = uuidv4()
        }
      })
    }
  }, [paymentGateway?.convenienceFee])

  useEffect(() => {
    setNewPTOptions(enabledPaymentOptions)
  }, [enabledPaymentOptions])
  useEffect(() => {
    //console.log("new", newPTOptions)

    setEnabledPTOptions(PaymentTypesMerchantEdit(paymentGateway, newPTOptions))
  }, [newPTOptions])

  function handleChange(changes) {
    handlePaymentGatewayChange(paymentGateway.id, {
      ...paymentGateway,
      ...changes,
    })
  }
  function handleCommissionChange(id, commission) {
    const newCommissions = [...paymentGateway.commissionSettings]
    const index = newCommissions.findIndex(i => i.id === id)
    newCommissions[index] = commission
    handleChange({ commissionSettings: newCommissions })
  }
  function handleConvenienceChange(id, convenience) {
    // console.log(id, transaction)
    const newConvenience = [...paymentGateway?.convenienceFee]
    const index = newConvenience.findIndex(i => i.id === id)
    newConvenience[index] = convenience
    handleChange({ convenienceFee: newConvenience })
  }

  function handleCustomConfigChange(id, config) {
    // console.log(id, config)
    const newCustomConfigs = [...paymentGateway.customConfig]
    const index = newCustomConfigs.findIndex(i => i.id === id)
    newCustomConfigs[index] = config
    handleChange({ customConfig: newCustomConfigs })
  }

  function handleCommissionAdd() {
    const newCommission = {
      id: uuidv4(),
      basis: "",
      fixed: 0,
      percentage: 0,
    }
    handleChange({
      commissionSettings: [...paymentGateway.commissionSettings, newCommission],
    })
  }

  function handleConvenienceAdd() {
    const newConvenience = {
      id: uuidv4(),
      basis: "",
      fixed: 0,
      percentage: 0,
    }
    handleChange({
      convenienceFee: [...paymentGateway?.convenienceFee, newConvenience],
    })
  }

  function handleCustomConfigAdd() {
    const newCustomConfig = {
      id: uuidv4(),
      key: "",
      value: "",
    }
    handleChange({
      customConfig: [...paymentGateway.customConfig, newCustomConfig],
    })
  }

  function handleCommissionDelete(id) {
    handleChange({
      commissionSettings: paymentGateway.commissionSettings.filter(
        i => i.id !== id
      ),
    })
  }
  function handleConvenienceDelete(id) {
    handleChange({
      convenienceFee: paymentGateway?.convenienceFee.filter(i => i.id !== id),
    })
  }

  function handleCustomConfigDelete(id) {
    handleChange({
      customConfig: paymentGateway.customConfig.filter(i => i.id !== id),
    })
  }

  function handleSelectedPgId(e) {
    const index = e.target.selectedIndex
    const optionElement = e.target.childNodes[index]
    const optionElementId = optionElement.getAttribute("id")
    handleChange({
      paymentGateway: e.target.value,
      paymentGatewayId: optionElementId,
    })

    const selectedpaymentgateWay = paymentGatewayList.filter(
      i => i.paymentGatewayId == optionElementId
    )[0]
    setSelectedPg(selectedpaymentgateWay)
  }

  function handleSelectedCommissionBasedOn(value) {
    if (selectedPg) {
      if (value === "payment type") {
        setCommissionBasis(selectedPg?.paymentType)
      }

      if (value === "card type") {
        setCommissionBasis(selectedPg?.cardType)
      }
    }

    handleChange({ commissionBasedOn: value })
  }

  function parseBool(value) {
    var bool = value === "true" ? true : false
    return bool
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "#e5edff",
          padding: "10px",
          marginBottom: "30px",
        }}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col lg="3">
              {/* TODO: pg get from db*/}
              <FormGroup>
                <label htmlFor="subject">Payment Gateway </label>
                <select
                  className="custom-select"
                  value={paymentGateway.paymentGateway}
                  name={inputPaymentGateway}
                  onChange={e => handleSelectedPgId(e)}
                  ref={register({
                    required: {
                      value: true,
                      message: "This is required",
                    },
                  })}
                >
                  <option value="" selected="selected" hidden="hidden">
                    Choose here
                  </option>
                  {pgList &&
                    pgList?.map((pg, index) => (
                      <option
                        key={index}
                        id={pg.paymentGatewayId}
                        value={pg.paymentGateway}
                      >
                        {pg.paymentGateway}
                      </option>
                    ))}
                </select>

                <small className="color-danger">
                  {errors[inputPaymentGateway] &&
                    errors[inputPaymentGateway].message}
                </small>
              </FormGroup>
            </Col>
            <Col lg="1">
              <FormGroup>
                <label htmlFor="subject">Status</label>
                <select
                  ref={register({ required: true })}
                  className="custom-select"
                  value={paymentGateway.isActive}
                  name={inputIsActive}
                  onChange={e =>
                    handleChange({ isActive: parseBool(e.target.value) })
                  }
                >
                  <option value="" selected="selected" hidden="hidden">
                    Choose here
                  </option>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>
                <small className="color-danger">
                  {errors[inputIsActive] && errors[inputIsActive].message}
                </small>
              </FormGroup>
            </Col>

            {user[0]?.role === "SuperAdmin" ? (
              <Col lg="2">
                <FormGroup>
                  <label htmlFor="subject">Commission</label>
                  <select
                    ref={register({
                      required: {
                        value: true,
                        message: "This is required",
                      },
                    })}
                    className="custom-select"
                    value={paymentGateway.commissionBasedOn}
                    name={inputCommissionBasedOn}
                    onChange={e =>
                      handleSelectedCommissionBasedOn(e.target.value)
                    }
                  >
                    <option value="" selected="selected" hidden="hidden">
                      Choose here
                    </option>
                    <option value="payment type">
                      Payment Type (credit / debit)
                    </option>
                    <option value="card type">Card Type (visa / master)</option>
                  </select>
                  <small className="color-danger">
                    {errors[inputCommissionBasedOn] &&
                      errors[inputCommissionBasedOn].message}
                  </small>
                </FormGroup>
              </Col>
            ) : null}
            {user[0].role === "SuperAdmin" ? (
              <Col lg="1">
                <FormGroup>
                  <label style={{ color: "#e5edff" }} htmlFor="subject">
                    Delete{" "}
                  </label>

                  <Button
                    onClick={e => handlePaymentGatewayDelete(paymentGateway.id)}
                    color="danger"
                    className="btn-block inner"
                    style={{ width: "100%" }}
                  >
                    {" "}
                    Delete PG{" "}
                  </Button>
                </FormGroup>
              </Col>
            ) : null}
          </Row>
          {/* {enabledPTOptions?.length > 0 ? (
            <>
              <hr></hr>
              <b>Active Payment Types</b>
              <p>
                Select the Payment Types your customers would be able to pay
                with.
              </p>
              <Row>
                <Col lg="12">
                  <EnabledPaymentTypes
                    click={click}
                    setClick={setClick}
                    newPTOptions={optionsSort}
                    enabledPTOptions={enabledPTOptions}
                    handlePaymentOptionChanges={handlePaymentOptionChanges}
                  />
                </Col>
              </Row>
            </>
          ) : null} */}
          {user[0].role === "SuperAdmin" ? (
            <Row>
              <Col lg="8">
                <b>Add Custom Configurations</b>
                <p></p>
                {paymentGateway.customConfig &&
                  paymentGateway.customConfig.map((config, index) => (
                    <CustomConfig
                      index={index}
                      indexPG={props.index}
                      selectedPg={selectedPg}
                      key={index}
                      config={config}
                      handleCustomConfigChange={handleCustomConfigChange}
                      handleCustomConfigDelete={handleCustomConfigDelete}
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      register={register}
                      errors={errors}
                    />
                  ))}

                <div style={{ padding: "10px" }}>
                  {" "}
                  <Button
                    onClick={() => handleCustomConfigAdd()}
                    color="warning"
                    className="mt-1"
                  >
                    + Add Config
                  </Button>
                </div>
              </Col>
            </Row>
          ) : null}

          {paymentGateway?.convenienceFee ? (
            <>
              <hr></hr>
              <b>Add Convenience Fee Settings</b>

              <Row>
                <Col lg="8">
                  {paymentGateway.convenienceFee &&
                    paymentGateway.convenienceFee.map((convenience, index) => (
                      <ConvenienceFeeSettings
                        index={index}
                        indexPG={props.index}
                        selectedPg={selectedPg}
                        convenienceBasis={convenienceBasis}
                        key={index}
                        convenience={convenience}
                        handleConvenienceChange={handleConvenienceChange}
                        handleConvenienceDelete={handleConvenienceDelete}
                        handleSubmit={handleSubmit}
                        onSubmit={onSubmit}
                        register={register}
                        errors={errors}
                      />
                    ))}
                </Col>
              </Row>

              <div style={{ padding: "10px" }}>
                {" "}
                <Button
                  onClick={() => handleConvenienceAdd()}
                  color="warning"
                  className="mt-1"
                >
                  + Add Convenience Fee
                </Button>
              </div>
            </>
          ) : null}

          {user[0]?.role === "SuperAdmin" ? (
            <>
              <hr></hr>
              <b>Add Commission Settings</b>
              <p>For internal purposes only.</p>

              <Row>
                {paymentGateway.commissionSettings &&
                  paymentGateway.commissionSettings.map((commission, index) => (
                    <CommissionSettings
                      index={index}
                      indexPG={props.index}
                      selectedPg={selectedPg}
                      commissionBasis={commissionBasis}
                      key={index}
                      commission={commission}
                      handleCommissionChange={handleCommissionChange}
                      handleCommissionDelete={handleCommissionDelete}
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      register={register}
                      errors={errors}
                    />
                  ))}
              </Row>

              <div style={{ padding: "10px" }}>
                {" "}
                <Button
                  onClick={() => handleCommissionAdd()}
                  color="warning"
                  className="mt-1"
                >
                  + Add Payment Method & Commission
                </Button>
              </div>
            </>
          ) : null}
        </Form>
      </div>
    </>
  )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentGateways)
