import React, { useState, useEffect, useRef } from "react"
import AuthUser from "components/AuthUser/AuthUser"
import { Link } from "react-router-dom"
import {
  getMerchantDetail,
  getPaymentGatewayList,
  getAdminUserByMerchantId,
} from "store/actions"
import { connect, useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"
import LoadingSpinnerThreeDots from "helpers/commonhelpers/loadingSpinner"
export const MerchantCreateContext = React.createContext()
import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  TabPane,
  Button,
} from "reactstrap"
import classnames from "classnames"
import { v4 as uuidv4 } from "uuid"
import { isEmpty } from "lodash"
import SweetAlert from "react-bootstrap-sweetalert"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { FirstMerchantInfo } from "./Steps/FirstMerchantInfo"
import { SecondConfigurations } from "./Steps/SecondConfigurations"
import { ThirdPaymentGateway } from "./Steps/ThirdPaymentGateway"
import FourthUser from "./Steps/FourthUser"
import FifthSummary from "./Steps/FifthSummary"
import LoadingModal from "./LoadingModal.js"
import { updateUser, updateMerchant } from "store/actions"
import { returnState, returnStateMerchant } from "store/actions"
import errorCatching from "../../helpers/commonhelpers/errorCatching"

const initialUserState = {
  UserId: null,
  MerchantId: "",
  RegistrationDate: new Date().toISOString(),
  Title: "",
  FirstName: "",
  LastName: "",
  Mobile: "",
  UserName: "",
  Password: "",
  Email: "",
  Role: "Admin",
  IsActive: false,
  Department: "",
}
const initialState = {
  MerchantId: null,
  RegistrationDate: new Date().toISOString(),
  Type: "",
  MerchantName: "",
  BusinessRegNumber: "",
  TaxRegNumber: "",
  Address: "",
  City: "",
  Country: "",
  PoBox: "",
  PhoneNumber: "",
  Website: "",
  TermsConditionUrl: "",
  Logo: "",
  Banner: "",
  PaymentGatewayEnrollments: [
    {
      id: uuidv4(),
      PaymentGateway: "",
      // TerminalId: "",
      // TerminalPassword: "",
      // MerchantId: "",
      CommissionBasedOn: "",
      IsActive: false,
      PaymentGatewayId: "",
      CommissionSettings: [
        {
          id: uuidv4(),
          Basis: "",
          Fixed: 0,
          Percentage: 0,
        },
      ],
      CustomConfig: [
        {
          id: uuidv4(),
          Key: "",
          Value: "",
        },
      ],
      ConvenienceFee: [
        {
          id: uuidv4(),
          Basis: "",
          Fixed: 0,
          Percentage: 0,
        },
      ],
    },
  ],
  PaymentGatewaySettings: {
    LinkValidity: 24,
  },
  Contacts: [
    {
      id: uuidv4(),
      Position: "",
      Name: "",
      Email: "",
      Mobile: "",
    },
  ],
  NotificationEmails: [
    {
      id: uuidv4(),
      Email: "",
      Date: new Date().toISOString(),
    },
  ],
  Services: [
    {
      id: uuidv4(),
      Service: "",
    },
  ],
  OfficeIds: [
    {
      id: uuidv4(),
      OfficeId: "",
      Date: new Date().toISOString(),
    },
  ],
  IsActive: false,
}
function index(props) {
  const {
    onGetMerchantDetail,
    onGetAdminUserByMerchantId,
    merchantDetail,
    userAdminDetail,
    loading,
    loadingUser,
    loadingMerchant,
    paymentGatewayList,
    onGetPaymentGateways,
    onUpdateMerchant,
    onUpdateUser,
    merchantUpdated,
    error,
    updatedUser,

    // loadingUserList,
    match: { params },
  } = props

  const user = useState(AuthUser())
  const [activeTab, setactiveTab] = useState(1)
  const [activeTabProgress, setactiveTabProgress] = useState(1)
  const [progressValue, setprogressValue] = useState(20)
  const [merchantDetailsEdit, setMerchantDetailsEdit] = useState(initialState)
  const [userAdmin, setUserAdmin] = useState(initialUserState)
  const [returnedMerchantDetail, setReturnedMerchantDetail] = useState()
  const [modal, setModal] = useState(false)
  const [errorResponse, setErrorResponse] = useState(undefined)
  const [userCreatedResponse, setUserCreatedResponse] = useState("")
  const [sweetAlertMsg, setSweetAlertMsg] = useState(false)
  const [firstStepError, setFirstStepError] = useState()
  const [secondStepError, setSecondStepError] = useState()
  const [thirdStepError, setThirdStepError] = useState()
  const [fourthStepError, setFourthStepError] = useState()
  const [isMerchantLoaded, setIsMerchantLoaded] = useState(undefined)
  const [isUserLoaded, setIsUserLoaded] = useState(undefined)
  const firstStepRef = useRef()
  const [dispatched, setDispatched] = useState(undefined)
  const dispatch = useDispatch()
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [error_dlg, seterror_dlg] = useState(false)

  useEffect(() => {
    if (user[0]?.role !== undefined && user[0]?.role === "User") {
      window.location = "/dashboard"
    }
  }, [user])
  useEffect(() => {
    if (params && params.merchantId) {
      //console.log(params.merchantId)
      onGetMerchantDetail(params.merchantId)
      onGetAdminUserByMerchantId(params.merchantId)
      onGetPaymentGateways()
    }
  }, [params, onGetMerchantDetail])

  useEffect(() => {
    //console.log(merchantDetail)
    if (
      merchantDetail &&
      merchantDetail.merchantName !== undefined &&
      params.merchantId === merchantDetail.merchantId
    ) {
      // console.log(merchantDetail)
      setMerchantDetailsEdit(merchantDetail)
    }
  }, [merchantDetail])

  useEffect(() => {
    if (userAdminDetail && userAdminDetail.userId !== undefined) {
      setUserAdmin(userAdminDetail)
    }
  }, [userAdminDetail])
  useEffect(() => {
    //console.log(merchantDetailsEdit)
  }, [merchantDetailsEdit])
  useEffect(() => {
    // console.log(userAdmin)
  }, [userAdmin])
  useEffect(() => {
    if (sweetAlertMsg) {
      callInfoSweetAlert()
    }
  }, [sweetAlertMsg])

  useEffect(() => {
    // console.log(merchantUpdated)
    if (merchantUpdated && merchantUpdated === true) {
      //showToastSuccess("Merchant successfully updated!")
      // resetState()
      //sleep(20000)
      setsuccess_dlg(true)
    }
    if (merchantUpdated && merchantUpdated === false) {
      // showToastError("Merchant not updated!")
      seterror_dlg(true)
      // resetState()
    }
  }, [merchantUpdated])

  useEffect(() => {
    if (updatedUser !== undefined && user[0]?.role === "SuperAdmin") {
      if (updatedUser === true) {
        showToastSuccess("Merchant admin successfully updated!")
      }
      if (updatedUser === false) {
        showToastError("Merchant admin not updated!")
      }
    }
  }, [updatedUser])
  useEffect(() => {
    if (error !== undefined && !isEmpty(error)) setErrorResponse(error)
  }, [error])

  //----------------------------------------------------------
  function sleep(ms) {
    return new Promise(resolve => {
      setTimeout(resolve, ms)
    })
  }
  const loadSubmitNowButton = () => {
    if (loadingMerchant === true) {
      return (
        <>
          <Button color="primary" style={{ cursor: "wait" }} onClick={null}>
            Processing ...
          </Button>
        </>
      )
    }

    if (loadingUser === true) {
      return (
        <>
          <Button color="primary" style={{ cursor: "wait" }} onClick={null}>
            Processing ...
          </Button>
        </>
      )
    }

    return (
      <>
        <Button type="submit" color="primary" onClick={() => handleSubmit()}>
          Submit Now
        </Button>
      </>
    )
  }
  function toggleTabProgress(tab) {
    if (activeTabProgress !== tab) {
      if (tab >= 1 && tab <= 5) {
        // if (isEmpty(firstStepError) &&
        //   isEmpty(secondStepError) &&
        //   isEmpty(thirdStepError) &&
        //   isEmpty(fourthStepError)) {

        setactiveTabProgress(tab)

        if (tab === 1) {
          // triggerSubmitButton("my-form_submit_1")
          setprogressValue(20)
        }
        if (tab === 2) {
          // triggerSubmitButton("my-form_submit_1")
          setprogressValue(40)
        }
        if (tab === 3) {
          // triggerSubmitButton("my-form_submit_2")
          setprogressValue(60)
        }
        if (tab === 4) {
          //  triggerSubmitButton("my-form_submit_3")
          setprogressValue(80)
        }
        if (tab === 5) {
          setprogressValue(100)
        }
        // }
      }
    }
  }
  function resetState() {
    toggleTabProgress(1)
    //window.location.reload()
  }
  const toggleModal = () => {
    setModal(!modal)
  }
  const callInfoSweetAlert = () => {
    return (
      <>
        <SweetAlert
          title="Need Assitance?"
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            resetState()
          }}
        >
          Please contact Amadeus Support Team. <br></br>
        </SweetAlert>
      </>
    )
  }
  const handleSubmit = async () => {
    if (!isEmpty(merchantDetailsEdit?.merchantName)) {
      toggleModal()

      onUpdateMerchant(merchantDetailsEdit)
      setDispatched(true)
      onUpdateUser(userAdmin)
      // dispatch(setMerchant(merchantDetails))
    }
  }

  function handleMerchantChange(changes) {
    // console.log(changes)
    setMerchantDetailsEdit(changes)
  }
  function handleUserAdminChange(changes) {
    setUserAdmin(changes)
  }

  const settingFirstStepError = errors => {
    setFirstStepError(errors)
  }
  const settingSecondStepError = errors => {
    setSecondStepError(errors)
  }
  const settingThirdStepError = errors => {
    setThirdStepError(errors)
  }
  const settingFourthStepError = errors => {
    setFourthStepError(errors)
  }
  const MerchantCreateContextValue = {
    settingFirstStepError,
    settingSecondStepError,
    settingThirdStepError,
    settingFourthStepError,
  }
  const showToastSuccess = message => {
    toastr.options = {
      positionClass: "toast-top-right",
      timeOut: 1000,
      extendedTimeOut: 1000,
      closeButton: true,
      debug: false,
      progressBar: true,
      preventDuplicates: true,
      newestOnTop: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 300,
    }

    toastr.success(message)
  }

  const showToastError = message => {
    toastr.options = {
      positionClass: "toast-top-right",
      timeOut: 1000,
      extendedTimeOut: 1000,
      closeButton: true,
      debug: false,
      progressBar: true,
      preventDuplicates: true,
      newestOnTop: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 300,
    }

    toastr.error(message)
  }

  //----------------------------------------------------
  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Merchant" breadcrumbItem="Update Merchant" />
          {errorCatching(errorResponse, setErrorResponse)}
          {success_dlg ? (
            <SweetAlert
              timeout={1000}
              success
              showConfirm={false}
              title={"Merchant Updated"}
              onConfirm={() => {
                setsuccess_dlg(false)
                window.location.reload()
                switch (user[0]?.role) {
                  case "SuperAdmin":
                    window.location.href =
                      "/merchant/overview/" + merchantDetailsEdit.merchantId
                    break
                  default:
                    window.location.href = "/merchant/overview/" + 0
                    break
                }
              }}
            >
              {"Successfully Updated."}
            </SweetAlert>
          ) : null}
          {error_dlg ? (
            <SweetAlert
              timeout={1000}
              error
              showConfirm={false}
              title={"Merchant not Updated"}
              onConfirm={() => {
                seterror_dlg(false)
              }}
            >
              {"Not Updated."}
            </SweetAlert>
          ) : null}
          {/* loading dispalys when either of the merchant and user loading is true */}
          {(loadingMerchant === true || loadingUser === true) && (
            <>
              <LoadingModal isOpen={modal} toggle={toggleModal} />
            </>
          )}
          {loading ? (
            <Row className="spinner-custom mx-auto my-auto pb-3">
              <LoadingSpinnerThreeDots />
            </Row>
          ) : (
            <Row>
              <Col lg="12" className=" my-2 ">
                {" "}
                {user[0]?.role === "Admin" ? (
                  <Col lg="12" className=" my-2">
                    {" "}
                    <Link
                      to={"/merchant/overview/" + 0}
                      className="mr-2 mb-1 float-right"
                    >
                      <i
                        className="mdi mdi-keyboard-backspace  font-size-18 mr-1 "
                        id="edittooltip"
                      />
                      Merchant Overview
                    </Link>
                  </Col>
                ) : null}
                {user[0]?.role === "SuperAdmin" ? (
                  <Col lg="12" className=" my-2">
                    {" "}
                    <Link
                      to={
                        "/merchant/overview/" + merchantDetailsEdit.merchantId
                      }
                      className="mr-2 mb-1 float-right"
                    >
                      <i
                        className="mdi mdi-keyboard-backspace  font-size-18 mr-1 "
                        id="edittooltip"
                      />
                      Merchant Overview!
                    </Link>
                  </Col>
                ) : null}
              </Col>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div id="progrss-wizard" className="twitter-bs-wizard">
                      <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                        <NavItem style={{ color: "#b3b3b3" }}>
                          <NavLink
                            style={{ cursor: "default", color: "#b3b3b3" }}
                            className={classnames({
                              active: activeTabProgress === 1,
                            })}
                          >
                            <span className="step-number mr-2">01</span>
                            Merchant Details
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "default", color: "#b3b3b3" }}
                            className={classnames({
                              active: activeTabProgress === 2,
                            })}
                          >
                            <span className="step-number mr-2">02</span>
                            <span>Configurations</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "default", color: "#b3b3b3" }}
                            className={classnames({
                              active: activeTabProgress === 3,
                            })}
                          >
                            <span className="step-number mr-2">03</span>
                            Payment Gateway
                          </NavLink>
                        </NavItem>
                        {user[0]?.role === "Admin" ? (
                          <>
                            {" "}
                            {/* <NavItem>
                          <NavLink
                            style={{ cursor: "default", color: "#b3b3b3" }}
                            className={classnames({
                              active: activeTabProgress === 4,
                            })}
                          >
                            <span className="step-number mr-2">04</span>
                            User
                          </NavLink>
                        </NavItem> */}
                            <NavItem>
                              <NavLink
                                style={{ cursor: "default", color: "#b3b3b3" }}
                                className={classnames({
                                  active: activeTabProgress === 4,
                                })}
                                onClick={() => {
                                  toggleTabProgress(5)
                                }}
                              >
                                <span className="step-number mr-2">04</span>
                                Confirm Details
                              </NavLink>
                            </NavItem>
                          </>
                        ) : (
                          <>
                            {" "}
                            <NavItem>
                              <NavLink
                                style={{ cursor: "default", color: "#b3b3b3" }}
                                className={classnames({
                                  active: activeTabProgress === 4,
                                })}
                              >
                                <span className="step-number mr-2">04</span>
                                User
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "default", color: "#b3b3b3" }}
                                className={classnames({
                                  active: activeTabProgress === 5,
                                })}
                                onClick={() => {
                                  toggleTabProgress(5)
                                }}
                              >
                                <span className="step-number mr-2">04</span>
                                Confirm Details
                              </NavLink>
                            </NavItem>
                          </>
                        )}
                      </ul>

                      <div id="bar" className="mt-4">
                        <Progress
                          color="success"
                          striped
                          animated
                          value={progressValue}
                        />
                        <div className="progress-bar bg-success progress-bar-striped progress-bar-animated" />
                      </div>
                      <MerchantCreateContext.Provider
                        value={MerchantCreateContextValue}
                      >
                        <TabContent
                          activeTab={activeTabProgress}
                          className="twitter-bs-wizard-tab-content"
                        >
                          <TabPane tabId={1}>
                            <FirstMerchantInfo
                              ref={firstStepRef}
                              merchantDetails={merchantDetailsEdit}
                              handleMerchantChange={handleMerchantChange}
                              toggleTabProgress={toggleTabProgress}
                              loadSubmitNowButton={loadSubmitNowButton}
                              activeTabProgress={activeTabProgress}
                            />
                          </TabPane>
                          <TabPane tabId={2}>
                            <SecondConfigurations
                              merchantDetails={merchantDetailsEdit}
                              handleMerchantChange={handleMerchantChange}
                              toggleTabProgress={toggleTabProgress}
                              loadSubmitNowButton={loadSubmitNowButton}
                              activeTabProgress={activeTabProgress}
                            />
                          </TabPane>
                          <TabPane tabId={3}>
                            <ThirdPaymentGateway
                              merchantDetails={merchantDetailsEdit}
                              handleMerchantChange={handleMerchantChange}
                              PaymentGatewayList={paymentGatewayList}
                              toggleTabProgress={toggleTabProgress}
                              loadSubmitNowButton={loadSubmitNowButton}
                              activeTabProgress={activeTabProgress}
                            />
                          </TabPane>
                          {user[0]?.role === "Admin" ? (
                            <>
                              {" "}
                              {/* <TabPane tabId={4}>
                            <FourthUser
                              userAdmin={userAdmin}
                              handleUserAdminChange={handleUserAdminChange}
                              toggleTabProgress={toggleTabProgress}
                              loadSubmitNowButton={loadSubmitNowButton}
                              activeTabProgress={activeTabProgress}
                            />
                          </TabPane> */}
                              <TabPane tabId={4}>
                                <FifthSummary
                                  handleSubmit={handleSubmit}
                                  merchantDetails={merchantDetailsEdit}
                                  userAdmin={userAdmin}
                                  loadingMerchant={loadingMerchant}
                                  loadingUser={loadingUser}
                                  toggleTabProgress={toggleTabProgress}
                                  loadSubmitNowButton={loadSubmitNowButton}
                                  activeTabProgress={activeTabProgress}
                                />
                              </TabPane>
                            </>
                          ) : (
                            <>
                              <TabPane tabId={4}>
                                <FourthUser
                                  userAdmin={userAdmin}
                                  handleUserAdminChange={handleUserAdminChange}
                                  toggleTabProgress={toggleTabProgress}
                                  loadSubmitNowButton={loadSubmitNowButton}
                                  activeTabProgress={activeTabProgress}
                                />
                              </TabPane>

                              <TabPane tabId={5}>
                                <FifthSummary
                                  handleSubmit={handleSubmit}
                                  merchantDetails={merchantDetailsEdit}
                                  userAdmin={userAdmin}
                                  loadingMerchant={loadingMerchant}
                                  loadingUser={loadingUser}
                                  toggleTabProgress={toggleTabProgress}
                                  loadSubmitNowButton={loadSubmitNowButton}
                                  activeTabProgress={activeTabProgress}
                                />
                              </TabPane>
                            </>
                          )}
                        </TabContent>
                      </MerchantCreateContext.Provider>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </>
  )
}
const mapStateToProps = state => ({
  merchantDetail: state.Merchants.merchantDetail,
  merchantUpdated: state.Merchants.merchantUpdated,
  loading: state.Merchants.loading,
  paymentGatewayList: state.PaymentGateway.paymentGateways,
  userAdminDetail: state.Users.userDetail,
  updatedUser: state.Users.update,
  error: state.Merchants.error,
  // loadingUserList: state.Users.loading,
})

const mapDispatchToProps = dispatch => ({
  onGetMerchantDetail: id => dispatch(getMerchantDetail(id)),
  onGetPaymentGateways: () => dispatch(getPaymentGatewayList()),
  onGetAdminUserByMerchantId: id => dispatch(getAdminUserByMerchantId(id)),
  onUpdateUser: data => dispatch(updateUser(data)),
  onUpdateMerchant: data => dispatch(updateMerchant(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(index)
