import React from "react"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Button,
  UncontrolledDropdown,
} from "reactstrap"
import {
  paymentRequestStatus,
  paymentRequestLinkStatus,
} from "../../helpers/paymentrequest/paymentrequest_helper"
import dateFormat from "../../helpers/commonhelpers/dateFormat"
const link = "/paymentrequest/overview/"

const PaymentRequestColumns = (
  handleOpenOverviewPrId,
  handleCopyLink,
  handleResendPaymentRequest,
  user,
  isDashboard,
  handleArchivePR
) => {
  const isHidden = () => {
    if (user.role !== "SuperAdmin") return true
    else return false
  }

  return [
    {
      dataField: "paymentRequestId",
      text: "Payment Request Id",
      searchable: true,
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        // return <a href={cellContent} target="_blank">
        //   {cellContent}
        // </a>
        if (isDashboard) {
          return (
            <small>
              {" "}
              <Button
                color="primary"
                outline
                className="waves-effect btn-sm"
                style={{ borderColor: "#ffffff00" }}
                onClick={() => handleOpenOverviewPrId(cellContent)}
              >
                {cellContent}
              </Button>
            </small>
          )
        } else {
          return (
            <Button
              color="primary"
              outline
              className="waves-effect"
              style={{ borderColor: "#ffffff00" }}
              onClick={() => handleOpenOverviewPrId(cellContent)}
            >
              {cellContent}
            </Button>
          )
        }

        // <Button className="btn" style={{backgroundColor:"#ffffff00", color:"black", borderColor:"#ffffff00"}}
        // onClick={() => handleOpenOverview({rowIndex})}>{cellContent}</Button>
      },
    },

    {
      dataField: "merchantDetails.merchantName",
      text: "Merchant",
      searchable: true,
      sort: true,
      hidden: isHidden(),
      formatter: (cellContent, row, rowIndex) => {
        if (isDashboard) {
          return (
            <small>
              <b>{cellContent}</b>
            </small>
          )
        } else {
          return <b>{cellContent}</b>
        }
      },
    },

    {
      dataField: "paymentRequestDate",
      text: "Payment Request Date",
      searchable: false,
      sort: true,
      formatter: (cellContent, row) => {
        if (isDashboard) {
          return <small>{dateFormat(cellContent)}</small>
        } else {
          return <span>{dateFormat(cellContent)}</span>
        }
      },
    },

    {
      text: "Customer Name",
      dataField: "customerDetails.name",
      sort: true,
      searchable: true,
      hidden: true,
    },
    {
      text: "Billing Name",
      dataField: "customerDetails",
      sort: true,
      formatter: (cellContent, row) => {
        if (isDashboard) {
          return (
            <small>
              <b className="mb-1">{cellContent.name}</b>
              <p>
                <small className="mb-0">{cellContent.email}</small> <br />
                <small className="mb-0">{cellContent.mobile}</small>
              </p>
            </small>
          )
        } else {
          return (
            <>
              <b className="mb-1">{cellContent.name}</b>
              <p>
                <small className="mb-0">{cellContent.email}</small> <br />
                <small className="mb-0">{cellContent.mobile}</small>
              </p>
            </>
          )
        }
      },
    },
    {
      text: "Customer Mobile",
      dataField: "customerDetails.mobile",
      sort: true,
      searchable: true,
      hidden: true,
    },
    {
      text: "Users Username",
      dataField: "userDetails.email",
      sort: true,
      searchable: true,
      hidden: true,
    },

    {
      dataField: "userDetails",
      text: "Request Sent By",
      sort: true,
      formatter: (cellContent, row) => {
        if (isDashboard) {
          if (cellContent.userName?.includes("@")) {
            var splittedName = cellContent.userName?.split("@")[0]

            return (
              <small>
                <b className="mb-1">{splittedName}</b>
                <p>
                  <small className="mb-0">{cellContent.email}</small>{" "}
                </p>
              </small>
            )
          } else {
            return (
              <small>
                <b className="mb-1">{cellContent.userName}</b>
                <p>
                  <small className="mb-0">{cellContent.email}</small>{" "}
                </p>
              </small>
            )
          }
        } else {
          if (cellContent.userName?.includes("@")) {
            var splittedName = cellContent.userName?.split("@")[0]

            return (
              <>
                <b className="mb-1">{splittedName}</b>
                <p>
                  <small className="mb-0">{cellContent.email}</small>{" "}
                </p>
              </>
            )
          } else {
            return (
              <>
                <b className="mb-1">{cellContent.userName}</b>
                <p>
                  <small className="mb-0">{cellContent.email}</small>{" "}
                </p>
              </>
            )
          }
        }
      },
    },
    {
      text: "Amount",
      dataField: "amountDetails.totalAmount",
      sort: true,
      searchable: true,
      formatter: (cellContent, row) => {
        if (row.paymentStatus !== "Unpaid") {
          if (isDashboard) {
            return (
              <small>
                {cellContent.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}
              </small>
            )
          } else {
            return (
              <>
                {" "}
                {cellContent.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}{" "}
              </>
            )
          }
        } else {
          if (isDashboard) {
            return (
              <small>
                {(
                  row.amountDetails.invoiceAmount + row.amountDetails.serviceFee
                ).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}
              </small>
            )
          } else {
            return (
              <>
                {" "}
                {(
                  row.amountDetails.invoiceAmount + row.amountDetails.serviceFee
                ).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}{" "}
              </>
            )
          }
        }
      },
    },
    {
      dataField: "amountDetails.currency",
      text: "Currency",
      sort: true,
      searchable: true,
      formatter: (cellContent, row) => {
        if (isDashboard) {
          return <small>{cellContent}</small>
        } else {
          return <> {cellContent} </>
        }
      },
    },

    {
      dataField: "paymentStatus",
      text: "Payment Status",
      sort: true,
      formatter: (cellContent, row) => {
        if (isDashboard) {
          return <small> {paymentRequestStatus(cellContent)}</small>
        } else {
          return paymentRequestStatus(cellContent)
        }
      },
    },

    {
      dataField: "commission",
      text: "1A Commission",
      sort: true,
      hidden: isHidden(),
      style: function callback(cell) {
        return { backgroundColor: "#EEFBFF" }
      },
      formatter: (cellContent, row) => {
        if (isDashboard) {
          if (cellContent !== null && cellContent !== undefined) {
            if (cellContent.type !== "") {
              return (
                <small>
                  <b className="mb-1">{cellContent?.totalCommission} QAR </b>
                  <p>
                    <small className="mb-0">
                      {cellContent?.type} - {cellContent?.value}
                    </small>{" "}
                  </p>
                </small>
              )
            } else {
              return (
                <small>
                  <b className="mb-1"></b>
                  <p>
                    <small className="mb-0">not calculated</small>{" "}
                  </p>
                </small>
              )
            }
          } else {
            return (
              <small>
                <span>--</span>
              </small>
            )
          }
        } else {
          if (cellContent !== null && cellContent !== undefined) {
            if (cellContent.type !== "") {
              return (
                <>
                  <b className="mb-1">{cellContent?.totalCommission} QAR </b>
                  <p>
                    <small className="mb-0">
                      {cellContent?.type} - {cellContent?.value}
                    </small>{" "}
                  </p>
                </>
              )
            } else {
              return (
                <>
                  <b className="mb-1"></b>
                  <p>
                    <small className="mb-0">not calculated</small>{" "}
                  </p>
                </>
              )
            }
          } else {
            return (
              <>
                <span>--</span>
              </>
            )
          }
        }
        // if (cellContent !== null && cellContent !== undefined) {

        //   if (cellContent.type !== "") {
        //     return (
        //       <>
        //         <b className="mb-1">{cellContent?.totalCommission} QAR </b>
        //         <p><small className="mb-0">{cellContent?.type} - {cellContent?.value}</small> </p>

        //       </>
        //     )
        //   }
        //   else {
        //     return (
        //       <>
        //         <b className="mb-1"></b>
        //         <p><small className="mb-0">not calculated</small> </p>
        //       </>
        //     )

        //   }

        // }
        // else {

        //   return (
        //     <>
        //       <span>--</span>
        //     </>
        //   )
        // }
      },
    },

    {
      dataField: "paymentRequestSettings.linkValidUpto",
      text: "Link Expiration",
      sort: true,
      searchable: false,
      formatter: (cellContent, row) => {
        if (isDashboard) {
          return <small>{dateFormat(cellContent)}</small>
        } else {
          return <span>{dateFormat(cellContent)}</span>
        }
      },
    },

    {
      dataField: "generatedLinkShort",
      text: "Link",
      hidden: true,
      searchable: false,
      formatter: (cellcontent, row) => {
        // setLinkShort(cellContent)
      },
    },

    {
      dataField: "generatedLinkStatus",
      text: "Link Status",
      sort: true,
      searchable: true,
      //  headerSortingStyle,
      formatter: (cellContent, row) => {
        return paymentRequestLinkStatus(cellContent, cellContent, false)
      },
      // filter: selectFilter({
      //   options: selectOptions
      // })
    },

    {
      dataField: "itemSource",
      text: "Platform",
      searchable: true,
      sort: true,
      formatter: (cellContent, row) => {
        if (isDashboard) {
          return <small> {cellContent}</small>
        } else {
          return <>{cellContent}</>
        }
      },
      // filter: selectFilter({
      //   options: selectOptions
      // })
    },

    {
      dataField: "_id",
      // isDummyField: true,
      searchable: false,
      text: "Action",
      formatter: (cellContent, row, rowIndex) => (
        <UncontrolledDropdown>
          <DropdownToggle href="#" className="card-drop" tag="i">
            <i className="mdi mdi-dots-horizontal font-size-18" />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem href={link + cellContent} target="_blank">
              <i className="fas fa-eye text-success mr-1" />
              <span>View More Info</span>
            </DropdownItem>
            <DropdownItem onClick={() => handleCopyLink(cellContent)}>
              <i className="fas fa-copy text-success mr-1" />
              <span>Copy Link</span>
            </DropdownItem>
            <DropdownItem
              onClick={() => handleResendPaymentRequest(cellContent)}
            >
              <i className="fas fa-paper-plane text-success mr-1" />
              Resend to Customer
            </DropdownItem>
            {user.role === "SuperAdmin" ? (
              <DropdownItem
                onClick={() => handleArchivePR(row.paymentRequestId)}
              >
                <i className="fas fa-sync text-success mr-1" />
                Archive Payment Request
              </DropdownItem>
            ) : null}
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ]
}

export default PaymentRequestColumns
