import { dateFormatString } from "helpers/commonhelpers/dateFormat"
import React, { useEffect, useState } from "react"
import {
  Modal,
  ModalBody,
  ModalHeader,
  Card,
  CardBody,
  Button,
  Row,
  Col,
  Label,
  Input,
  Form,
} from "reactstrap"

import Items from "./PaymentRequestItems"

import { useForm } from "react-hook-form"
import { connect } from "react-redux"
import { setAPSRefund } from "store/actions"
function RefundModalAPS(props) {
  const {
    refundDetails,
    isOpen,
    toggle,
    paymentrequestDetails,
    onSetAPSRefund,
    paymentRequestDetail,
  } = props

  const [refundDesc, setRefundDescp] = useState("")
  const [refundAmount, setRefundAmount] = useState()
  const { register, handleSubmit, errors } = useForm()
  const [amountCheck, setAmountCheck] = useState(true)
  useEffect(() => {
    //console.log(paymentrequestDetails)
  }, [paymentrequestDetails])
  useEffect(() => {}, [refundDesc])
  function handleConfirm() {
    let request = {
      transId: refundDetails.transId,
      amount: {
        currency: refundDetails.amountDetails.currency,
        value: refundAmount.toString(),
      },
      description: refundDesc,
    }

    if (refundDesc !== "" && amountCheck) {
      toggle()
      onSetAPSRefund(request)
    }
  }
  function handleValidAmount(amount, refund) {
    if (refund <= amount) {
      return true
    } else {
      return false
    }
  }
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>
        <Label>
          Refund Payment: <b>{paymentrequestDetails.paymentRequestId}</b>
        </Label>
      </ModalHeader>
      <ModalBody>
        <Card>
          <CardBody>
            <Form onSubmit={handleSubmit(handleConfirm)}>
              <Row className="">
                <Col>
                  Paid date:{" "}
                  <b>
                    {" "}
                    {dateFormatString(
                      paymentrequestDetails?.paidDetails?.paidDate
                    )}
                  </b>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  Payment Gateway: <b> Amazon Payment Services {"(PAYFORT)"}</b>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <h5 className="color-primary">Customer Details</h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      Name: {paymentrequestDetails.customerDetails.name}
                    </Col>
                  </Row>
                  <Row className="my-1">
                    <Col>
                      Email: {paymentrequestDetails.customerDetails.email}
                    </Col>
                  </Row>
                  <Row className="">
                    <Col>
                      Mobile: {paymentrequestDetails.customerDetails.mobile}
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <h5 className="color-primary">Amount Details</h5>
                    </Col>
                  </Row>
                  <Row className="">
                    <Col>
                      Total Amount:{" "}
                      <b className="color-danger">
                        {paymentrequestDetails.amountDetails.totalAmount.toLocaleString(
                          undefined,
                          {
                            maximumFractionDigits: 2,
                          }
                        )}{" "}
                        {paymentrequestDetails.amountDetails.currency}
                      </b>
                    </Col>
                  </Row>
                  <Row className="my-1">
                    <Col>
                      Payment method:{" "}
                      {paymentrequestDetails.amountDetails.paymentType}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <Items
                    source={
                      paymentRequestDetail?.paymentRequestDetails?.itemSource
                    }
                    purchasedItems={paymentRequestDetail?.purchasedItems}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Label>
                    <b className="color-danger">*</b>Refund Amount:{" "}
                  </Label>{" "}
                  <input
                    type="number"
                    id="refundAmount"
                    name="refundAmount"
                    className="form-control "
                    value={refundAmount}
                    ref={register({ required: true })}
                    onChange={e => {
                      setRefundAmount(e.target.value)
                      setAmountCheck(
                        handleValidAmount(
                          refundDetails.amountDetails.totalAmount,
                          parseFloat(e.target.value)
                        )
                      )
                    }}
                  />
                  {errors.refundAmount &&
                    errors.refundAmount.type === "required" && (
                      <p className="color-danger">This is required</p>
                    )}
                  {amountCheck ? null : (
                    <p className="color-danger">Invalid amount.</p>
                  )}
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <Label>
                    <b className="color-danger">*</b>Refund Description:{" "}
                  </Label>
                  <input
                    type="text"
                    id="refundDesc"
                    name="refundDesc"
                    className="form-control  form-control-lg input-text-size"
                    value={refundDesc}
                    ref={register({ required: true })}
                    onChange={e => {
                      setRefundDescp(e.target.value)
                    }}
                  />
                  {errors.refundDesc &&
                    errors.refundDesc.type === "required" && (
                      <p className="color-danger">This is required</p>
                    )}
                </Col>
              </Row>
              <hr />
              <Row className="mt-3">
                <Col className="item-center">
                  <Button color="success" className="" type="sumbit">
                    Confirm
                  </Button>
                  <Button
                    color="danger"
                    className="mx-2"
                    onClick={() => {
                      toggle()
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>
  )
}
const mapStateToProps = pr => ({
  paymentRequestDetail: pr.paymentRequests.paymentRequestDetail,
})

const mapDispatchToProps = dispatch => ({
  onSetAPSRefund: data => dispatch(setAPSRefund(data)),
})
export default connect(mapStateToProps, mapDispatchToProps)(RefundModalAPS)
