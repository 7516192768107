import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"

const PaymentDetails = props => {
  const { pr, merchantDetail } = props
  const [convenienceFee, setCV] = useState(0)
  const [showFees, setShowFees] = useState(true)
  useEffect(() => {
    // console.log(pr?.amountDetails)
    if (pr?.amountDetails) {
      setCV(
        parseFloat(pr?.amountDetails.totalAmount) -
          pr?.amountDetails.invoiceAmount
      )
    }
  }, [pr])

  useEffect(() => {
    if (merchantDetail) {
      setShowFees(merchantDetail?.paymentGatewaySettings?.feeHidden)
    }
  }, [merchantDetail])
  useEffect(() => {
    //console.log(convenienceFee)
  }, [convenienceFee])
  useEffect(() => {}, [showFees])
  return (
    <>
      <Col lg="12">
        {/* <Row>
                    <Col style={{ "marginTop": "0.2rem" }}></Col>
                </Row> */}

        <Row>
          <Col lg="2"></Col>
          <Col lg="8">
            <div
              style={{
                background: "#EAEFF5",
                borderRadius: "0.25rem",
                padding: "2%",
                textAlign: "center",
              }}
            >
              <Row>
                <Col className="item-left">
                  <p>{props.t("Amount")}</p>
                </Col>
                <Col className="item-right">
                  {(pr?.amountDetails.invoiceAmount).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}{" "}
                  {props.t(pr.amountDetails.currency)}
                </Col>
              </Row>
              {pr.amountDetails.convenienceFee !== 0 && showFees ? (
                <Row>
                  <Col className="item-left">
                    <p>{props.t("Convenience fee")}</p>
                  </Col>
                  <Col className="item-right">
                    {convenienceFee.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                    {props.t(" QAR")}
                  </Col>
                </Row>
              ) : null}
              {pr.amountDetails.cardFee &&
              pr.amountDetails.cardFee !== 0 &&
              showFees ? (
                <Row>
                  <Col className="item-left">
                    <p>{props.t("Card fee")}</p>
                  </Col>
                  <Col className="item-right">
                    {pr?.amountDetails?.cardFee?.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                    {props.t(" QAR")}
                  </Col>
                </Row>
              ) : null}

              <hr className="mt-0 mb-2" />
              <Row>
                <Col className="item-left">
                  <h5>{props.t("Total Amount")}</h5>
                </Col>
                <Col>
                  <h5 className="item-right color-danger">
                    {parseFloat(pr.amountDetails.totalAmount).toLocaleString(
                      undefined,
                      {
                        maximumFractionDigits: 2,
                      }
                    )}{" "}
                    {props.t(pr.amountDetails.currency)}
                  </h5>
                </Col>
              </Row>

              {/* <div><small>{props.t("Only Qatar Banks are accepted")}</small></div> */}
            </div>
          </Col>
          <Col lg="2"></Col>
        </Row>
      </Col>
    </>
  )
}

PaymentDetails.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(PaymentDetails))
