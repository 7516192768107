import React, { useContext, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import classnames from "classnames"

import { Col, Button, Row } from "reactstrap"
import { PaymentMethodContext } from "../../index"

const DebitRedirect = props => {
  const { apsDebitRedirect } = props
  const { handleDebitCard } = useContext(PaymentMethodContext)

  const [debitRedirect, setDebitRedirect] = useState(apsDebitRedirect)
  const [clicked, setClicked] = useState(false)

  useEffect(() => {
    if (apsDebitRedirect) setDebitRedirect(apsDebitRedirect)
  }, [apsDebitRedirect])

  return (
    <>
      {apsDebitRedirect && (
        <>
          <form
            name="frm"
            onSubmit={e => setClicked(true)}
            action={debitRedirect.formApi}
            method="post"
            id="frm"
          >
            <input
              type="hidden"
              name="signature"
              value={debitRedirect.signature}
            />
            <input type="hidden" name="command" value={debitRedirect.command} />
            <input
              type="hidden"
              name="access_code"
              value={debitRedirect.access_code}
            />
            <input
              type="hidden"
              name="merchant_identifier"
              value={debitRedirect.merchant_identifier}
            />
            <input
              type="hidden"
              name="merchant_reference"
              value={debitRedirect.merchant_reference}
            />
            <input
              type="hidden"
              name="language"
              value={debitRedirect.language}
            />
            <input
              type="hidden"
              name="customer_email"
              value={debitRedirect.customer_email}
            />
            <input
              type="hidden"
              name="customer_ip"
              value={debitRedirect.customer_ip}
            />
            <input
              type="hidden"
              name="payment_option"
              value={debitRedirect.payment_option}
            />
            <input type="hidden" name="amount" value={debitRedirect.amount} />
            <input
              type="hidden"
              name="currency"
              value={debitRedirect.currency}
            />

            <Button
              width="100%"
              className={classnames(
                "btn-outline-naps btn-block inner mb-2",
                "fw-medium"
              )}
              type="submit"
              disabled={clicked}
              outline
              style={{ fontSize: "1rem" }}
            >
              <span
                style={{
                  border: "1px solid",
                  borderRadius: "0.05rem",
                  padding: "2px",
                  fontSize: "7px",
                  fontStyle: "italic",
                  verticalAlign: "middle",
                }}
              >
                NAPS
              </span>
              <span style={{ fontSize: "1rem" }}>{props.t(" DEBIT CARD")}</span>
            </Button>
          </form>
        </>
      )}
    </>
  )
}

DebitRedirect.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(DebitRedirect))
