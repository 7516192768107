import axios from "axios"
import { del, get, post, put, getById, patch } from "./api_helper"
import * as url from "./url_helper"

////Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

////is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// USER
const postLogin = data => post(url.POST_LOGIN, data)
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data)
export const getUsersList = data => post(url.GET_USERS_LIST_BY_MERCHANTID, data)
export const getUserList = () => get(url.GET_ALL_USERS)
export const setUser = data => post(url.CREATE_USER, data)
export const getAdminUserByMerchantId = id =>
  get(url.GET_ADMIN_USER_BY_MERCHANTID + "/" + id)
export const deactivateUser = id =>
  del(url.DEACTIVATE_USER, { headers: { id: id } })
export const deleteUser = data => post(url.DELETE_USER, data)
export const updateNewUserPass = data => patch(url.NEW_USER_PASSWORD, data)
export const updateUserPass = data => post(url.CHANGE_PASSOWRD, data)
export const updateUser = data => patch(url.UPDATE_USER, data)
export const getUserDetail = id => get(url.GET_USER_BY_ID + "/" + id)
export const getUserListByMerchantId = merchantId =>
  get(`${url.GET_USERLIST_BY_MERCHANTID}/${merchantId}`, {
    params: { merchantId },
  })
export const getUsersListArchived = () => get(url.GET_USERS_LIST_ARCHIVED)
export const patchUserArchive = data => post(url.PATCH_USER_ARCHIVE, data)
export const isUserExisting = email => get(url.IS_USER_EXISTING, email)
export const setDummyUser = data => post(url.SET_DUMMY_USER, data)

//LOGS
export const getUserLogsById = id => get(url.USER_LOGS + "/" + id)
export const getUserLogList = id => get(url.USER_LOGS_ALL + "/" + id)
export const getEmailLogList = () => get(url.EMAIL_LOGS_ALL)
export const getErrorLogList = () => get(url.ERROR_LOGS_ALL)
export const getLogsTransactionUpdateList = () =>
  get(url.GET_LOGS_TRANSACTION_UPDATE_LIST)
export const updateTransactionLogs = data =>
  post(url.UPDATE_TRANSACTION_LOGS, data)
export const getParserErrorLogList = () => get(url.PARSER_ERROR_LOGS)
export const getParserExecutionLogList = () => get(url.PARSER_EXECUTION_LOGS)
export const getPlacerErrorLogList = () => get(url.PLACER_ERROR_LOGS)
export const getPlacerExecutionLogList = () => get(url.PLACER_EXECUTION_LOGS)
export const setWhatsAppLog = data => post(url.SET_WHATSAPP_LOGS, data)
export const getSyncCheckerExecutionLogList = () =>
  get(url.SYNC_CHECKER_EXECUTION_LOGS)
export const getSyncCheckerErrorLogList = () => get(url.SYNC_CHECKER_ERROR_LOGS)

//Customer
export const getCustomerList = () => get(url.GET_CUSTOMERS)
export const setCustomer = data => post(url.ADD_CUSTOMER, data)
export const getCustomerDetail = data => post(url.SEARCH_CUSTOMER, data)
export const updateCustomer = data => patch(url.UPDATE_CUSTOMER, data)
export const getCustomerListByMerchantID = data =>
  post(url.GET_ALL_CUSTOMERS_BY_MERCHANTID, data)
export const delCustomer = data => post(url.DELETE_CUSTOMER, data)
export const getCustomerListArchived = () => get(url.GET_CUSTOMER_LIST_ARCHIVED)
export const patchCustomerArchive = data =>
  post(url.PATCH_CUSTOMER_ARCHIVE, data)
//Merchant
export const getMerchantDetail = merchantId =>
  get(`${url.GET_MERCHANT_DETAIL}/${merchantId}`, { params: { merchantId } })
export const getMerchantList = () => get(url.GET_MERCHANT_LIST)
export const getMerchantIds = () => get(url.GET_MERCHANT_IDS)
export const setMerchant = data => post(url.SET_MERCHANT, data)
export const registerMerchant = data => post(url.REGISTER_MERCHANT, data)
export const getMerchantListReport = () => get(url.GET_MERCHANT_LIST_REPORT)
export const getMerchantDetialExternal = id =>
  get(url.GET_MERCHANT_DETAIL_EXTERNAL + "/" + id)
export const getMerchantByOid = id => get(url.GET_MERCHANT_BY_OID + "/" + id)
export const updateMerchant = data => patch(url.UPDATE_MERCHANT, data)

//Merchant PG Account
export const updateMerchantPGAccontSettings = data =>
  post(url.UPDATE_MERCHANT_PG_ACCOUNT_SETTINGS, data)
export const getMerchantPGAccountSettingByOid = id =>
  get(url.GET_MERCHANT_PG_ACCOUNT_SETTINGS + "/" + id)

//Source Portal
export const setItem = data => post(url.INSERT_ITEMS, data)
export const getItemDetail = searchValue => getById(url.GET_ITEM, searchValue)
export const updateItem = data => patch(url.UPDATE_ITEM, data)

//Payment Request
export const setPaymentRequest = data => post(url.SEND_PAYMENT_REQUEST, data)
export const uploadFileAttachment = file =>
  post(url.UPLOAD_FILE_ATTACHMENT, file)
export const getPaymentRequestList = merchantId =>
  get(`${url.GET_PAYMENTREQUEST_LIST}/${merchantId}`, {
    params: { merchantId },
  })
export const getPaymentRequestListArchived = () =>
  get(url.GET_PAYMENTREQUEST_LIST_ARCHIVED)

export const getPaymentRequestListReport = merchantId =>
  get(`${url.GET_PAYMENTREQUEST_LIST_REPORT}/${merchantId}`, {
    params: { merchantId },
  })

export const getPaymentMethod = data =>
  get(`${url.GET_PAYMENT_METHOD}/${data.paymentType}/${data.paymentRequestOId}`)

export const getPaymentRequestsDetails = id =>
  get(`${url.GET_PAYMENTREQUEST_DETAIL}/${id}`, { params: { id } })
export const getPaymentRequestsDetailsPublic = id =>
  get(`${url.GET_PAYMENTREQUEST_DETAIL_PUBLIC}/${id}`, { params: { id } })
// export const getPaymentRequestsDetailsPublic = data =>
// post(url.GET_PAYMENTREQUEST_DETAIL_PUBLIC,data)
export const updatePaymentRequest = data =>
  patch(url.UPDATE_PAYMENT_REQUEST, data)
export const patchPaymentRequestArchive = data =>
  post(url.PATCH_PAYMENTREQUEST_ARCHIVE, data)

//Device Finger Print setDeviceFingerPrint
export const setDeviceFingerPrint = data =>
  post(url.SET_DEVICE_FINGERPRINT, data)

//APS
export const setAPSRefund = data => post(url.SET_APS_REFUND, data)

//MyFatoorah
export const setMyfatoorahInitiatePayment = data =>
  post(url.SET_MYFATOORAH_INITIATE_PAYMENT, data)

//Dibsy
export const setDibsyInitiatePayment = data =>
  post(url.SET_DIBSY_INITIATE_PAYMENT, data)

export const setDibsyInitiateSessionApplepay = data =>
  post(url.SET_DIBSY_INITIATE_SESSION_APPLEPAY, data)

export const setDibsyRefund = data => post(url.SET_DIBSY_REFUND, data)

//Payment Method
export const setPaymentMethod = data =>
  post(
    url.SEND_PAYMENT_METHOD +
      "?objectId=" +
      data.objectId +
      "&paymentType=" +
      data.paymentType
  )

//Email Sending
export const sendPaymentRequestEmail = data => post(url.SEND_EMAIL, data)

//Payment Response
export const getPaymentResponseDetail = trackId =>
  get(`${url.GET_PAYMENTRESPONSE_DETAIL}/${trackId}`, { params: { trackId } })
export const getPRByUserId = id =>
  get(url.GET_PAYMENT_REQUESTS_BY_USERID + "/" + id)

//PAYMENT GATEWAY
export const setPaymentGateway = data => post(url.INSERT_PAYMENT_GATEWAY, data)
export const getPaymentGatewayList = () => get(url.GET_PAYMENT_GATEWAY_LIST)
export const getPaymentGatewayDetail = id =>
  get(url.GET_SINLE_PAYGATE + "/" + id)
export const updatePaymentGateway = data =>
  patch(url.UPDATE_PAYMENT_GATEWAY, data)

//PAYMENTHISTORY
export const updatePaymentHistory = data =>
  post(url.UPDATE_PAYMENT_HISTORY, data)
export const sendNotificationEmail = data =>
  post(
    url.SEND_NOTIFICATION_EMAIL +
      "?sendToCustomer=" +
      data.isNotifyCustomer +
      "&trackId=" +
      data.trackId
  )
export const downloadPaymentHistory = data =>
  post(url.DOWNLOAD_PAYMENT_HISTORY, data)

//DASHBOARD
export const getSummary = dates => post(url.GET_SUMMARY, dates)
export const setSurveyAnswers = data => post(url.SET_SURVEY_ANSWERS, data)
export const getTopMerchants = data => post(url.GET_TOP_MERCHANT, data)
export const getTopUsers = data => post(url.GET_TOP_USERS, data)

//OPTIONS
export const getCountryCode = () => get(url.GET_COUNTRY_CODE)

export { getLoggedInUser, isUserAuthenticated, postLogin }
