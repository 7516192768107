import React, { useState, useEffect } from "react"
import handleMultiPG, {
  handleMultiPGv2,
} from "components/PaymentTypesSelect/multiPG"

import SimpleBar from "simplebar-react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  CardTitle,
  Table,
} from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { PaymentListSelectionMerchant } from "../../components/PaymentTypesSelect/PaymentList"
import PaymentTypesColumns from "./DataFields/PaymentTypesColumns"

import { statusPillsBool } from "../../helpers/commonhelpers/statusPills"
function MultiPGCards(props) {
  const { paymentTypes, commissionSettings } = props
  const [ptList, setPtList] = useState()
  const pageOptions = {
    sizePerPage: 3,
    totalSize: ptList?.length,
    custom: true,
  }
  useEffect(() => {
    //console.log(paymentTypes)
    if (paymentTypes) {
      setPtList(PaymentListSelectionMerchant(commissionSettings, paymentTypes))
    }
  }, [paymentTypes, commissionSettings])
  // useEffect(() => {
  //   console.log(commissionSettings)
  // }, [commissionSettings])
  useEffect(() => {
    //console.log(ptList)
  }, [ptList])

  function handlePGName(value) {
    switch (value) {
      case "1":
        return "Amazon Payment Services"
      case "2":
        return "Dibsy"
    }
  }
  const handleTableChange = (type, { page, searchText }) => {
    setPaymentGatewayList(
      paymentGatewayList?.filter(pg =>
        Object.keys(pg).some(
          key =>
            typeof pg[key] === "string" &&
            pg[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }
  return (
    <Card style={{ minHeight: "350px", maxHeight: "250px", overflow: "auto" }}>
      <CardTitle style={{ backgroundColor: "#CCDFF1", padding: "5px" }}>
        <i className="fas fa-credit-card text-success mr-1" />
        Enabled Payment Types
      </CardTitle>
      <CardBody>
        <SimpleBar style={{ maxHeight: "300vh" }}>
          {/* <div className="container-scroll "> */}
          {/* <div className="center-col "> */}

          <Row>
            <Col lg="12">
              {ptList?.map((pt, index) => {
                return (
                  <div key={index}>
                    <Row>
                      <Col>
                        {" "}
                        <h5 className="color-primary">
                          <b>{pt.pgName}</b>
                        </h5>
                      </Col>
                    </Row>
                    {/* <Row className="my-2 color-primary">
                      <Col className="ml-4">
                        <Label>
                          <b>Payment Type:</b>
                        </Label>
                      </Col>
                      <Col className="">
                        <b>Status:</b>
                      </Col>
                    </Row> */}
                    {pt?.cards?.map((p, i) => {
                      return (
                        <>
                          {p?.basis ? (
                            <Row key={i} className="my-2">
                              <Col className="ml-4">
                                <Label>{p?.basis.toUpperCase()}</Label>
                              </Col>
                              <Col className="">
                                {statusPillsBool(
                                  p?.enabled,
                                  p?.enabled.toString(),
                                  true
                                )}
                              </Col>
                            </Row>
                          ) : null}
                        </>
                      )
                    })}
                    <hr />
                  </div>
                )
              })}
            </Col>
          </Row>

          {/* </div> */}
          {/* </div> */}
        </SimpleBar>
      </CardBody>
    </Card>
  )
}

export default MultiPGCards
