import React, { useEffect, useState } from "react"
import { Col, Card, CardBody, Row, CardTitle, Button } from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { isEmpty } from "lodash"
import ConvenienceFeeColumns from "./DataFields/ConvenienceFeeColumns"
import CommisionModal from "./CommissionModal"
import Table from "components/MainSearch/Table"

function ConvenienceFee(props) {
  const { convenienceFees, user } = props
  const [activePG, setActivePG] = useState()
  const pageOptions = {
    sizePerPage: 3,
    totalSize: convenienceFees.length,
    custom: true,
  }
  useEffect(() => {
    // if (paymentGatewayEnrollments) {
    //   paymentGatewayEnrollments.map(i => {
    //     if (i?.isActive) {
    //       setActivePG(i)
    //     }
    //   })
    // }
  }, [convenienceFees])

  useEffect(() => {
    // console.log(activePG)
  }, [activePG])
  const handleTableChange = (type, { page, searchText }) => {
    setPaymentGatewayList(
      paymentGatewayList?.filter(pg =>
        Object.keys(pg).some(
          key =>
            typeof pg[key] === "string" &&
            pg[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }
  return (
    <Card style={{ minHeight: "350px", maxHeight: "450px" }}>
      <CardTitle style={{ backgroundColor: "#CCDFF1", padding: "5px" }}>
        <i className="fas fa-credit-card text-success mr-1" />
        Convenience Fees
      </CardTitle>
      <CardBody>
        <Row>
          <Col lg="12">
            <PaginationProvider pagination={paginationFactory(pageOptions)}>
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField={"_id"}
                  data={convenienceFees || []}
                  columns={ConvenienceFeeColumns(user)}
                  bootstrap4
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        {/* <Col sm="4">
                          <div className="search-box mr-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <SearchBar
                                id="pgSearch"
                                {...toolkitProps.searchProps}
                              />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col> */}
                        {/* <Col sm="8">
                          <div className="text-sm-right">
                            <Button color="success">New Convenience Fee</Button>
                          </div>
                        </Col> */}
                      </Row>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              responsive
                              bordered={false}
                              striped={false}
                              classes={"table table-centered table-nowrap"}
                              headerWrapperClasses={"thead-light"}
                              {...toolkitProps.baseProps}
                              onTableChange={handleTableChange}
                              {...paginationTableProps}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-md-center mt-30">
                        <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                          <PaginationListStandalone {...paginationProps} />
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default ConvenienceFee
