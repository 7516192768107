import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Row, Col, Label, Button } from "reactstrap"
import QRCode from "qrcode.react"
import moment from "moment"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
function OverviewPopup(props) {
  const [prDetails, setPrDetails] = useState(props.paymentRequestDeatils)

  useEffect(() => {
    if (props.paymentRequestDeatils !== undefined) {
      setPrDetails(props.paymentRequestDeatils)
    }
  }, [props.paymentRequestDeatils])

  useEffect(() => {}, [prDetails])

  const CopyTinyURL = () => {
    showToastInfo("Link copied.")
    navigator.clipboard.writeText(prDetails.generatedLinkShort)
  }

  const showToastInfo = message => {
    toastr.options = {
      positionClass: "toast-top-right",
      timeOut: 10000,
      extendedTimeOut: 1000,
      closeButton: true,
      debug: false,
      progressBar: true,
      preventDuplicates: true,
      newestOnTop: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 300,
    }

    toastr.info(message)
  }
  return (
    <div>
      <Row className="mx-auto">
        <Col md="8">
          PAYMENT REQUEST #{" "}
          <Label>
            {prDetails.paymentRequestId ? prDetails.paymentRequestId : "N/A"}
          </Label>
        </Col>
        <Col md="4">
          DATE{" "}
          <Label>
            {" "}
            {moment(prDetails.paymentRequestDate).format(
              "DD MMM YYYY, h:mm:ss a"
            )}{" "}
          </Label>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="item-center bg-color-secondary py-2 mx-2">
            <Col>
              <Row className="item-center">
                {" "}
                <h1>
                  {parseFloat(
                    prDetails.amountDetails.invoiceAmount +
                      prDetails.amountDetails.serviceFee
                  ).toFixed(2)}{" "}
                  {prDetails.amountDetails.currency}
                </h1>
              </Row>
              <Row className="item-center">
                {" "}
                AMOUNT DUE FOR{" "}
                {prDetails.customerDetails && prDetails.customerDetails.name}
              </Row>
            </Col>
          </Row>

          <Row className="item-center my-auto">
            <div className="app-search  d-lg-block pointer">
              <div className="position-relative" onClick={CopyTinyURL}>
                <input
                  type="text"
                  className="form-control pointer form-control-lg qrcode-searchbar"
                  value={prDetails.generatedLinkShort}
                  placeholder="HTTPS://PAYALLLINK.COM"
                  disabled
                />
                <span className="mdi mdi-content-copy color-primary"></span>
              </div>
            </div>
          </Row>
          <Row className="item-center">
            <QRCode
              value={
                prDetails.generatedLinkShort === undefined
                  ? "HTTPS://PAYALLLINK.COM"
                  : prDetails.generatedLinkShort
              }
              className="qr-code "
            />
          </Row>
        </Col>
      </Row>
      <Row className="float-right">
        <div style={{ margin: "5px" }}>
          <Button
            type="submit"
            color="danger"
            onClick={() => {
              props.onChange(false)
              window.location.reload()
            }}
          >
            Close
          </Button>
        </div>
      </Row>
    </div>
  )
}
const mapStateToProps = state => {
  return {
    paymentRequestDeatils: state.paymentRequests.paymentRequestDetail,
  }
}
export default connect(mapStateToProps)(OverviewPopup)
