import React, { useState, useEffect } from "react"
import { Row, Card, CardTitle, CardBody, Table } from "reactstrap"
import AuthUser from "components/AuthUser/AuthUser"
import SimpleBar from "simplebar-react"
import { statusPillsBool } from "../../helpers/commonhelpers/statusPills"
import handleHoursToReadableText from "components/Utility/HoursToReadableText"
export default function Configuration(props) {
  const { linkValidity, marketingBanner, logo, hiddenFee } = props

  const [user, setUser] = useState(AuthUser())
  useEffect(() => {
    //console.log(hiddenFee)
  }, [hiddenFee])
  return (
    <>
      <Card
        style={{ minHeight: "350px", maxHeight: "450px", overflow: "auto" }}
      >
        <CardTitle style={{ backgroundColor: "#CCDFF1", padding: "5px" }}>
          <i className="fas fa-wrench text-success mr-1" />
          Configuration
        </CardTitle>
        <CardBody>
          <Row>
            <Table>
              <SimpleBar style={{ maxHeight: "450px" }}>
                <tbody>
                  <tr>
                    <th scope="row">
                      <i className="fas fa-file-alt text-success mr-1" />
                      Invoice Link Validity:{" "}
                    </th>
                    <td>{handleHoursToReadableText(linkValidity)} </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <i className="fas fa-file-alt text-success mr-1" />
                      Marketing Banner:{" "}
                    </th>
                    <td>
                      <a href={marketingBanner}>Show Banner</a>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <i className="fas fa-file-alt text-success mr-1" />
                      Logo:{" "}
                    </th>
                    <td>
                      <a href={logo}>Show Logo</a>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <i className="fas fa-file-alt text-success mr-1" />
                      Show Fees{" "}
                    </th>
                    <td>
                      {statusPillsBool(hiddenFee, hiddenFee.toString(), true)}
                    </td>
                  </tr>
                </tbody>
              </SimpleBar>
            </Table>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}
