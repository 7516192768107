import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Card, CardBody, Row, Col, Button } from "reactstrap"
import PaymentRequestExpressModal from "./PaymentRequestExpressModal"
import OverviewPRExpress from "./OverviewPRExpress"
import ExpressImg from "../../assets/images/Express.png"
import ReactTooltip from "react-tooltip"
import { Link } from "react-router-dom"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import errorCatching from "../../helpers/commonhelpers/errorCatching"
import { isEmpty } from "lodash"
import {
  getMerchantDetail,
  getCustomerList,
  getCustomersByMerchantID,
  updateCustomerExpress,
  setPaymentRequestExpress,
  sendPaymentRequestEmailExpress,
  setWhatAppLog,
  setCustomerExpress,
  getCountryCode,
} from "store/actions"
import AuthUser from "components/AuthUser/AuthUser"
import CustomerList from "components/Customer/CustomerList"
import handleMultiPG from "components/PaymentTypesSelect/multiPG"
import PaymentListSelection from "../../components/PaymentTypesSelect/PaymentList"
const initialPrState = {
  TransactionReference: null,
  PaymentRequestId: "",
  AmountDetails: {
    InvoiceAmount: 1.0,
    ConvenienceFee: 0.0,
    ServiceFee: 0.0,
    TotalAmount: 1.0,
    PaymentType: "",
    Currency: "QAR",
  },
  Remarks: "",
  GeneratedLinkLong: "",
  GeneratedLinkShort: "",
  GeneratedLinkStatus: "",
  MerchantId: "",
  ItemId: "",
  ItemSource: "PORTAL",
  CreatedBy: "",
  PaymentRequestDate: new Date().toISOString(),
  LastPayment: "",
  PaymentStatus: "Unpaid",
  PaymentGatewayId: "",
  IsExpress: true,
  PaymentRequestSettings: {
    AvailablePaymentOptions: [],
    PaymentType: "",
    Language: "En",
    LinkValidUpto: new Date().toISOString(),
    LinkValidity: 24,
    TerminalId: "",
  },
  CustomerDetails: {
    Name: "",
    Mobile: "",
    Email: "",
    Type: "New",
  },
  EmailSettings: {
    SubjectLine: "Payment Request for",
    Attachment: [],
    SentTo: [],
  },
  PaidDetails: null,
  Commission: {
    Type: "",
    Value: "",
    TotalCommission: 0,
  },
  SellcoSettings: {
    CreatedBy: null,
  },
}

export const PaymentRequestExpress = props => {
  const [prDetails, setPrDetails] = useState(initialPrState)
  const [modal, setModal] = useState(false)
  const [selectCustomer, setSelectCustomer] = useState({})
  const [emailType, setEmailType] = useState("")
  const [step, setStep] = useState(0)
  const [errorResponse, setErrorResponse] = useState(undefined)
  const [activeCustomers, setActiveCustomers] = useState([])
  const [updateRecurringCustomer, setUpdateRecurringCustomer] = useState(false)

  const [paymentList, setPaymentList] = useState()
  const [commissionSettingsPaymentTypes, setCommissionSettingsPaymentTypes] =
    useState([])
  const user = AuthUser()
  let form = ""
  const {
    onGetMerchantDetials,
    merchantDetail,
    customersList,
    onGetCustomerList,
    onGetCustomerByMerchantId,
    onUpdateExpressCustomer,
    onSetPaymentRequestExpress,
    paymentRequestDetailExpress,
    loading,
    onSendPaymentRequestEmail,
    onSetWhatsAppLogo,
    prExpress,
    onSetCustomer,
    newCustomer,
    onGetCountryCode,
    countryCodes,
    error,
    merchantLoading,
  } = props
  //////////////////////////////////////////////////////////
  useEffect(() => {
    UserRole()
    onGetMerchantDetials(user.merchantId)
    onGetCountryCode()
  }, [])
  useEffect(() => {
    if (error !== undefined && !isEmpty(error)) {
      setModal(false)
      setErrorResponse(error)
    }
  }, [error])

  useEffect(() => {}, [errorResponse])
  useEffect(() => {
    //console.log(loading)
  }, [loading])
  useEffect(() => {
    const cardtypesList = new Array()
    if (
      merchantDetail !== undefined &&
      Array.isArray(merchantDetail.paymentGatewayEnrollments)
    ) {
      setPaymentList(
        PaymentListSelection(
          merchantDetail.paymentGatewayEnrollments,
          merchantDetail?.paymentGatewaySettings.enabledPaymentOptions
        )
      )
    }
  }, [merchantDetail])

  useEffect(() => {
    //console.log(commissionSettingsPaymentTypes)
  }, [commissionSettingsPaymentTypes])
  useEffect(() => {
    //console.log(customersList)
    if (Array.isArray(customersList)) {
      setActiveCustomers(customersList.filter(cus => cus.isActive === true))
    }
  }, [customersList])

  useEffect(() => {
    // console.log(activeCustomers )
  }, [activeCustomers])

  // useEffect(() => {}, [activeCustomers])
  useEffect(() => {
    //console.log(selectCustomer)
    if (selectCustomer !== undefined) {
      if (selectCustomer?.type === "New") {
        //selectCustomer.type = "Recurring"
        setUpdateRecurringCustomer(true)
        //onUpdateExpressCustomer(selectCustomer)
      }
    }
  }, [selectCustomer])
  useEffect(() => {
    //console.log(prDetails)
  }, [prDetails])
  useEffect(() => {
    //console.log(emailType)
  }, [emailType])
  useEffect(() => {
    //console.log(paymentRequestDetailExpress, loading)
    if (
      paymentRequestDetailExpress?._id !== undefined &&
      paymentRequestDetailExpress !== undefined
    ) {
      //console.log(paymentRequestDetailExpress)
      handlePREmail(emailType)
    }
  }, [paymentRequestDetailExpress])
  useEffect(() => {
    // console.log(step)
  }, [step])
  useEffect(() => {
    // console.log(newCustomer)
    if (newCustomer === true && prDetails.CustomerDetails?.Name !== undefined) {
      showToastSuccess(
        "Customer " + prDetails.CustomerDetails.Name + " has been created."
      )
    }
    if (
      newCustomer === false &&
      prDetails.CustomerDetails?.Name !== undefined
    ) {
      showToastError(
        "Customer " + prDetails.CustomerDetails.Name + " is already listed."
      )
    }
  }, [newCustomer])

  useEffect(() => {
    //console.log(countryCodes)
  }, [countryCodes])
  //////////////////////////////////////////////////////////
  function handlePaymentRequestChange(changes) {
    setPrDetails(changes)
  }

  const toggleModal = () => {
    setModal(!modal)
    setStep(0)
    setPrDetails(state => ({ ...state, initialPrState }))
  }
  const UserRole = () => {
    if (user.role !== "SuperAdmin") {
      const data = {
        type: "all",
        merchantId: user.merchantId,
      }
      onGetCustomerByMerchantId(data)
    }
    if (user.role === "SuperAdmin") {
      onGetCustomerList()
    }
  }

  function handlePRCreate(value) {
    if (updateRecurringCustomer) {
      selectCustomer.type = "Recurring"
      onUpdateExpressCustomer(selectCustomer)
    }
    if (prDetails.CustomerDetails.Type === "New") {
      let cus = {
        Name: prDetails.CustomerDetails.Name,
        Email: prDetails.CustomerDetails.Email,
        Mobile: prDetails.CustomerDetails.Mobile,
        RegistrationDate: "2020-01-01T01:01:01",
        Type: "New",
        MerchantId: user.merchantId,
        IsActive: true,
      }
      onSetCustomer(cus)
    }
    setEmailType(value)
    onSetPaymentRequestExpress(prDetails)
  }

  const handlePREmail = value => {
    if (value === "all") {
      const data = {
        id: paymentRequestDetailExpress._id,
        recipient: null,
        actionType: "Initial Send of Payment Request",
      }
      setStep(1)
      onSendPaymentRequestEmail(data)
    } else if (value === "user") {
      const data = {
        id: paymentRequestDetailExpress._id,
        recipient: user.email,
        actionType: "Initial Send of Payment Request",
      }
      setStep(1)
      onSendPaymentRequestEmail(data)
    } else if (value === "whatsapp") {
      const data = {
        id: paymentRequestDetailExpress._id,
        recipient: null,
        actionType: "Initial Send of Payment Request",
      }
      onSendPaymentRequestEmail(data)

      let newMobile = prDetails.CustomerDetails.Mobile.replace("-", "")
      let name = prDetails.CustomerDetails.Name
      let newName = name.split(" ").join("%20")
      let whatAppMessage =
        "Payment%20Request%20for%20" +
        newName +
        ".%20" +
        props.paymentRequestDetailExpress.generatedLinkShort
      let whatsAppUrl = "https://wa.me/" + newMobile + "?text=" + whatAppMessage
      const whatsApp = {
        PhoneNumber: newMobile,
        pr_id: props.paymentRequestDetailExpress._id,
        Message: whatAppMessage,
      }
      toggleModal()
      onSetWhatsAppLogo(whatsApp)

      window.open(whatsAppUrl, "_blank")
    }
  }
  //////////////////////////////////////////////////////////////////////////
  const showToastSuccess = message => {
    toastr.options = {
      positionClass: "toast-top-right",
      timeOut: 10000,
      extendedTimeOut: 1000,
      closeButton: true,
      debug: false,
      progressBar: true,
      preventDuplicates: true,
      newestOnTop: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 300,
    }

    toastr.success(message)
  }

  const showToastInfo = message => {
    toastr.options = {
      positionClass: "toast-top-right",
      timeOut: 10000,
      extendedTimeOut: 1000,
      closeButton: true,
      debug: false,
      progressBar: true,
      preventDuplicates: true,
      newestOnTop: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 300,
    }

    toastr.info(message)
  }

  const showToastError = message => {
    toastr.options = {
      positionClass: "toast-top-right",
      timeOut: 10000,
      extendedTimeOut: 1000,
      closeButton: true,
      debug: false,
      progressBar: true,
      preventDuplicates: true,
      newestOnTop: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 300,
    }

    toastr.error(message)
  }
  //////////////////////////////////////////////////////////////////////////
  switch (step) {
    case 0:
      form = (
        <PaymentRequestExpressModal
          countryCodes={countryCodes}
          isOpen={modal}
          loading={loading}
          customers={activeCustomers}
          toggle={toggleModal}
          onChange={value => setSelectCustomer(value)}
          commissionSettingsPaymentTypes={commissionSettingsPaymentTypes}
          prDetails={prDetails}
          handlePRCreate={handlePRCreate}
          handlePaymentRequestChange={handlePaymentRequestChange}
          paymentList={paymentList}
          merchantLoading={merchantLoading}
        />
      )

      break
    case 1:
      form = (
        <OverviewPRExpress
          isOpen={modal}
          loading={loading}
          prExpress={prExpress}
          toggle={toggleModal}
          paymentRequestDetailExpress={paymentRequestDetailExpress}
        />
      )

      break
    default:
      form = <ExpressPayRequestModal />
  }
  ///////////////////////////////////////////////////////////////////////////
  return (
    <React.Fragment>
      {errorCatching(errorResponse, setErrorResponse)}
      {form}
      <ReactTooltip place="bottom" type="dark" effect="solid" />

      <Row className="  item-center">
        <Col sm="2">
          <Link
            to="#"
            onClick={() => {
              toggleModal()
            }}
            style={{ cursor: "pointer" }}
            data-tip="Send an express payment request."
            data-text-color="white"
            data-background-color="black"
            data-html={true}
          >
            <img
              src={ExpressImg}
              alt="Express Button"
              className="express-logo-size"
            />
          </Link>
          {/* <Button
            color="warning"
            data-toggle="modal"
            outline
            // data-target=".bs-example-modal-lg"
            className="express-payment btn-small"
            //className="mr-1 waves-effect waves-light my-3"
            onClick={() => {
              toggleModal()
            }}
          >
           
          </Button> */}
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    loading: state.paymentRequests.loading,
    prExpress: state.paymentRequests.prExpress,
    merchantDetail: state.Merchants.merchantDetail,
    customersList: state.Customer.customerList,
    paymentRequestDetailExpress:
      state.paymentRequests.paymentRequestDetailExpress,
    newCustomer: state.Customer.expressCustomer,
    countryCodes: state.Options.countryCodes,
    error: state.paymentRequests.error,
    merchantLoading: state.Merchants.loading,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetMerchantDetials: id => dispatch(getMerchantDetail(id)),
  onGetCustomerList: () => dispatch(getCustomerList()),
  onGetCustomerByMerchantId: id => dispatch(getCustomersByMerchantID(id)),
  onUpdateExpressCustomer: data => dispatch(updateCustomerExpress(data)),
  onSetPaymentRequestExpress: data => dispatch(setPaymentRequestExpress(data)),
  onSendPaymentRequestEmail: data =>
    dispatch(sendPaymentRequestEmailExpress(data)),
  onSetWhatsAppLogo: data => dispatch(setWhatAppLog(data)),
  onSetCustomer: data => dispatch(setCustomerExpress(data)),
  onGetCountryCode: () => dispatch(getCountryCode()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentRequestExpress)
