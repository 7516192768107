import React, { useState, useEffect } from "react"
import { Card, CardBody, CardTitle, Col, Label, Row, Table } from "reactstrap"
import { refundLogo, noRefundLogo } from "components/SharedImages"
import { map } from "lodash"

const Items = props => {
  const [parsedItem, setParsedItem] = useState({})
  const [plus, setPlus] = useState()
  const [remarks, setRemarks] = useState()
  const [itemId, setItemId] = useState()
  const [fileName, setFileName] = useState()
  const [refund, setRefund] = useState()
  const [pnr, setPNR] = useState()
  useEffect(() => {
    //console.log(props.purchasedItems)
    var parsed = JSON.parse(
      props.purchasedItems ? props.purchasedItems : "null"
    )
    setParsedItem(parsed)
  }, [])
  useEffect(() => {
    //console.log(parsedItem)
    if (parsedItem?.plus) {
      setPlus(parsedItem?.plus)
    }
    if (parsedItem?.RM_PnrRemarks) {
      setRemarks(parsedItem?.RM_PnrRemarks)
    }
    if (parsedItem?.AMD_AirRecordHeader) {
      setPNR(parsedItem?.AMD_AirRecordHeader?.PNR_RECORD_LOCATOR)
    }
  }, [parsedItem])
  useEffect(() => {
    // console.log(plus)
    plus?.map(p => {
      if (p?.Name === "ItemId") {
        setItemId(p?.Value)
      }
      if (p?.Name === "FileName") {
        setFileName(p?.Value)
      }
    })
  }, [plus])
  useEffect(() => {
    remarks?.map(rm => {
      //console.log(rm?.REMARK_TEXT)
      if (rm?.REMARK_TEXT?.includes("*FARETYPE")) {
        //console.log("FARETYPE")
        setRefund(rm?.REMARK_TEXT.split(":")[1])
      }
    })
  }, [remarks])
  useEffect(() => {}, [itemId, fileName, refund])
  // let refundLogo =
  //   "https://res.cloudinary.com/developerqa/image/upload/v1736774509/merchant/45225461_tmyasv.jpg"
  // let noRefundLogo =
  //   "https://res.cloudinary.com/developerqa/image/upload/v1736774514/merchant/48694533_fx194s.jpg"

  return (
    <Card style={{ minHeight: "130px", overflow: "auto" }}>
      <CardBody>
        <Row>
          <Col lg="12">
            {props.source === "SELLCO" && (
              <div>
                <CardTitle className="mb-4">
                  Item List Breakdown{" "}
                  <small>
                    {itemId ? itemId : null}-{fileName ? fileName : null}
                  </small>
                </CardTitle>
                <Row className="my-2">
                  <Col>
                    <CardTitle
                      style={{ background: "#EAEFF5", padding: "1px" }}
                    >
                      {" "}
                      <Label>
                        PNR: <b>{pnr}</b>{" "}
                        {refund === "REFUNDABLE" ? //   alt="refund logo" //   height={"16 px"} //   width={"60px"} //   src={refundLogo} // <img
                        //   className="mx-2"
                        // />
                        null : (
                          <img
                            src={noRefundLogo}
                            width={"60px"}
                            height={"30px"}
                            alt="refund logo"
                            className="mx-2 my-1"
                          />
                        )}
                      </Label>
                    </CardTitle>
                    <Table responsive size="sm" style={{ textAlign: "left" }}>
                      <tbody>
                        <tr>
                          <th scope="col">
                            <small>
                              <b>FLIGHT NO</b>
                            </small>
                          </th>
                          <th scope="col">
                            <small>
                              <b>DEPARTURE</b>
                            </small>
                          </th>

                          <th scope="col">
                            <small>
                              <b>ARRIVAL</b>
                            </small>
                          </th>
                        </tr>

                        {map(
                          parsedItem?.U_UnticketedAirSegment,
                          (item, index) => (
                            <tr key={index}>
                              <td>
                                <small>
                                  {" "}
                                  {item.AIRLINE_CODE} - {item.FLIGHT_NUMBER}
                                </small>
                              </td>
                              <td>
                                <small>
                                  {" "}
                                  {item.ORIGIN_CITY_AIRPORT_NAME} -{" "}
                                  {item.ORIGIN_COUNTRY_CODE}
                                </small>
                              </td>

                              <td>
                                <small>
                                  {item.DESTINATION_CITY_AIRPORT_NAME}-{" "}
                                  {item.DESTINATION_COUNTRY_CODE}
                                </small>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                    {/* <Table responsive size="sm" style={{ textAlign: "left" }}>
                      <tbody>
                        <tr>
                          <th scope="col">
                            <small>
                              <b>TITLE</b>
                            </small>
                          </th>
                          <th scope="col">
                            <small>
                              <b> NAME</b>
                            </small>
                          </th>
                          <th scope="col">
                            <small>
                              <b> MOBILE</b>
                            </small>
                          </th>
                        </tr>

                        {map(parsedItem?.I_Name, (passenger, index) => (
                          <tr key={index}>
                            <td>
                              <small> {passenger.PASSENGER_NAME_TITLE}</small>
                            </td>
                            <td>
                              <small>
                                {" "}
                                {passenger.PASSENGER_FIRST_NAME}{" "}
                                {passenger.PASSENGER_LAST_NAME}
                              </small>
                            </td>
                            <td>
                              <small>{passenger.PHONE_FIELD_DATA}</small>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table> */}
                  </Col>
                </Row>
              </div>
            )}

            {props.source === "PORTAL" && parsedItem?.Items ? (
              <div>
                <CardTitle className="mb-4">
                  Item List Breakdown <small>{parsedItem.ItemId}</small>
                </CardTitle>

                <Table>
                  <tbody>
                    <tr>
                      <th>Item Name</th>
                      <th>Qty </th>
                      <th>Amount </th>
                      <th>Refundable </th>
                    </tr>
                    {map(parsedItem.Items, (item, index) => (
                      <tr key={index}>
                        <td>{item.ItemName}</td>
                        <td>{item.Qty}</td>
                        <td>{item.Amount}</td>
                        <td>
                          {" "}
                          <small>
                            {/* {statusPillsBool(
                          item.Refundable,
                          item.Refundable.toString(),
                          true
                        )} */}
                            {item.Refundable ? null : ( // /> //   className="mx-2" //   alt="refund logo" //   height={"16 px"} //   width={"60px"} //   src={refundLogo} // <img
                              <img
                                src={noRefundLogo}
                                width={"60px"}
                                height={"30px"}
                                alt="refund logo"
                                className="mx-2"
                              />
                            )}
                          </small>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ) : null}
            {props.source === "PORTAL" && parsedItem?.Items === undefined ? (
              <CardTitle className="mb-4">
                Item List Breakdown <small>N/A</small>
              </CardTitle>
            ) : null}
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default Items
