/* PAYMENTREQUESTS */
export const GET_PAYMENTREQUEST_LIST = "GET_PAYMENTREQUEST_LIST"
export const GET_PAYMENTREQUEST_LIST_SUCCESS = "GET_PAYMENTREQUEST_LIST_SUCCESS"
export const GET_PAYMENTREQUEST_LIST_FAIL = "GET_PAYMENTREQUEST_LIST_FAIL"

/* PAYMENTREQUESTS */
export const GET_PAYMENTREQUEST_LIST_REPORT = "GET_PAYMENTREQUEST_LIST_REPORT"
export const GET_PAYMENTREQUEST_LIST_REPORT_SUCCESS =
  "GET_PAYMENTREQUEST_LIST_REPORT_SUCCESS"
export const GET_PAYMENTREQUEST_LIST_REPORT_FAIL =
  "GET_PAYMENTREQUEST_LIST_REPORT_FAIL"

/* PAYMENTREQUESTS DETAIL*/
export const GET_PAYMENTREQUEST_DETAIL = "GET_PAYMENTREQUEST_DETAIL"
export const GET_PAYMENTREQUEST_DETAIL_SUCCESS =
  "GET_PAYMENTREQUEST_DETAIL_SUCCESS"
export const GET_PAYMENTREQUEST_DETAIL_FAIL = "GET_PAYMENTREQUEST_DETAIL_FAIL"

/* PAYMENTREQUESTS DETAIL - PUBLIC*/
export const GET_PAYMENTREQUEST_DETAIL_PUBLIC =
  "GET_PAYMENTREQUEST_DETAIL_PUBLIC"
export const GET_PAYMENTREQUEST_DETAIL_PUBLIC_SUCCESS =
  "GET_PAYMENTREQUEST_DETAIL_PUBLIC_SUCCESS"
export const GET_PAYMENTREQUEST_DETAIL_PUBLIC_FAIL =
  "GET_PAYMENTREQUEST_DETAIL_PUBLIC_FAIL"

/* PAYMENTREQUESTS PAYMENT METHOD */
export const GET_PAYMENT_METHOD = "GET_PAYMENT_METHOD"
export const GET_PAYMENT_METHOD_SUCCESS = "GET_PAYMENT_METHOD_SUCCESS"
export const GET_PAYMENT_METHOD_FAIL = "GET_PAYMENT_METHOD_FAIL"

/* SET DEVICE FINGERPRINT*/
export const SET_DEVICE_FINGERPRINT = "SET_DEVICE_FINGERPRINT"
export const SET_DEVICE_FINGERPRINT_SUCCESS = "SET_DEVICE_FINGERPRINT_SUCCESS"
export const SET_DEVICE_FINGERPRINT_FAIL = "SET_DEVICE_FINGERPRINT_FAIL"

// PAYMENT REQUEST CREATION
export const SET_PAYMENT_REQUEST = "SET_PAYMENT_REQUEST"
export const SET_PAYMENT_REQUEST_SUCCESS = "SET_PAYMENT_REQUEST_SUCCESS"
export const SET_PAYMENT_REQUEST_FAIL = "SET_PAYMENT_REQUEST_FAIL"

export const SET_PAYMENT_REQUEST_EXPRESS = "SET_PAYMENT_REQUEST_EXPRESS"
export const SET_PAYMENT_REQUEST_EXPRESS_SUCCESS =
  "SET_PAYMENT_REQUEST_EXPRESS_SUCCESS"
export const SET_PAYMENT_REQUEST_EXPRESS_FAIL =
  "SET_PAYMENT_REQUEST_EXPRESS_FAIL"

// PAYMENT METHOD

export const SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD"
export const SET_PAYMENT_METHOD_SUCCESS = "SET_PAYMENT_METHOD_SUCCESS"
export const SET_PAYMENT_METHOD_FAIL = "SET_PAYMENT_METHOD_FAIL"

export const UPDATE_PAYMNET_REQUEST = "UPDATE_PAYMNET_REQUEST"
export const UPDATE_PAYMNET_REQUEST_SUCCESS = "UPDATE_PAYMNET_REQUEST_SUCCESS"
export const UPDATE_PAYMNET_REQUEST_FAIL = "UPDATE_PAYMNET_REQUEST_FAIL"

// SEND PAYMENT REQUEST EMAIL

export const SEND_PAYMENT_REQUEST_EMAIL = "SEND_PAYMENT_REQUEST_EMAIL"
export const SEND_PAYMENT_REQUEST_EMAIL_SUCCESS =
  "SEND_PAYMENT_REQUEST_EMAIL_SUCCESS"
export const SEND_PAYMENT_REQUEST_EMAIL_FAIL = "SEND_PAYMENT_REQUEST_EMAIL_FAIL"

export const SEND_PAYMENT_REQUEST_EMAIL_NON_EXPRESS =
  "SEND_PAYMENT_REQUEST_EMAIL_NON_EXPRESS"
export const SET_PAYMENT_REQUEST_EMAIL_NON_EXPRESS_SUCCESS =
  "SET_PAYMENT_REQUEST_EMAIL_NON_EXPRESS_SUCCESS"
export const SET_PAYMENT_REQUEST_EMAIL_NON_EXPRESS_FAIL =
  "SET_PAYMENT_REQUEST_EMAIL_NON_EXPRESS_FAIL"

export const SEND_PAYMENT_REQUEST_EMAIL_EXPRESS =
  "SEND_PAYMENT_REQUEST_EMAIL_EXPRESS"
export const SET_PAYMENT_REQUEST_EMAIL_EXPRESS_SUCCESS =
  "SET_PAYMENT_REQUEST_EMAIL_EXPRESS_SUCCESS"
export const SET_PAYMENT_REQUEST_EMAIL_EXPRESS_FAIL =
  "SET_PAYMENT_REQUEST_EMAIL_EXPRESS_FAIL"

export const UPDATE_PAYMENT_REQUEST_BOOLEAN = "UPDATE_PAYMENT_REQUEST_BOOLEAN"
export const UPDATE_PAYMENT_REQUEST_BOOLEAN_SUCCESS =
  "UPDATE_PAYMENT_REQUEST_BOOLEAN_SUCCESS"
export const UPDATE_PAYMENT_REQUEST_BOOLEAN_FAIL =
  "UPDATE_PAYMENT_REQUEST_BOOLEAN_FAIL"

export const FILE_UPLOAD = "FILE_UPLOAD"
export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS"
export const FILE_UPLOAD_FAIL = "FILE_UPLOAD_FAIL"

export const RESTART_STATE = "RESTART_STATE"

export const GET_USER_PAYMENT_REQUESTS_BY_USERID =
  "GET_USER_PAYMENT_REQUESTS_BY_USERID"
export const GET_USER_PAYMENT_REQUESTS_BY_USERID_SUCCESS =
  "GET_USER_PAYMENT_REQUESTS_BY_USERID_SUCCESS"
export const GET_USER_PAYMENT_REQUESTS_BY_USERID_FAIL =
  "GET_USER_PAYMENT_REQUESTS_BY_USERID"

/* SET INITIATED MYFATOORAH SESSION DATA*/
export const SET_MYFATOORAH_INITIATE_PAYMENT = "SET_MYFATOORAH_INITIATE_PAYMENT"
export const SET_MYFATOORAH_INITIATE_PAYMENT_SUCCESS =
  "SET_MYFATOORAH_INITIATE_PAYMENT_SUCCESS"
export const SET_MYFATOORAH_INITIATE_PAYMENT_FAIL =
  "SET_MYFATOORAH_INITIATE_PAYMENT_FAIL"

/* SET INITIATED DIBSY PAYMENT DATA*/
export const SET_DIBSY_INITIATE_PAYMENT = "SET_DIBSY_INITIATE_PAYMENT"
export const SET_DIBSY_INITIATE_PAYMENT_SUCCESS =
  "SET_DIBSY_INITIATE_PAYMENT_SUCCESS"
export const SET_DIBSY_INITIATE_PAYMENT_FAIL = "SET_DIBSY_INITIATE_PAYMENT_FAIL"

/* SET APPLE PAY DIBSY INITIATE SESSION DATA*/
export const SET_DIBSY_INITIATE_SESSION_APPLEPAY =
  "SET_DIBSY_INITIATE_SESSION_APPLEPAY"
export const SET_DIBSY_INITIATE_SESSION_APPLEPAY_SUCCESS =
  "SET_DIBSY_INITIATE_SESSION_APPLEPAY_SUCCESS"
export const SET_DIBSY_INITIATE_SESSION_APPLEPAY_FAIL =
  "SET_DIBSY_INITIATE_SESSION_APPLEPAY_FAIL"

export const SET_DIBSY_REFUND = "SET_DIBSY_REFUND"
export const SET_DIBSY_REFUND_SUCCESS = "SET_DIBSY_REFUND_SUCCESS"
export const SET_DIBSY_REFUND_FAIL = "SET_DIBSY_REFUND_FAIL"

export const SET_APS_REFUND = "SET_APS_REFUND"
export const SET_APS_REFUND_SUCCESS = "SET_APS_REFUND_SUCCESS"
export const SET_APS_REFUND_FAIL = "SET_APS_REFUND_FAIL"

export const SET_PAYMENT_TYPES = "SET_PAYMENT_TYPES"
