import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Table,
  Collapse,
} from "reactstrap"
import dateFormat from "../../helpers/commonhelpers/dateFormat"

import { handleMultiPGv2 } from "components/PaymentTypesSelect/multiPG"
import {
  paymentRequestStatus,
  paymentRequestLinkStatus,
} from "../../helpers/paymentrequest/paymentrequest_helper"

const PaymentRequestDetails = props => {
  const { paymentrequestDetails, userDetails, refund } = props
  const [col2, setcol2] = useState(false)

  const [sortOptions, setSortOptions] = useState()
  useEffect(() => {
    setSortOptions(
      paymentrequestDetails?.paymentRequestSettings?.availablePaymentOptions
    )
  }, [paymentrequestDetails?.paymentRequestSettings?.availablePaymentOptions])

  useEffect(() => {}, [sortOptions])
  const emailRecipients = paymentrequestDetails?.emailSettings.sentTo.map(
    (email, index) => <li key={index}>{email}</li>
  )

  const attachments = paymentrequestDetails?.emailSettings.attachment.map(
    (attachment, index) => <li key={index}>{attachment}</li>
  )
  function handleSort(value) {
    let sortedOptions = value?.sort(
      (a, b) => a.toString().slice(1) - b.toString().slice(1)
    )
    return sortedOptions
  }
  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col lg="6">
              <CardTitle className="mb-4">Payment Request Details</CardTitle>
              <p className="text-muted mb-4">
                {/* {userProfile.personalDetail} */}
              </p>

              <div className="table-responsive">
                <Table className="table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">
                        <i className="fas fa-file-alt text-success mr-1" />
                        Payment Request Id:{" "}
                      </th>
                      <td>{paymentrequestDetails?.paymentRequestId}</td>
                    </tr>
                    {/* invoice and service fee unpaid */}
                    {paymentrequestDetails.paymentStatus === "Unpaid" &&
                    paymentrequestDetails?.amountDetails.totalAmount ? (
                      <tr>
                        <th scope="row">
                          <i className="fas fa-money-bill text-success mr-1" />
                          Total Amount:{" "}
                        </th>
                        <td>
                          <b>
                            {(
                              paymentrequestDetails?.amountDetails
                                .invoiceAmount +
                              paymentrequestDetails?.amountDetails.serviceFee
                            ).toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}{" "}
                            {paymentrequestDetails.amountDetails.currency}
                          </b>{" "}
                          -{" "}
                          {paymentRequestStatus(
                            paymentrequestDetails.paymentStatus
                          )}
                        </td>
                      </tr>
                    ) : null}

                    {/* total amount paid */}
                    {paymentrequestDetails.paymentStatus !== "Unpaid" &&
                    paymentrequestDetails?.amountDetails.totalAmount ? (
                      <tr>
                        <th scope="row">
                          <i className="fas fa-money-bill text-success mr-1" />
                          Total Amount:{" "}
                        </th>
                        <td>
                          <b>
                            {(paymentrequestDetails?.amountDetails.totalAmount).toLocaleString(
                              undefined,
                              {
                                maximumFractionDigits: 2,
                              }
                            )}{" "}
                            {paymentrequestDetails.amountDetails.currency}
                          </b>{" "}
                          -{" "}
                          {paymentRequestStatus(
                            paymentrequestDetails.paymentStatus
                          )}
                        </td>
                      </tr>
                    ) : null}
                    {/* refund amount paid */}
                    {paymentrequestDetails.paymentStatus !== "Unpaid" &&
                    refund ? (
                      <tr>
                        <th scope="row">
                          <i className="fas fa-money-bill text-success mr-1" />
                          Refund Amount:{" "}
                        </th>
                        <td>
                          <b>
                            {(refund?.amountPaid).toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}{" "}
                            {paymentrequestDetails.amountDetails.currency}
                          </b>{" "}
                        </td>
                      </tr>
                    ) : null}

                    {/* invoice  fee paid */}
                    {paymentrequestDetails.paymentStatus !== "Unpaid" &&
                    paymentrequestDetails?.amountDetails.totalAmount ? (
                      <tr>
                        <th scope="row">
                          <i className="fa fa-credit-card text-success mr-1" />
                          Invoice Amount:{" "}
                        </th>
                        <td>
                          <b>
                            {(paymentrequestDetails?.amountDetails.invoiceAmount).toLocaleString(
                              undefined,
                              {
                                maximumFractionDigits: 2,
                              }
                            )}{" "}
                            {paymentrequestDetails.amountDetails.currency}
                          </b>{" "}
                        </td>
                      </tr>
                    ) : null}
                    {/*  service fee paid */}
                    {paymentrequestDetails.paymentStatus !== "Unpaid" &&
                    paymentrequestDetails?.amountDetails.totalAmount &&
                    paymentrequestDetails?.amountDetails.serviceFee !== 0 ? (
                      <tr>
                        <th scope="row">
                          <i className="fa fa-money-bill text-success mr-1" />
                          Service Fee:{" "}
                        </th>
                        <td>
                          <b>
                            {(paymentrequestDetails?.amountDetails.serviceFee).toLocaleString(
                              undefined,
                              {
                                maximumFractionDigits: 2,
                              }
                            )}{" "}
                            {paymentrequestDetails.amountDetails.currency}
                          </b>{" "}
                        </td>
                      </tr>
                    ) : null}
                    {paymentrequestDetails.paymentStatus !== "Unpaid" &&
                    paymentrequestDetails?.amountDetails.convenienceFee ? (
                      <tr>
                        <th scope="row">
                          <i className="fa fa-money-check text-success mr-1" />
                          Convenience Fee:{" "}
                        </th>
                        <td>
                          <b>
                            {(paymentrequestDetails?.amountDetails.convenienceFee).toLocaleString(
                              undefined,
                              {
                                maximumFractionDigits: 2,
                              }
                            )}{" "}
                            {paymentrequestDetails.amountDetails.currency}
                          </b>{" "}
                        </td>
                      </tr>
                    ) : null}
                    <tr>
                      <th scope="row">
                        <i className="fas fa-file text-success mr-1" />
                        Transaction Reference:
                      </th>
                      <td>{paymentrequestDetails.transactionReference}</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <i className="fas fa-calendar-alt text-success mr-1" />
                        Transaction Date:
                      </th>
                      <td>
                        {dateFormat(paymentrequestDetails.paymentRequestDate)}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <i className="fas fa-calendar-check text-success mr-1" />
                        Paid Date
                      </th>
                      <td>
                        {paymentrequestDetails.paidDetails != null &&
                          dateFormat(
                            paymentrequestDetails?.paidDetails?.paidDate
                          )}
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">
                        <i className="fas fa-user text-success mr-1" /> Billing
                        Name:
                      </th>
                      <td>{paymentrequestDetails?.customerDetails.name}</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <i className="fas fa-envelope-open text-success mr-1" />
                        Billing Email:
                      </th>
                      <td>{paymentrequestDetails?.customerDetails.email}</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <i className="fas fa-mobile-alt text-success mr-1" />
                        Billing Mobile:
                      </th>
                      <td>{paymentrequestDetails?.customerDetails.mobile}</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <i className="fas fa-user text-success mr-1" />
                        Requested By:
                      </th>
                      <td>
                        {userDetails.firstName} <br></br>
                        <small> {userDetails.email} </small>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>

            <Col lg="6">
              <CardTitle className="mb-4">Configuration / Settings</CardTitle>
              <p className="text-muted mb-4">
                {/* {userProfile.personalDetail} */}
              </p>
              <div className="table-responsive">
                <Table className="table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">
                        {" "}
                        <i className="fas fa-link text-success mr-1" />
                        Invoice Link:
                      </th>
                      <td>
                        {" "}
                        <a
                          href={paymentrequestDetails?.generatedLinkShort}
                          target="_blank"
                        >
                          {paymentrequestDetails?.generatedLinkShort}
                        </a>{" "}
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">
                        <i className="fas fa-unlink text-success mr-1" />
                        Link Status:
                      </th>
                      <td>
                        {paymentRequestLinkStatus(
                          paymentrequestDetails.generatedLinkStatus,
                          paymentrequestDetails.generatedLinkStatus,
                          true
                        )}
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">
                        <i className="fas fa-unlink text-success mr-1" />
                        Link Expiration:
                      </th>
                      <td>
                        {dateFormat(
                          paymentrequestDetails?.paymentRequestSettings
                            .linkValidUpto
                        )}{" "}
                      </td>

                      {/* <small>({paymentrequestDetails?.paymentRequestSettings.linkValidity} hrs)</small> */}
                    </tr>
                    <tr>
                      <th scope="row">
                        <i className="fas fa-credit-card text-success mr-1" />
                        Payment Method Provided:
                      </th>
                      <td>
                        <Row className="mx-auto">
                          {handleSort(sortOptions)?.map((pt, index) => {
                            return (
                              <div key={index}>
                                {handleMultiPGv2(pt.toString())},
                              </div>
                            )
                          })}
                        </Row>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <i className="fas fa-credit-card text-success mr-1" />
                        Language:
                      </th>
                      <td>
                        {paymentrequestDetails?.paymentRequestSettings.language}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <i className="fas fa-download text-success mr-1" />
                        Email Attachment:
                      </th>
                      <td>
                        {paymentrequestDetails?.emailSettings.attachment
                          .length > 0 ? (
                          <div className="tooltip_custom">
                            ( Hover to Check )
                            <span className="tooltiptext">{attachments} </span>
                          </div>
                        ) : (
                          <span>N/A</span>
                        )}
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">
                        <i className="fas fa-envelope text-success mr-1" />
                        Email Recipients & Subject:
                      </th>
                      <td>
                        <Card className="mb-1">
                          <span
                            onClick={() => {
                              setcol2(!col2)
                            }}
                            style={{ cursor: "pointer" }}
                            className="text-dark"
                          >
                            {" "}
                            Click to Show{" "}
                          </span>
                          <Collapse isOpen={col2}>
                            <small>
                              {paymentrequestDetails?.emailSettings.subjectLine}
                            </small>
                            {emailRecipients}
                          </Collapse>{" "}
                        </Card>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <i className="fas fa-user text-success mr-1" />
                        Generated From:
                      </th>
                      <td>
                        {paymentrequestDetails?.itemSource}
                        {paymentrequestDetails?.itemSource == "PORTAL" &&
                        paymentrequestDetails?.isExpress === true
                          ? "- Express"
                          : " - Non Express"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <i className="fas fa-user text-success mr-1" />
                        Remarks:
                      </th>
                      <td>
                        {paymentrequestDetails?.remarks ? (
                          <>
                            <div className="tooltip_custom">
                              {paymentrequestDetails?.remarks}
                              <span className="tooltiptext">
                                {paymentrequestDetails?.remarks}
                              </span>
                            </div>
                          </>
                        ) : (
                          <span>N/A</span>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>

            {/* <Col lg="5">
                  <CardTitle className="mb-4">Billing Info</CardTitle>
                  <p className="text-muted mb-4">
                    
                  </p>
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                      <tr>
                          <th scope="row"> Name:</th>
                          <td>{paymentrequestDetail?.customerDetails.name}</td>
                        </tr>
                        <tr>
                          <th scope="row">Email:</th>
                          <td>{paymentrequestDetail?.customerDetails.email}</td>
                        </tr>
                        <tr>
                          <th scope="row">Mobile:</th>
                          <td>{paymentrequestDetail?.customerDetails.mobile}</td>
                        </tr>
                        
                      </tbody>
                    </Table>
                  </div>

                  </Col> */}
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default PaymentRequestDetails
